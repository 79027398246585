import { useEffect, useState } from "react";
import RefReady from "../global/ref";
import { AddQuestion, DelQuestion, EditQuestion, GetCategories, GetQuestions } from "../axios/api";
import ButtonRed from "../global/button/button";
import { CheckSquareOutlined, CloseSquareOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AlertDelete from "../global/alert/AlertDelete";

const Questions = () => {

    const [questions, setQuestions] = useState([])
    const [categories, setCategories] = useState([])
    const [categoryName, setCategoryName] = useState()
    const [categoryCode, setCategoryCode] = useState()
    const [resultSearch, setResultSearch] = useState([])
    const [edit, setEdit] = useState({ id: '', question1: '', categoryCode: '' })
    const [del, setDel] = useState({ id: '', question1: '', categoryCode: '' })
    const [add, setAdd] = useState({ id: null, question1: '', categoryCode: '' })

    useEffect(() => {
        GetCategories().then((data) => {
            setCategories(data);
        }).catch((error) => { console.error(error); });
    }, []);

    const searchData = (search) => {
        var result = []
        for (let i = 0; i < categories?.length; i++) {
            if (categories[i].name.includes(search))
                result.push(categories[i])
        }
        setResultSearch(result)
    }

    const delQuestion = () => {
        DelQuestion(del.id).then((data) => {
            setQuestions(questions.filter(a => a.id != del.id))
            setDel({})
        }).catch((error) => { console.error(error); });
    }

    const editQuestion = () => {
        EditQuestion(edit.id, edit.question1).then((data) => {
            setQuestions((prevArr) => { return prevArr.map(item => { if (item.id === edit.id) { return { ...item, question1: edit.question1 }; } return item; }); });
            setEdit({})
        }).catch((error) => { console.error(error); });
    }

    const addQuestion = () => {
        AddQuestion(add).then((data) => {
            setQuestions([...questions, data]);
            setAdd({})
        }).catch((error) => { console.error(error); });
    }

    return <div>

        <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%', width: '30%', marginRight: '35%' }}>עריכת שאלות נפוצות</p>
        <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

        <br /><h4><b>זהירות: </b>במחיקת שאלה יש לשים לב שייתכן ועסקים כבר מילאו תשובה לשאלה זו.</h4>

        <input id="search1" type="text" className="input20" placeholder="בחרו תחום" value={categoryName} onChange={(e) => { searchData(e.target.value); setCategoryName(e.target.value); var thisCategory = categories.filter(a => a.name === e.target.value)[0]; setCategoryCode(thisCategory?.code); if (thisCategory) { GetQuestions(thisCategory.id).then((data) => { setQuestions(data) }).catch((error) => { console.error(error); }); } else { setQuestions([]) } }} onClick={(e) => { searchData(e.target.value) }} onBlur={() => setTimeout(() => { setResultSearch([]) }, 500)} style={{ marginTop: '3%' }}></input>
        {resultSearch[0] && <div className='resultSearchRound animate__animated animate__fadeInDown' style={{ width: '30%', marginRight: 'auto', marginLeft: 'auto', maxHeight: '30vh', marginTop: '-3%' }}>{resultSearch.map((x, i) => (
            <div className='resultSearch' key={i} onClick={(e) => { setCategoryName(x.name); setCategoryCode(categories.filter(a => a.name === x.name)[0].code); /*setBussiness(x);*/ searchData(x.name); setResultSearch([]); GetQuestions(x.id).then((data) => { setQuestions(data) }).catch((error) => { console.error(error); }); }}>{x.name}</div>
        ))}</div>}

        {questions.length > 0 && <div>{questions.map((x, i) => (<div key={i}>
            <DeleteOutlined className="iconDel_Edit" onClick={() => { setDel(x) }} />
            <EditOutlined className="iconDel_Edit" onClick={() => { setEdit(x) }} />
            {edit.id != x.id && <span style={{ margin: '5px' }}>{x.question1}</span>}
            {edit.id === x.id && <><input type='text' value={edit.question1} style={{ fontSize: '75%', width: '70%', minWidth: 'max-content' }} placeholder="תוכן השאלה" onChange={(e) => { if (e.target.value.length <= 150) { setEdit({ ...edit, question1: e.target.value }) } }} />
                <CheckSquareOutlined className="iconDel_Edit" onClick={() => { editQuestion() }} /></>}
        </div>))}</div>}
        {questions.length === 0 && categoryName && <p>אין שאלות נפוצות לתחום זה</p>}
        {categoryCode && <div className='buton' onClick={() => { setAdd({ ...add, categoryCode: categoryCode }) }} style={{ width: '20%', marginRight: 'auto', marginLeft: 'auto', marginTop: '1%' }}><ButtonRed text='הוספת שאלה'></ButtonRed></div>}
        {add.categoryCode && <><input type='text' value={add.question1} style={{ fontSize: '75%', width: '70%', minWidth: 'max-content', marginTop: '2%' }} placeholder="תוכן השאלה" onChange={(e) => { if (e.target.value.length <= 150) { setAdd({ ...add, question1: e.target.value }) } }} />
            <CheckSquareOutlined className="iconDel_Edit" onClick={() => { addQuestion() }} />
            <CloseSquareOutlined className="iconDel_Edit" onClick={() => { setAdd({}) }} /></>}

        {del.id && <AlertDelete set={setDel} func={delQuestion} text={'שאלה'} text2={del.question1}></AlertDelete>}
    </div>
}

export default Questions