import { Padding } from "@mui/icons-material"
import ButtonRed from "../global/button/button"
import RefReady from "../global/ref"

const KavInPhone = () => {
    return <div>
        <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%', width: '30%', marginRight: '35%' }}>ניהול המערכת הטלפונית</p>
        <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

        <div className='buton1' style={{ width: '30%', marginRight: 'auto', marginLeft: 'auto', marginTop: '3%' }} onClick={() => window.open('https://nakdan.dicta.org.il/nikudedit')}><ButtonRed text='נקדן אוטומטי'></ButtonRed></div>
        <div className='buton1' style={{ width: '15%', marginRight: 'auto', marginLeft: 'auto', marginTop: '3%', display: 'inline-block' }} onClick={() => window.open('https://ttsmaker.com/he')}><ButtonRed text='המרת טקסט לדיבור 1'></ButtonRed></div>
        <div className='buton1' style={{ width: '15%', marginRight: 'auto', marginLeft: 'auto', display: 'inline-block' }} onClick={() => window.open('https://ttsfree.com')}><ButtonRed text='המרת טקסט לדיבור 2'></ButtonRed></div>
        <div className='buton1' style={{ width: '30%', marginRight: 'auto', marginLeft: 'auto', marginTop: '1%' }} onClick={() => window.open('https://private.call2all.co.il/yemot-admin-g1/#/login')}><ButtonRed text='ימות המשיח - בנית השלוחות בקו'></ButtonRed></div>
        <p>מערכת: <b>023137663</b> , סיסמה: <b>91088638</b></p>

        <div style={{ width: '80%', marginRight: '12%', marginTop: '5%', textAlign: 'right', fontWeight: '400' }}>
            <b style={{ color: '#c00000' }}>בנית שלוחה לכל עסק – לכל החנויות שאצלם רלוונטי שעות פתיחה:</b><br />
            <p><b style={{ color: '#c00000' }}>1. </b>יש לפתוח את ימות המשיח - בנית השלוחות בקו, ולהכנס עם מספר מערכת: 023137663 וסיסמה: 91088638.</p>
            <p><b style={{ color: '#c00000' }}>2. </b>לעבור בתפריט בצד ימין לשלוחות במערכת.</p>
            <p><b style={{ color: '#c00000' }}>3. </b>יש להכנס לשלוחה המתאימה - לפי תחום העסק.</p>
            <p><b style={{ color: '#c00000' }}>4. </b>לשכפל שלוחה של עסק קיים בתחום הרצוי.</p>
            <hr></hr>
            <p><b style={{ color: '#c00000' }}>5. </b>לשנות את הפרטים בהתאם לעסק החדש:</p>
            <p style={{ lineHeight: '90%', paddingRight: '25px' }}> <b style={{ padding: '10px', color: '#c00000' }}> • </b>לשנות את מספר השלוחה.</p>
            <p style={{ lineHeight: '90%', paddingRight: '25px' }}> <b style={{ padding: '10px', color: '#c00000' }}> • </b>בקובץ ההגדרות ext.ini לשנות כל מקום שכתוב שם העסק - לשם המתאים.</p>
            <p style={{ lineHeight: '90%', paddingRight: '25px' }}> <b style={{ padding: '10px', color: '#c00000' }}> • </b>לשנות מספר טלפון בשלוחת יצירת קשר.</p>
            <p style={{ lineHeight: '90%', paddingRight: '25px' }}> <b style={{ padding: '10px', color: '#c00000' }}> • </b>בתוך כתובת ושעות פתיחה לשנות את תוכן הקובץ 000.tts - להכניס כתובת ושעות פתיחה.</p>
            <p style={{ lineHeight: '90%', paddingRight: '25px' }}> <b style={{ padding: '10px', color: '#c00000' }}> • </b>בתוך מידע נוסף לשנות את תוכן הקובץ 000.tts - להכניס תיאור ויתרון.</p>
            <p style={{ lineHeight: '90%', paddingRight: '25px' }}> <b style={{ padding: '10px', color: '#c00000' }}> • </b>בתוך מבצעים לשנות את תוכן הקובץ 000.tts - להכניס בונוס.</p>
            <p style={{ lineHeight: '90%', paddingRight: '25px' }}> <b style={{ padding: '10px', color: '#c00000' }}> • </b>בשלוחה החיצונית של כל העסקים בקטגוריה - בקובץ ext.ini - להוסיף את שם העסק עם מספר השלוחה.</p>
            <p style={{ lineHeight: '90%', paddingRight: '25px' }}> <b style={{ padding: '10px', color: '#c00000' }}> • </b>להוסיף בשלוחה Hash - השלוחה של חיפוש עסק, בקובץ menu_voice.ini, את שם העסק - יחד עם השלוחה המתאימה.</p>
            <hr></hr>
            <p><b style={{ color: '#c00000' }}>6. </b>פעמים רבות הקריינות האוטומטית לא נכונה, אי לכך במקרים אלו נדרש לנקד את הטקסט - ניתן להיעזר בנקדן אוטומטי.</p>
            <p><b style={{ color: '#c00000' }}>7. </b>יש לחייג לשלוחה שנוספה ולהאזין לכל המידע כדי לוודא שהכל תקין.</p>
            <p><b style={{ color: '#c00000' }}>8. </b>נא למלא בטבלת הסטטיסטיקה את מספר השלוחה של העסק.</p>
            <p><b style={{ color: '#c00000' }}>9. </b>לסיום, להיכנס לשליחת מיילים, ולשלוח לעסק מייל אישור על סיום בניית השלוחה.</p>
        </div>

    </div>
}

export default KavInPhone