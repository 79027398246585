import './AddToDataBase.css'
import { useEffect, useState } from "react"
import ButtonRed from "../../../global/button/button"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { EditCatchDates, SendEmailToBussiness } from "../../../axios/api"
import { CompleteBussinessData } from "../../../2. redux/actions/thisBussinessActions"
import Calender from "../../../global/bussiness/Calender/Calender"
import { HDate } from 'hebcal';

const EditBussinessCalander = (props) => {

    const [dates, setDates] = useState([]);
    const [think, setThink] = useState(false)
    const [toSendMailCalander, setToSendMailCalander] = useState(0);
    const isAddBus = props.isAddBus;

    const s = useSelector((state) => state.thisBussinessReducer);
    const n = useNavigate()
    const d = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0);
        if (s.bussinessData.dates?.length > 0) {
            var arr = JSON.parse(JSON.stringify(s.bussinessData.dates))
            arr.sort((a, b) => a.date1 > b.date1 ? 1 : -1)
            for (let i = 0; i < arr.length; i++) { arr[i].date1 = arr[i].date1.split('T')[0] }
            setDates(arr)
        } else { setToSendMailCalander(1); }
    }, [s])

    const HebrewDaysNames = ['0', "א'", "ב'", "ג'", "ד'", "ה'", "ו'", "ז'", "ח'", "ט'", "י'", 'י"א', 'י"ב', 'י"ג', 'י"ד', 'ט"ו', 'ט"ז', 'י"ז', 'י"ח', 'י"ט', "כ'", 'כ"א', 'כ"ב', 'כ"ג', 'כ"ד', 'כ"ה', 'כ"ו', 'כ"ז', 'כ"ח', 'כ"ט', "ל'"];

    const fullDatesH = () => {
        var fullDatesH = []
        var whatDates = dates ? dates : []
        if (s?.bussinessData?.dates?.length > 0) {
            var arr = s.bussinessData.dates
            arr.sort((a, b) => a.date1 > b.date1 ? 1 : -1)
            for (let i = 0; i < arr.length; i++) { arr[i].date1 = arr[i].date1.split('T')[0] }
            whatDates = arr
        }
        whatDates.forEach(element => {
            const hebrewDate0 = new HDate(new Date(element.date1))
            const hebrewDateString0 = `${HebrewDaysNames[hebrewDate0.getDate()]} ${hebrewDate0.getMonthName('h')}`;
            fullDatesH.push(hebrewDateString0)
        });
        return fullDatesH;
    }

    const addDate = (date) => {
        setDates([...dates, { date1: date, categoryCode: s.categoryCode, bussinessId: 0 }])
    }

    const removeDate = (date) => {
        setDates(prev => { var dates = prev; return dates.filter(a => a.date1 !== date); })
    }

    const handleSubmit = (e) => {
        e.preventDefault(); setThink(true);
        if (!isAddBus)
            EditCatchDates(dates, s.id, s.categoryCode).then((data) => {
                d(CompleteBussinessData({ ...s.bussinessData, dates: dates }))
                //שליחת מייל לעסק על שהתחבר לניהול יומן - עם קיצור דרך לניהול היומן
                if (toSendMailCalander) {
                    var url = `https://kolsimcha.co.il/EditBussinessMain/6/${s.categoryCode}/${s.id}`
                    var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;">הצטרפתם לניהול יומן דרך קול שמחה</p></div><br/> <br/> ${s.name} שלום,  <br/><br/>הצטרפתם בהצלחה לאפשרות ניהול יומן וסימון תאריכים מלאים.<br/> <b>קוד ניהול העסק שלכם הוא:</b><br/> ${s.code} <br/><b> מצורף קיצור דרך לגישה ישירה ליומן, מומלץ להוסיף למועדפים: </b><br/> <a href="${url}" class="button">גישה ישירה ליומן</a> <br/><br/>אתם מחליטים האם לעדכן את היומן כל יום/שבוע/חודש.<br/>ככל שיהיה יותר עדכני תחסכו לעצמכם פניות על תאריכים שכבר תפוסים.<br/> <br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                    SendEmailToBussiness({ name: "", emailTo: s.mail, emailFrom: 'info@kolsimcha.co.il', subject: `ניהול יומן דרך קול שמחה`, message: mes }).then(() => { }).catch((error) => { console.error(error); });
                }
                window.history.length > 1 ? n(-1) : n('/');
            }).catch((error) => { console.error(error); setThink(false); });
        else {
            d(CompleteBussinessData({ ...s.bussinessData, dates: dates }))
            props.enter();
        }
    }

    return <div>
        <form onSubmit={handleSubmit}>
        {isAddBus && <div className='buton' onClick={() => { d(CompleteBussinessData({ ...s.bussinessData, dates: dates })); props.prev() }} style={{ position: 'absolute', top: '-5vh', right: '-40%', width: '5vh', zIndex:'5' }}><ButtonRed text='<--'></ButtonRed></div>}

            <p className="titl" style={{ marginTop: '8%', fontSize: '120%', marginBottom: '2%' }}>ניהול יומן</p>
            <p>מהיום אתם יושבים בשקט - והלקוחות מסמנים תאריכים וממלאים לכם את היומן!</p>

            <p style={{ marginBottom: '2%', marginTop: '5%' }}>התאריכים המלאים ב{s.name} עד כה:</p>

            <div style={{ minHeight: '60vh' }}>
                <Calender fullDatesH={fullDatesH()} categoryCode={s.categoryCode} bussinessId={0} addDate={addDate} removeDate={removeDate}></Calender>
            </div>

            <p style={{ marginTop: '10vh' }}>היומן יתחיל לפעול רק כאשר תתחילו אתם לסמן בו תאריכים.</p>

            <div style={{ marginBottom: '15%' }}></div>
            <span style={{ cursor: `${think ? 'wait' : 'pointer'}` }}><button type="submit" className='buton' style={{ pointerEvents: `${think ? 'none' : 'auto'}`, width: '20%', marginTop: '-10%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto', minWidth:'20vh', zIndex:'5' }}><ButtonRed text={isAddBus ? 'המשך    -->' : 'סיים עריכה'}></ButtonRed></button></span>
        </form>

    </div>
}

export default EditBussinessCalander