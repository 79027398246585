import { useEffect, useRef } from 'react';
import './HowItWork.css'
import RefReady from '../../global/ref';
import BottomMenu from '../../global/bottomMenu/BottomMenu';
import { useNavigate } from 'react-router-dom';
import ButtonRed from '../../global/button/button';

const HowItWork = () => {
    useEffect(() => { window.scrollTo(0, 0); }, []);

    // ------------------------start ref-------------------------
    const ref1 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible3')
                }
            });
        });

        if (ref1.current) { observer.observe(ref1.current); }
        if (ref3.current) { observer.observe(ref3.current); }
        if (ref4.current) { observer.observe(ref4.current); }
        if (ref5.current) { observer.observe(ref5.current); }

        return () => {
            if (ref1.current) { observer.unobserve(ref1.current); }
            if (ref3.current) { observer.unobserve(ref3.current); }
            if (ref4.current) { observer.unobserve(ref4.current); }
            if (ref5.current) { observer.unobserve(ref5.current); }
        };
    }, []);
    // ------------------------end ref-------------------------

    const navigate = useNavigate()

    return <div className='aaa'>

        {/* ------------------------------------------------SideMenu-------------------------------------------------------------------------- */}
        <div className='bbb' style={{ zIndex: '100', backgroundColor: 'white', marginTop: '10vh', float: 'right', position: 'fixed', boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)", padding: '2%' }}>
            <RefReady tag={<p className='animate__animated' style={{ marginBottom: '0', fontWeight: '400' }}>הרשמו וקבלו<br /> ארגונית אישית <br />הכוללת: </p>} event={'animate__fadeInTopLeft'}></RefReady>
            <RefReady tag={<div className='animate__animated' style={{ width: '80px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '3px', marginBottom: '14%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
            <a href='#take1' className='sidenavBtn1' style={{ textDecoration: 'none' }}>רשימת משימות<br></br>דיגיטלית אישית</a>
            <div className='hr' style={{ marginTop: '10%', marginBottom: '10%' }}></div>
            <a href='#take2' className='sidenavBtn1' style={{ textDecoration: 'none' }}>גיליון חישוב<br></br>הוצאות</a>
            <div className='hr' style={{ marginTop: '10%', marginBottom: '10%' }}></div>
            <a href='#take3' className='sidenavBtn1' style={{ textDecoration: 'none' }}>תזכורות למייל</a>
            <div className='hr' style={{ marginTop: '10%', marginBottom: '10%' }}></div>
            <a href='#take4' className='sidenavBtn1' style={{ textDecoration: 'none' }}>רשימת מוזמנים<br></br>וכתובות</a>
        </div>
        {/* ------------------------------------------------SideMenu----------------------------------------------------------------------- */}


        <div className='aaaaa'>
            <div style={{ backgroundColor: 'white', paddingBottom: '10vh' }}>
                <p style={{ fontSize: '150%', fontWeight: '500', paddingTop: '8%' }}>כך מארגנים אירוע</p>
                <div ref={ref1} style={{ transition: 'width 0.5s ease-in-out' }} className='underline3'></div>

                <div className='picFirst1 animate__animated animate__fadeInDown'></div>
                <div style={{ fontSize: '140%', paddingTop: '0%', textAlign: 'right', width: '50%', lineHeight: '2vh', display: 'inline-block' }}>
                    <p className='animate__animated animate__fadeInLeft' style={{ fontSize: '145%', fontWeight: '400' }}>פשוט לעבוד עם סדר</p>
                    <p className='animate__animated animate__fadeInLeft animate__delay-1s'>ההכנות לחתונה הן רבות ולכן חשוב מאד לעבוד עם סדר</p>
                    <p className='animate__animated animate__fadeInLeft animate__delay-1s animate__slow'>בקול שמחה תקבלו <b>ארגונית אישית</b> הכוללת ידע וכלים אישיים,</p>
                    <p className='animate__animated animate__fadeInLeft animate__delay-2s'>מעשיים ונגישים בכל שלב</p>
                    <p className='animate__animated animate__fadeInLeft animate__delay-2s animate__slow'>כדי להגיע מאורגנים לאירוע</p>
                    <p className='animate__animated animate__fadeInLeft animate__delay-2s animate__slow'>ולא לשכוח שום דבר.</p>
                </div>
            </div>
            {/* <div style={{ backgroundColor: 'white', width: '100%', height: '45vh', paddingTop: '15vh' }}>
            <RefReady tag={<p className='animate__animated' style={{ fontSize: '150%', fontWeight: '400', marginBottom: '0' }}>איך משתמשים?</p>} event={'animate__flash'}></RefReady>
            <RefReady tag={<div className='animate__animated' style={{ width: '80px', height: '5px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '5px', marginBottom: '8%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
        </div> */}

            <div className='first2' id='take1'>
                <RefReady tag={<p className='animate__animated' style={{ fontSize: '150%', fontWeight: '400', marginBottom: '0', marginTop: '-4%' }}>הרשמו וקבלו ארגונית אישית הכוללת </p>} event={'animate__fadeInTopLeft'}></RefReady>
                <RefReady tag={<div className='animate__animated' style={{ width: '80px', height: '5px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '5px', marginBottom: '4%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
                <RefReady tag={<p style={{ fontSize: '260%', fontWeight: '400' }}>רשימת משימות דיגיטלית אישית</p>} event={'animate__bounceInLeft'}></RefReady>

                {/* <RefReady tag={<p className='animate__animated' style={{ fontSize: '130%', fontWeight: '500', paddingTop: '14%', marginTop: '0', webkitTextFillColor: 'black', webkitTextStrokeWidth: '0.4px', webkitTextStrokeColor: '#C00000' }}>הרשמו וקבלו</p>} event={'animate__heartBeat'} event2={'0.5s'}></RefReady>
            <RefReady tag={<div className='border50 animate__animated ' style={{ height: '-50vh' }}></div>} event={'animate__fadeInRight'} event2={'2s'}></RefReady> */}

            </div>
            <div style={{ backgroundColor: 'white', paddingTop: '4vh', paddingBottom: '14vh' }}>
                <div className='picFirst2 animate__animated animate__fadeInDown'></div>
                <div style={{ fontSize: '140%', paddingTop: '6%', textAlign: 'right', width: '50%', lineHeight: '2vh', display: 'inline-block' }}>
                    <RefReady tag={<p className='animate__animated' style={{ fontSize: '145%', fontWeight: '400' }}>רשימה הכוללת הכל</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>רשימת המשימות מותאמת בדיוק לצרכי האירוע שלכם,</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>כדי שתהיו בטוחים שלא שכחתם שום דבר</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>עם קישורים חכמים שמקשרים אתכם בלחיצה פשוטה - </p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>מכל מטלה ישיר לביצועה</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<div className='but animate__animated' onClick={() => navigate(`/taskList`)}><ButtonRed text={'עבור לרשימת המשימות הדיגיטלית באירגונית האישית'}></ButtonRed></div>} event={'animate__fadeInDownBig'}></RefReady>
                </div>
            </div>

            {/* <div style={{ backgroundColor: 'white', width: '100%', height: '45vh', paddingTop: '15vh' }}>
            <RefReady tag={<p className='animate__animated' style={{ fontSize: '150%', fontWeight: '400', marginBottom: '0' }}>איך מבצעים משימות?</p>} event={'animate__flash'}></RefReady>
            <RefReady tag={<div className='animate__animated' style={{ width: '80px', height: '5px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '5px', marginBottom: '8%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
        </div> */}

            <div className='picWine' style={{ paddingTop: '4vh', paddingBottom: '8vh' }}>
                {/* <div className='picFirst3 animate__animated animate__fadeInDown'></div> */}
                <div className='left' style={{ fontSize: '140%', paddingTop: '6%', textAlign: 'left', width: '50%', lineHeight: '2vh', display: 'inline-block', marginLeft: '32%' }}>
                    <RefReady tag={<p className='animate__animated' style={{ fontSize: '145%', fontWeight: '400' }}>גישה אל כל ספקי האירועים</p>} event={'animate__fadeInRight'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>בתפריט המשימות לחצו על המטלה אותה ברצונכם לבצע</p>} event={'animate__fadeInRight'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>ויוצגו לפניכם כל נותני השירות בתחום המבוקש</p>} event={'animate__fadeInRight'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>ניתן למיין בכל תחום מזול ליקר, מקטן לגדול</p>} event={'animate__fadeInRight'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>ולסנן לפי טעם, תקציב, גודל, תאריכים פנויים, וכל מה שחשוב לכם</p>} event={'animate__fadeInRight'}></RefReady>
                    {/* <RefReady tag={<div style={{ float: 'left' }} className='but animate__animated' onClick={() => navigate(`/taskList`)}><ButtonRed text={'עבור לרשימת המשימות הדיגיטלית באירגונית האישית'}></ButtonRed></div>} event={'animate__fadeInRight'}></RefReady> */}
                </div>
            </div>

            <div className='first2' id='take2' style={{ height: '58vh' }}>
                <RefReady tag={<div style={{ fontSize: '110%', marginTop: '-7%' }}><p>חישוב ההוצאות לאירוע היא מלאכה בפני עצמה הדורשת תכנון וחשיבה,</p></div>} event={'animate__fadeInDownBig'}></RefReady>
                <RefReady tag={<p className='animate__animated' style={{ fontSize: '150%', marginTop: '5%', fontWeight: '400', marginBottom: '0' }}>הרשמו וקבלו ארגונית אישית הכוללת </p>} event={'animate__fadeInTopLeft'}></RefReady>
                <RefReady tag={<div className='animate__animated' style={{ width: '80px', height: '5px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '5px', marginBottom: '4%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
                <RefReady tag={<p style={{ fontSize: '230%', fontWeight: '400' }}>גיליון המחשב את סכום ההוצאות שלכם</p>} event={'animate__bounceInLeft'}></RefReady>

            </div>

            <div style={{ backgroundColor: 'white', paddingTop: '4vh', paddingBottom: '14vh' }}>
                <div className='picFirst4 animate__animated animate__fadeInDown'></div>
                <div style={{ fontSize: '140%', paddingTop: '6%', textAlign: 'right', width: '50%', lineHeight: '2vh', display: 'inline-block' }}>
                    <RefReady tag={<p className='animate__animated' style={{ fontSize: '145%', fontWeight: '400' }}>גיליון חישוב אוטומטי</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>גיליון אוטומטי המחשב בכל רגע נתון את סכום ההוצאות הנוכחי שלכם</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>כך תוכלו לעקוב שאתם עומדים במקום טוב ביחס לתקציב שלכם.</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>אם תהיו עקביים - </p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>תוכלו לדעת בסוף האירוע כמה עלה לכם הכל מתחילה ועד סוף.</p>} event={'animate__fadeInDownBig'}></RefReady>
                    {/* <RefReady tag={<p className='animate__animated'>האירוע יכול להתחיל....</p>} event={'animate__fadeInDownBig'}></RefReady> */}
                    <RefReady tag={<div className='but animate__animated' onClick={() => navigate(`/howManyToPay`)}><ButtonRed text={'עבור לגיליון חישוב ההוצאות באירגונית האישית'}></ButtonRed></div>} event={'animate__fadeInDownBig'}></RefReady>
                </div>
            </div>

            <div style={{ backgroundColor: '#EEEEEE', paddingBottom: '10vh', height: '71vh' }}>
                <div className='picFirst5 animate__animated animate__fadeInDown'></div>
                <div className='left' style={{ fontSize: '140%', paddingTop: '7%', textAlign: 'left', width: '70%', lineHeight: '2vh', display: 'inline-block', float: 'right' }}>
                    <RefReady tag={<p className='animate__animated' style={{ fontSize: '145%', fontWeight: '400' }}>תיאום בין הצדדים למניעת כפילויות</p>} event={'animate__fadeInRight'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>חלק מהמשימות משותפות לשני הצדדים</p>} event={'animate__fadeInRight'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>המערכת מסונכרנת ומתואמת עם הצד השני - מונעת כפילוית,</p>} event={'animate__fadeInRight'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>מונעת מצב שכל צד בטוח שהצד השני סגר עם מישהו מסוים ובסוף לא הוזמן....</p>} event={'animate__fadeInRight'}></RefReady>
                    <RefReady tag={<p className='animate__animated' style={{ fontSize: '70%' }}>תוכלו להזין ברשימת ההוצאות האישית רק את הסכום שהצד שלכם הוציא עבור הוצאה משותפת.</p>} event={'animate__fadeInRight'}></RefReady>
                    <RefReady tag={<div style={{ float: 'left', marginBottom: '10vh' }} className='but animate__animated' onClick={() => navigate(`/taskList`)}><ButtonRed text={'עבור לרשימת המשימות הדיגיטלית באירגונית האישית'}></ButtonRed></div>} event={'animate__fadeInRight'}></RefReady>
                </div>
                {/* <div className='picFirst4 animate__animated animate__fadeInDown'></div> */}

            </div>

            <div className='first2' id='take3'>
                <RefReady tag={<p className='animate__animated' style={{ fontSize: '150%', fontWeight: '400', marginBottom: '0', marginTop: '-4%' }}>הרשמו וקבלו ארגונית אישית הכוללת </p>} event={'animate__fadeInTopLeft'}></RefReady>
                <RefReady tag={<div className='animate__animated' style={{ width: '80px', height: '5px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '5px', marginBottom: '4%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
                <RefReady tag={<p style={{ fontSize: '200%', fontWeight: '400' }}>תזכורות למייל בזמן המתאים לביצוע המשימה</p>} event={'animate__bounceInLeft'}></RefReady>
            </div>

            <div style={{ backgroundColor: 'white', paddingTop: '4vh', paddingBottom: '14vh' }}>
                <div className='picFirst6 animate__animated animate__fadeInDown'></div>
                <div style={{ fontSize: '140%', paddingTop: '6%', textAlign: 'right', width: '50%', lineHeight: '2vh', display: 'inline-block' }}>
                    <RefReady tag={<p className='animate__animated' style={{ fontSize: '145%', fontWeight: '400' }}>תזכורות למייל</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>במידה ואישרתם קבלת תזכורות,</p>} event={'animate__fadeInDownBig'}></RefReady>
                    {/* <RefReady tag={<p className='animate__animated'>במקרה שלא ביצעתם מטלה שחשוב לבצע אותה</p>} event={'animate__fadeInDownBig'}></RefReady> */}
                    <RefReady tag={<p className='animate__animated'>תקבלו תזכורת כלליות ופרטניות למייל שלכם בכל שלב,</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>מתוזמנות אוטומטית לצרכי האירוע</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>כדי שנהיה בטוחים שלא תשכחו לארגן שום דבר.</p>} event={'animate__fadeInDownBig'}></RefReady><br></br>
                    {/* <RefReady tag={<div className='but animate__animated' onClick={() => navigate(`/taskList`)}><ButtonRed text={'רשימת משימות דיגיטלית'}></ButtonRed></div>} event={'animate__fadeInDownBig'}></RefReady> */}
                </div>
            </div>

            <div className='first2' id='take4'>
                <RefReady tag={<p className='animate__animated' style={{ fontSize: '150%', fontWeight: '400', marginBottom: '0', marginTop: '-4%' }}>הרשמו וקבלו ארגונית אישית הכוללת </p>} event={'animate__fadeInTopLeft'}></RefReady>
                <RefReady tag={<div className='animate__animated' style={{ width: '80px', height: '5px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '5px', marginBottom: '4%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
                <RefReady tag={<p style={{ fontSize: '260%', fontWeight: '400' }}>ניהול רשימת כתובות להזמנות</p>} event={'animate__bounceInLeft'}></RefReady>

                {/* <RefReady tag={<p className='animate__animated' style={{ fontSize: '130%', fontWeight: '500', paddingTop: '14%', marginTop: '0', webkitTextFillColor: 'black', webkitTextStrokeWidth: '0.4px', webkitTextStrokeColor: '#C00000' }}>הרשמו וקבלו</p>} event={'animate__heartBeat'} event2={'0.5s'}></RefReady>
            <RefReady tag={<div className='border50 animate__animated ' style={{ height: '-50vh' }}></div>} event={'animate__fadeInRight'} event2={'2s'}></RefReady> */}

            </div>
            <div style={{ backgroundColor: 'white', paddingTop: '4vh', paddingBottom: '14vh' }}>
                <div className='picFirst8 animate__animated animate__fadeInDown'></div>
                <div style={{ fontSize: '140%', paddingTop: '6%', textAlign: 'right', width: '50%', lineHeight: '2vh', display: 'inline-block' }}>
                    <RefReady tag={<p className='animate__animated' style={{ fontSize: '145%', fontWeight: '400' }}>רשימת מוזמנים להדפסה</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>ניתן להזין רשימת מוזמנים בצורה נוחה, </p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>לסמן לעצמכם סיווג וכינוי אישי לכל מוזמן.</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>בלחיצת כפתור תוכלו להפוך את טבלת המוזמנים </p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>לעיצוב מדבקות להדבקה על ההזמנות.</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<div className='but animate__animated' onClick={() => navigate(`/listAddress`)}><ButtonRed text={'עבור לרשימת המוזמנים באירגונית האישית'}></ButtonRed></div>} event={'animate__fadeInDownBig'}></RefReady>
                </div>
            </div>


            <div style={{ backgroundColor: 'rgb(244, 244, 244)', paddingTop: '4vh', paddingBottom: '14vh' }}>
                <div className='picFirst7 animate__animated animate__fadeInDown'></div>
                <div style={{ fontSize: '140%', paddingTop: '6%', textAlign: 'right', width: '50%', lineHeight: '2vh', display: 'inline-block' }}>
                    <RefReady tag={<p className='animate__animated' style={{ fontSize: '145%', fontWeight: '400' }}>יתרון עצום</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>המערכת שומרת לכם את הנתונים מילד לילד,</p>} event={'animate__fadeInDownBig'}></RefReady>
                    {/* <RefReady tag={<p className='animate__animated'>במקרה שלא ביצעתם מטלה שחשוב לבצע אותה</p>} event={'animate__fadeInDownBig'}></RefReady> */}
                    <RefReady tag={<p className='animate__animated'>תוכלו תמיד לפתוח ולבדוק נתונים כמו:</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>רשימת מוזמנים וכתובות, כמה מנות הזמנו לחתונה,</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>כמה עלה לנו האולם או כל הוצאה אחרת,</p>} event={'animate__fadeInDownBig'}></RefReady>
                    <RefReady tag={<p className='animate__animated'>מי היה הזמר או המאפרת וכמה שילמנו לה...</p>} event={'animate__fadeInDownBig'}></RefReady><br></br>
                    {/* <RefReady tag={<div className='but animate__animated' onClick={() => navigate(`/taskList`)}><ButtonRed text={'רשימת משימות דיגיטלית'}></ButtonRed></div>} event={'animate__fadeInDownBig'}></RefReady> */}
                </div>
            </div>
        </div>

        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', backgroundColor: '#DDDDDD', width: '40%', right: '30%', height: '100vh', top: '0', fontSize: '120%' }}>
                <RefReady tag={<div style={{ marginTop: '20%' }}><p>הרשימה סודרה עפ"י ציר הזמן -<br></br> ישנן משימות שחשוב לקבוע מיד לאחר האירוסין<br></br> או כאלה שמתאים לבצע סמוך יותר לחתונה,</p></div>} event={'animate__fadeInLeft'}></RefReady>
                <RefReady tag={<div><p> ולכן מומלץ לעבור על הרשימה לפי הסדר.  </p></div>} event={'animate__fadeInLeft'}></RefReady>
                <hr></hr>
                <p style={{ fontSize: '80%', fontWeight: '400' }}>תוכן השירותים, המוצרים, הנתונים והמחירים באחריות המפרסמים בלבד.<br /> אין המערכת אחראית על כל נזק שיגרם כתוצאה משימוש במידע ובשירותי האתר.<br></br>ברשימת המשימות השתדלנו לרכז הכל. אם השמטנו משהו - איננו אחראים על כך.<br></br> לתועלת המשתמשים נשמח לקבל שיפורים והערות. </p>
                <hr></hr>
                <p style={{marginRight:'auto', marginLeft:'auto', width:'max-content', fontSize:'70%'}}>Icons by <span style={{cursor:'pointer', textDecoration:'underline'}} onClick={()=>{window.open("https://lordicon.com/")}}>Lordicon.com</span> and <span style={{cursor:'pointer', textDecoration:'underline'}} onClick={()=>{window.open("https://www.flaticon.com/")}}>flaticon.com</span></p>
            </div>
            <div style={{ marginBottom: '65vh' }}>4</div>
            <BottomMenu></BottomMenu>
        </div>
        {/* <div style={{ marginBottom: '2%' }}>
            <DownOutlined className='animate__animated animate__fadeOutDown animate__infinite animate__delay-3s DownOutlined' />
        </div> */}
        {/* <BottomMenu></BottomMenu> */}

        {/* <div style={{ marginTop: '50vh' }}></div> */}
        {/* <div style={{ position: 'relative' }}> */}
        {/* <img loading='lazy' style={{position:'absolute', top:'20vh', left:'10%',height:'30vh',width:'auto'}} src={task}></img> */}


        {/* <RefReady tag={<div className='divvv'> */}
        {/* <div className='border6'><div style={{ fontSize: '150%', color: '#c00000', marginBottom: '3%', marginTop: '2%' }} className='animate__animated animate__swing animate__infinite animate__delay-1s'>1.</div></div> */}
        {/* </div>} event={'animate__fadeInDown'}></RefReady>
            <RefReady tag={<div className='picDivvv'></div>} event={'animate__fadeInRight'}></RefReady> */}



        {/* <div className='round' style={{marginRight:'40%'}}>
                <DownOutlined className='animate__animated animate__fadeOutDown animate__infinite animate__delay-3s DownOutlined' />
            </div> */}
        {/* <div className='round'>
                <DownOutlined className='animate__animated animate__fadeOutDown animate__infinite animate__delay-3s DownOutlined' />
            </div>
            <div className='round'>
                <DownOutlined className='animate__animated animate__fadeOutDown animate__infinite animate__delay-3s DownOutlined' />
            </div> */}
        {/* <RefReady tag={<div style={{ zIndex: '1' }}>
                <div className='ppp1'></div>
                <div className='divv'>
                    <p>כאן תמצאו את כל ספקי האירועים במקום אחד.</p>
                    <div><p>לחצו על המטלה אותה ברצונכם לבצע ויוצגו לפניכם כל נותני השירות בתחום המבוקש.</p></div>
                </div>
                <div className='ppp2'></div>
            </div>} event={'animate__fadeInDown'}></RefReady>

            <div style={{ marginTop: '-5%', marginBottom: '10%' }}>
                <DownOutlined className='animate__animated animate__fadeOutDown animate__infinite animate__delay-3s DownOutlined' />
            </div> */}
        {/* <DownOutlined style={{ fontSize: '150%', color: '#c00000', marginBottom: '3%', marginTop: '2%' }} className='animate__animated animate__fadeOutDown animate__infinite animate__delay-3s' /> */}

        {/* <RefReady tag={<div className='divvvv'>
                <div><p style={{ paddingTop: '6%' }}>בחרו את נותן השירות ניתן למיין בכל תחום מזול ליקר, מקטן לגדול,</p></div>
                <div><p>ולסנן לפי טעם, תקציב, גודל, תאריכים פנויים, וכל מה שחשוב לכם.</p></div>
            </div>} event={'animate__fadeInDown'}></RefReady>
            <div className='picDivvvv'></div> */}
        {/* <DownOutlined style={{ fontSize: '150%', color: '#c00000', marginBottom: '3%', marginTop: '2%' }} className='animate__animated animate__fadeOutDown animate__infinite animate__delay-3s' /> */}
        {/* <div className='white'>
                <RefReady tag={<div><p style={{ paddingTop: '20%' }}>הספק יקבל הודעה שתפסתם אותו לתאריך האירוע שלכם, ויאשר במייל חוזר.</p></div>} event={'animate__fadeInLeft'}></RefReady>
                <RefReady tag={<div style={{ marginBottom: '0', paddingBottom: '8%' }}><p style={{ paddingBottom: '5%' }}>לאחר האישור עברו לשלב התשלום.</p></div>} event={'animate__fadeInLeft'}></RefReady>
            </div>
        </div> */}
        {/* <div className='blackk'>

            <RefReady tag={<div style={{ paddingTop: '10%' }}><p>חישוב ההוצאות לאירוע היא מלאכה בפני עצמה הדורשת תכנון וחשיבה,</p></div>} event={'animate__fadeInDownBig'}></RefReady>


            <p style={{ fontSize: '110%', fontWeight: '400', marginTop: '2%', webkitTextFillColor: 'white', webkitTextStrokeWidth: '0.4px', webkitTextStrokeColor: '#C00000' }}>הרשמו וקבלו</p>
            <div ref={ref3} style={{ transition: 'width 0.5s ease-in-out', marginTop: '30px' }} className='underline3'></div> */}
        {/* <div className='border5' style={{ height: '66rem', top: '260%', height: '20rem' }}></div>
            <div className='border6' style={{ top: '260%' }}><div style={{ fontSize: '150%', color: '#c00000', marginBottom: '3%', marginTop: '2%' }} className='animate__animated animate__swing animate__infinite animate__delay-1s'>2.</div></div> */}


        {/* <RefReady tag={<div style={{ marginTop: '7%' }}><p>גיליון אוטומטי המחשב בכל רגע נתון את סכום ההוצאות הנוכחי שלכם</p></div>} event={'animate__fadeInDownBig'}></RefReady>
            <RefReady tag={<div><p>כך תוכלו לעקוב שאתם עומדים במקום טוב ביחס לתקציב שלכם. </p></div>} event={'animate__fadeInDownBig'}></RefReady>
            <RefReady tag={<div><p>תוכלו לדעת בסוף האירוע כמה עלה לכם הכל מתחילה ועד סוף.</p></div>} event={'animate__fadeInDownBig'}></RefReady><br /><br /><br /><br />

        </div>
        <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', marginBottom: '0', paddingBottom: '10%' }}>
            <p style={{ fontSize: '110%', fontWeight: '400', paddingTop: '12%', marginTop: '0', webkitTextFillColor: 'black', webkitTextStrokeWidth: '0.4px', webkitTextStrokeColor: '#C00000' }}>הרשמו וקבלו</p>
            <div ref={ref4} style={{ transition: 'width 0.5s ease-in-out', marginTop: '30px', color: '#FF7D7D' }} className='underline3'></div> */}
        {/* <div className='border5' style={{ height: '20rem', top: '330%' }}></div>
            <div className='border6' style={{ top: '330%' }}><div style={{ fontSize: '150%', color: '#c00000', marginBottom: '3%', marginTop: '2%' }} className='animate__animated animate__swing animate__infinite animate__delay-1s'>3.</div></div> */}


        {/* <RefReady tag={<div style={{ marginTop: '7%' }}><p>לנוחותכם רשימת המשימות המשותפת לשני הצדדים מתואמת עם הצד השני,</p></div>} event={'animate__fadeInDownBig'}></RefReady>
            <RefReady tag={<div><p>כך ימנעו כפילויות או מצב שכל צד היה בטוח שהצד השני סגר עם מישהו מסוים ובסוף אין כלום.... </p></div>} event={'animate__fadeInDownBig'}></RefReady>
            <RefReady tag={<div><p>תוכלו להזין ברשימת ההוצאות האישית רק את הסכום שהצד שלכם הוציא עבור הוצאה משותפת.</p></div>} event={'animate__fadeInDownBig'}></RefReady>
        </div>
        <div className='white'>
            <p style={{ fontSize: '110%', fontWeight: '400', paddingTop: '12%', marginTop: '0', webkitTextFillColor: 'black', webkitTextStrokeWidth: '0.4px', webkitTextStrokeColor: '#C00000' }}>הרשמו וקבלו</p>
            <div ref={ref5} style={{ transition: 'width 0.5s ease-in-out', marginTop: '30px' }} className='underline3'></div> */}
        {/* <div className='border5' style={{ height: '20rem', top: '400%' }}></div>
            <div className='border6' style={{ top: '400%' }}><div style={{ fontSize: '150%', color: '#c00000', marginBottom: '3%', marginTop: '2%' }} className='animate__animated animate__swing animate__infinite animate__delay-1s'>4.</div></div> */}


        {/* <RefReady tag={<div style={{ marginTop: '7%' }}><p>תזכורות למייל - במקרה שלא ביצעתם מטלה שחשוב לבצע אותה </p></div>} event={'animate__fadeInLeft'}></RefReady>
            <RefReady tag={<div><p>תקבלו תזכורת למייל שלכם, כדי שנהיה בטוחים שלא תשכחו לארגן שום דבר.</p></div>} event={'animate__fadeInLeft'}></RefReady>


            <RefReady tag={<div style={{ marginTop: '20%' }}><p>הרשימה סודרה עפ"י ציר הזמן - ישנן משימות שחשוב לקבוע מיד לאחר האירוסין או כאלה שמתאים</p></div>} event={'animate__fadeInLeft'}></RefReady>
            <RefReady tag={<div><p> לבצע סמוך יותר לחתונה, ולכן מומלץ לעבור על הרשימה לפי הסדר.  </p></div>} event={'animate__fadeInLeft'}></RefReady> */}

        {/* </div> */}
    </div>
}

export default HowItWork