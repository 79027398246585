import { useDispatch, useSelector } from "react-redux";
import { styled } from 'styled-components';
import './Bussiness.css'
import RefReady from "../ref";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import EditBussiness from "./ZEditBussiness/EditBussiness";
import BottomMenu from "../bottomMenu/BottomMenu";

import icon1 from "../../../pic/animations/115.gif";
import icon2 from "../../../pic/animations/16.gif";
import icon3 from "../../../pic/animations/135.gif";
import icon4 from "../../../pic/animations/356.gif";
import icon5 from "../../../pic/animations/137.gif";
import icon6 from "../../../pic/animations/126.gif";
import { ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import ButtonRed from "../button/button";
import pic2 from '../../../pic/logo/444.jpg'
import picError from '../../../pic/animations/113.gif'
import { AddPhoneToCall, EditHowEditBussiness, EditPhoneToCall, SendEmailChangeInPhone, SendEmailToBussiness } from "../../axios/api";
import { COMPLETEphone } from "../../2. redux/actions/bussinessActions";

const ImageDiv = styled.div`
        background-image: url(${props => props.$pic}); 
    `;

const Kochavit = () => {
    const s = useSelector((state) => state.thisBussinessReducer)
    const navigate = useNavigate()
    const [numForChange, setNumForChange] = useState(false)
    const p = useParams()
    const [numUsersGood, setNumUsersGood] = useState(0)
    const [addZahav, setAddZahav] = useState(false)
    const [error, setError] = useState(false)
    const [phone, setPhone] = useState("")
    const d = useDispatch()
    const cc = JSON.parse(sessionStorage.getItem('mainCC'))
    const [createChanges, setCreateChanges] = useState(false);
    const baseImageUrl = "https://kolsimcha.co.il/images";

    useEffect(() => {
        navigate(`/Kochavit/${p.categoryName}/${p.cc}`)
    }, [numForChange])

    useEffect(() => {
        var sum = 0
        s.bussinessData?.options.forEach(element => {
            if (element.kochvim > 3)
                sum += 1
        });
        setNumUsersGood(sum)
        EditHowEditBussiness(String(s.categoryCode).split(',')[0], s.id)
    }, [])

    // פונקציה שסופרת את המספרים
    var countingElements = document.querySelectorAll('.counting');

    countingElements.forEach(function (element) {
        var countTo = parseInt(element.getAttribute('data-count'));
        var countNum = parseInt(element.textContent);

        var duration = 1500;
        var startTime;

        function animateCount(timestamp) {
            if (!startTime) startTime = timestamp;
            var progress = timestamp - startTime;

            element.textContent = Math.floor((countTo - countNum) * progress / duration + countNum);

            if (progress < duration) {
                requestAnimationFrame(animateCount);
            } else {
                element.textContent = countTo;
                //alert('finished');
            }
        }

        requestAnimationFrame(animateCount);
    });
    //עד כאן פונקציה שסופרת את המספרים

    return <div>
        <div id="picBussinessOut">
            <div style={{ position: 'relative' }}>
                <ImageDiv id="picBussiness" $pic={encodeURI(`${baseImageUrl}${s.mainImage?.replace(/\\/g, '\/')}?format=avif`)} style={{ width: '100%', height: '40vh', backgroundSize: 'cover', transition: '15s' }}></ImageDiv>
                <div style={{ position: 'absolute', backgroundColor: '#9a6a6a7d', width: '100%', height: '100%', top: '0', paddingTop: '26vh', fontSize: '200%', fontWeight: '400', WebkitTextStrokeColor: 'white', WebkitTextStrokeWidth: '1.1px' }}>ניהול העסק {s.name}</div>
            </div>

            <div onClick={() => { navigate(`/bussiness/${p.categoryName ? p.categoryName : null}/${s.name}/${cc}`) }} style={{ backgroundColor: 'rgb(218, 166, 166)', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', borderRadius: '4px', cursor: 'pointer', width: '5%', height: '6vh', paddingTop: '10px', position: 'absolute', top: '41vh', right: '1vh' }}><ArrowRightOutlined /></div>

            {/* להוסיף בשלב שני */}
            {/* <div style={{ marginTop: '5%', width: '80%', marginRight: '10%' }}>
                <div className="ribuha">
                    <div className="inRibuha">מספר המבקרים בעמוד השיווק</div>
                    <div id="ribuha1" className="inRibuhaDown counting" style={{ fontSize: '200%', paddingTop: '2%' }} data-count={s.howManySeeYou}>0</div>
                </div>

                <div className="ribuha">
                    <div className="inRibuha">מספר הפניות במייל</div>
                    <div className="inRibuhaDown counting" style={{ fontSize: '200%', paddingTop: '2%' }} data-count={s.howManySendEmail}>0</div>
                </div>

                <div className="ribuha">
                    <div className="inRibuha">מספר הפניות הטלפוניות (אולי קישור לימות המשיח)</div>
                    <div className="inRibuhaDown counting" style={{ fontSize: '200%', paddingTop: '2%' }} data-count={s.howManyCall}>0</div>
                </div>

                <div className="ribuha">
                    <div className="inRibuha">מספר לקוחות מרוצים</div>
                    <div className="inRibuhaDown counting" style={{ fontSize: '200%', paddingTop: '2%' }} data-count={numUsersGood}>0</div>
                </div>
            </div> */}
        </div>

        <div style={{ width: '100%', backgroundColor: 'rgb(245, 238, 238)', marginTop: '4%', marginBottom: '3%', paddingTop: '4%' }}>

            {/* <p style={{ fontSize: '150%', fontWeight: '500', paddingTop: '5%', width: '30%', marginRight: '35%' }}>עריכת פרטי העסק</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady> */}

            <div style={{ userSelect: 'none' }}>
                <div className="iconGlobal1" onClick={() => { setNumForChange(1) }}>
                    <img loading='lazy' src={icon1} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                    <p style={{ height: '8vh' }}>שינוי סיסמת מנהל</p>
                </div>
                <div className="iconGlobal1" onClick={() => { navigate(`/EditBussinessMain/${2}`) }}>
                    <img loading='lazy' src={icon2} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                    <p style={{ height: '8vh' }}>שינוי פרטי העסק</p>
                </div>
                <div className="iconGlobal1" onClick={() => { navigate(`/EditBussinessMain/${3}`) }}>
                    <img loading='lazy' src={icon3} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                    <p style={{ height: '8vh' }}>החלפת תמונות השייכות לעסק</p>
                </div>
                {(cc === "1" || cc === "82" || ['2', '179', '182'].includes(cc) || cc === "3" || cc === "4") &&
                    <div className="iconGlobal1" onClick={() => { navigate(`/EditBussinessMain/${6}`) }}>
                        <img loading='lazy' src={icon4} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                        <p style={{ height: '8vh' }}>ניהול יומן<br />&nbsp;</p>
                    </div>}
                {cc != '1' && !['2', '179', '182'].includes(cc) && cc != '3' && cc != '82' && cc != '4' &&
                    <div className="iconGlobal1" onClick={() => { navigate(`/EditBussinessMain/${1}`) }}>
                        <img loading='lazy' src={icon5} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                        <p style={{ height: '8vh' }}>שינוי תחום לעסק</p>
                    </div>}
                {['2', '179', '182'].includes(cc) &&
                    <div className="iconGlobal1" onClick={() => { navigate(`/EditBussinessMain/${5}`) }}>
                        <img loading='lazy' src={icon6} style={{ marginRight: 'auto', marginLeft: 'auto', marginBottom: '30px', width: '50px', height: '50px', display: 'block' }}></img>
                        <p style={{ height: '8vh' }}>עריכת שירים<br />&nbsp;</p>
                    </div>}
            </div>
        </div>

        <div style={{ marginBottom: '5%' }}>
            <p style={{ fontSize: '150%', fontWeight: '500', paddingTop: '3%', width: '30%', marginRight: '35%', marginBottom: '4px' }}>ניהול המערכת הטלפונית</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            {!s.phoneToCall && <div style={{ marginTop: '3%' }}>
                <p>הינכם רשומים כעת לחבילת קול ששון.</p>
                <p style={{ fontWeight: '400' }}>כדי שנבנה עבורכם שלוחות מידע ויצירת קשר במערכת הטלפונית ותתחילו לקבל טלפונים מלקוחות -</p>
                <p>הירשמו לחבילת קול שמחה - בעלות נוספת של 70 ש"ח בחודש</p>
                <div className='buton1' style={{ width: '20%', marginRight: '40%' }} onClick={() => { setAddZahav(true) }}><ButtonRed text='הצטרפות לחבילת קול שמחה'></ButtonRed></div>
            </div>}

            {s.phoneToCall && <div style={{ marginTop: '3%' }}>
                {s.numberToCall && <span><p>השלוחה שלכם במערכת הטלפונית היא:</p>
                    <p style={{ direction: 'ltr', fontWeight: '400', marginTop: '0' }}>02-3137663 {'>'} {s.numberToCall}</p>
                </span>}
                {!s.numberToCall && <p>השלוחה שלכם בבניה - בסיום בנית השלוחה תקבלו עדכון.</p>}
                <p>כאשר לקוח מבקש ליצור איתכם קשר טלפוני תבוצע הפניה למספר: <span style={{ fontWeight: '400' }}>{s.phoneToCall}</span></p>
                <div className='buton1' style={{ width: '30%', marginRight: '35%', minWidth: 'max-content' }} onClick={() => { setPhone(s.phoneToCall); setAddZahav(true); }}><ButtonRed text='ערוך את מספר הטלפון'></ButtonRed></div>
                {s.numberToCall && <span><p>ניתן לעדכן נתונים במערכת הטלפונית דרך מזכירות קול שמחה, בתוספת תשלום של 150 ₪, עד שעה עבודה.</p>
                    <div className='buton1' style={{ width: '30%', marginRight: '35%', minWidth: 'max-content' }} onClick={() => { setCreateChanges(true) }}><ButtonRed text='ביצוע שינויים במערכת הטלפונית'></ButtonRed></div>
                </span>}
            </div>}
        </div>

        <hr style={{ backgroundColor: '#555' }}></hr>

        <div>
            <p style={{ fontSize: '150%', fontWeight: '500', paddingTop: '2%', width: '30%', marginRight: '35%', marginBottom: '4px' }}>ניהול מערכת הזמנות דרך האתר</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            {s.bussinessData?.lotOfItems && s.bussinessData?.lotOfItems.length <= 0 && s.phoneToCall && <div style={{ marginTop: '3%' }}>
                <p>הינכם רשומים כעת לחבילת קול שמחה</p>
                <p style={{ fontWeight: '400' }}>כדי להתחיל למכור דרך האתר חבילות / שירותים / מוצרים - </p>
                <p>הירשמו לחבילת קול מצהלות - בעלות נוספת של 280 ש"ח בחודש</p>
                <div className='buton1' style={{ width: '20%', marginRight: '40%', minWidth: 'max-content' }} onClick={() => { navigate(`/EditBussinessMain/${4}`) }}><ButtonRed text='הצטרפות לחבילת קול מצהלות'></ButtonRed></div>
            </div>}

            {s.bussinessData?.lotOfItems && s.bussinessData?.lotOfItems.length <= 0 && !s.phoneToCall && <div style={{ marginTop: '3%' }}>
                <p>הינכם רשומים כעת לחבילת קול ששון</p>
                <p style={{ fontWeight: '400' }}>כדי שנבנה עבורכם שלוחות מידע ויצירת קשר במערכת הטלפונית ותתחילו לקבל טלפונים מלקוחות</p>
                <p style={{ fontWeight: '400' }}>וכדי להתחיל למכור דרך האתר חבילות / שירותים / מוצרים - </p>
                <p>הירשמו לחבילת קול מצהלות - בעלות נוספת של 350 ש"ח בחודש</p>
                <div className='buton1' style={{ width: '20%', marginRight: '40%', minWidth: 'max-content' }} onClick={() => { navigate(`/EditBussinessMain/${4}`) }}><ButtonRed text='הצטרפות לחבילת קול מצהלות'></ButtonRed></div>
            </div>}

            {s.bussinessData?.lotOfItems && s.bussinessData?.lotOfItems.length > 0 && <div style={{ marginTop: '3%' }}>
                <div className='buton1' style={{ width: '30%', marginRight: '35%', minWidth: 'max-content' }} onClick={() => { navigate(`/EditBussinessMain/${4}`) }}><ButtonRed text='ערוך חבילות / שירותים / מוצרים למכירה'></ButtonRed></div>
            </div>}
        </div>



        <BottomMenu></BottomMenu>

        {numForChange && <EditBussiness num={numForChange} set={setNumForChange}></EditBussiness>}

        {addZahav && <>
            <img loading='lazy' style={{ position: 'absolute', top: '0', right: '0', width: '100%', height: 'auto', zIndex: '800', position: 'fixed' }} src={pic2}></img>
            <div id='modal2'>
                <div className='animate__animated animate__zoomIn'>
                    <div className='glow' style={{ width: '30%', position: 'relative' }}>
                        <CloseOutlined className='close2' onClick={() => setAddZahav(false)} style={{ cursor: 'pointer', position: 'absolute', left: '4%', top: '2%', zIndex: '300', color: 'white' }} />
                        <p className='titl' style={{ backgroundColor: '#820909', color: 'white', marginTop: '0', paddingTop: '6%', paddingBottom: '6%', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}>חבילת קול שמחה</p>
                        <p className='titl' style={{ marginTop: '20%' }}>הכניסו מספר טלפון למערכת הטלפונית</p>
                        <input className='input20' id='passManag' style={{ width: '300px' }} onChange={(e) => { if (/^[0-9]*$/.test(e.target.value)) { setPhone(e.target.value) } }} onClick={(e) => e.target.style.width = '90%'} onBlur={(e) => e.target.style.width = '50%'} placeholder="מספר טלפון" type="text" value={phone} />
                        <div id='submit30' onClick={() => {
                            if (phone.length != 10 && phone.length != 9 && phone.length != 5) { setError('מספר טלפון לא תקין') } else {
                                setError("");
                                if (!s.phoneToCall)
                                    AddPhoneToCall(s.id, s.categoryCode, phone).then((data) => {
                                        d(COMPLETEphone(phone)); s.phoneToCall = phone; setAddZahav(false);
                                        var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום לקול שמחה </p></div><br/> <br/> עסק קיים התחבר למערכת הטלפונית,  <br/> נא לחבר אותו בהקדם - הוא כבר התחיל לשלם. <br/> <br/>  <b>שם העסק: </b> ${s.name}.<br/>  <b>תחום: </b> ${s.categoryCode}. <br/> <b>מספר טלפון: </b> ${s.phone}<br/> <b>כתובת מייל: </b> ${s.mail}<br/><br/> <b>מספר הטלפון אותו לחבר למערכת הטלפונית: </b><br/> ${phone}<br/><br/>  לאחר בנית השלוחה - נא להודיע ללקוח שהתחבר בהצלחה, ולשלוח לו את מספר הטלפון שלנו + מספר השלוחה שלו  <br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
                                        SendEmailToBussiness({ name: `${s.name}*-*${s.city}*-*${s.phone}`, emailTo: 'K3137663@gmail.com', emailFrom: 'info@kolsimcha.co.il', subject: 'מיזם קול שמחה - עסק קיים התחבר למערכת הטלפונית - נא להוסיף', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                                    }).catch((error) => { console.error(error); });
                                else
                                    EditPhoneToCall(String(s.categoryCode).split(',')[0], s.id, phone).then((data) => {
                                        d(COMPLETEphone(phone)); s.phoneToCall = phone; setAddZahav(false);
                                        var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום לקול שמחה </p></div><br/> <br/> עסק שינה מספר טלפון למערכת הטלפונית,  <br/> נא לשנות אצלו בשלוחה. <br/> <br/>  <b>שם העסק: </b> ${s.name}.<br/>  <b>תחום: </b> ${s.categoryCode}. <br/> <b>מספר טלפון: </b> ${s.phone}<br/> <b>כתובת מייל: </b> ${s.mail}<br/><br/> <b>מספר הטלפון החדש אותו לחבר למערכת הטלפונית: </b><br/> ${phone}<br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
                                        SendEmailToBussiness({ name: `${s.name}*-*${s.city}*-*${s.phone}`, emailTo: 'K3137663@gmail.com', emailFrom: 'info@kolsimcha.co.il', subject: 'מיזם קול שמחה - עסק שינה מספר טלפון למערכת הטלפונית', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                                    }).catch((error) => { console.error(error); });
                            }
                        }}><ButtonRed text={'אישור'}></ButtonRed></div>
                        {error && <p> <img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img> {error}</p>}
                    </div>
                </div>
            </div>
        </>}

        {createChanges && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <div className='glow' style={{ position: 'relative' }}>
                    <CloseOutlined className='close2' onClick={() => { setCreateChanges(false) }} style={{ cursor: 'pointer', position: 'absolute', left: '3%', marginTop: '3%' }} />
                    <div className='titl' style={{ marginTop: '5%', marginBottom: '3%' }}>השינויים לביצוע במערכת הטלפונית:</div>
                    <textarea id="createChangesTextarea" style={{ width: '60%', marginBottom: '2%' }} placeholder="מהם השינויים?" type="text" />
                    <input id="createChangesInput" style={{ width: '60%', marginBottom: '2%' }} type="file" />
                    <div className='buton' onClick={() => {
                        var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 23px ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 23px;">עסק רוצה לבצע שינוי במערכת הטלפונית</p></div><br/> <br/><b>שם העסק: </b> ${s.name}.<br/><b>מספר טלפון: </b> ${s.phone}.<br/><b>כתובת מייל: </b> ${s.mail}.<br/> <br/>תוכן הבקשה:<br/> ${document.getElementById('createChangesTextarea').value}<br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
                        const formData = new FormData();
                        formData.append('name', "");
                        formData.append('emailFrom', s.mail);
                        formData.append('subject', `עסק רוצה לבצע שינוי במערכת הטלפונית`);
                        formData.append('message', mes);
                        formData.append('attachment', document.getElementById('createChangesInput').files[0]); // הוספת הקובץ
                        SendEmailChangeInPhone(formData).then(() => {
                            setCreateChanges(false)
                        }).catch((error) => { console.error(error); });

                    }} style={{ width: '20%', marginRight: 'auto', marginLeft: 'auto' }}><ButtonRed text='שלח'></ButtonRed></div>
                </div>
            </div>
        </div>}

    </div>
}

export default Kochavit