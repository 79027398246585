const shoppingCart = {
    allShoppingCart: [],
    shoppingCartLotOfItems: []
};

const shoppingCartReducer = (state = shoppingCart, action) => {
    switch (action.type) {
        case "COMPLETEallShoppingCart":
            return { ...state, allShoppingCart: action.payload };
        case "COMPLETEshoppingCartLotOfItems":
            return { ...state, shoppingCartLotOfItems: action.payload };
        case "COMPLETEDelCart":
            return { ...state, allShoppingCart: state.allShoppingCart.filter(a => a.id !== action.payload) };
        // case "GETshoppingCartLotOfItems":
        //     return state.shoppingCartLotOfItems;
        // case "GETallShoppingCart":
        //     return state.allShoppingCart;
        default:
            return state;
    }
};



export default shoppingCartReducer;
