import { useEffect, useState } from "react";
import { AddPayManagger, DeletePayManagger, EditPayManagger, GetAllPays } from "../../axios/api";
import { IconButton } from "@mui/material";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import RefReady from "../../global/ref";
import Search from "../Search";
import Wait from "../../global/Wait";
import { useDispatch, useSelector } from "react-redux";
import { COMmanaggerPAYS, COMmanaggerSUMPAYS } from "../../2. redux/actions/managgerActions";
import { Fragment } from "react";
import ButtonRed from "../../global/button/button";
import AlertDelete from "../../global/alert/AlertDelete";

const PaysManag = () => {

    const [allPays, setAllPays] = useState([]);
    const [tempAllPays, setTempAllPays] = useState([]);

    const [wait, setWait] = useState(true);
    const [edit, setEdit] = useState(false);
    const [add, setAdd] = useState(false);
    const [del, setDel] = useState(false);

    const [payEdit, setPayEdit] = useState({ id: '', name: '', priceRange: '', averagePrice: '', isChtanOrKalaOr2: '' });
    const [sum, setSum] = useState(0);
    const d = useDispatch()
    const paysSelector = useSelector(a => a.managgerReducer.pays)

    const delPay = () => {
        var d = del
        setDel(false)
        DeletePayManagger(d).then((data) => { setAllPaysWhisSum(data); }).catch((error) => { console.error(error); });
    }

    const setAllPaysWhisSum = (data) => {
        var ssum = 0;
        data.forEach(element => {
            ssum = ssum + element.averagePrice;
        });
        setAllPays(data)
        setSum(ssum)
        setTempAllPays(data)
        d(COMmanaggerPAYS(data))
        d(COMmanaggerSUMPAYS(ssum))
    }

    useEffect(() => {
        if (paysSelector.length > 0) {
            setAllPays(paysSelector.allPays)
            setTempAllPays(paysSelector)
            setWait(false)
            setSum(paysSelector.sum)
        }
        else {
            GetAllPays().then((data) => {
                setAllPaysWhisSum(data)
                setWait(false)
            }).catch((error) => { console.error(error); });
        }
    }, []);

    const funcSearch = (inp) => {
        setAllPays(tempAllPays.filter(a => a.name.includes(inp) || String(a.id).includes(inp)))
        window.scrollTo(0, 0);
    }

    const dinamicWidth = (num) => {
        const input = document.getElementsByClassName("inp")[num];
        input.style.width = '28%'
        setTimeout(() => {
            input.style.width = "auto"; // Reset to auto width to measure the content
            input.style.width = (input.scrollWidth + 5) + "px"; // Add some padding or margin if needed
        }, 1000);
    }

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const div = document.getElementById('firstDivTitles');
            if (div !== null)
                setIsSticky(div.getBoundingClientRect().top <= 55);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    // --------------------------------------------end scroll------------------------------------------------


    return <div>
        {wait && <Wait></Wait>}
        <Search funcSearch={funcSearch} txt={"ברשימת ההוצאות"}></Search>

        {allPays.length === 0 && <>
            <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%', marginBottom: '30px' }}>רשימת מטלות</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-25px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
            <div style={{ padding: '7%' }}>לא נמצאו תוצאות</div>
        </>}

        <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%', marginBottom: '30px' }}>רשימת ההוצאות</p>
        <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-25px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

        {allPays && <table style={{ marginTop: '3%', width: '80%', marginRight: '10%', textAlign: 'center' }} className="animate__animated animate__bounceInUp">
            <thead>
                <tr id="firstDivTitles" className={isSticky ? 'sticky1' : ''}>
                    <th style={{ fontWeight: '400', width: '10%', textAlign: 'center' }}>קוד</th>
                    <th style={{ fontWeight: '400', width: '35%', textAlign: 'center' }}>שם ההוצאה</th>
                    <th style={{ fontWeight: '400', width: '20%', textAlign: 'center' }}>טווח מחירים מקובל בשוק</th>
                    <th style={{ fontWeight: '400', width: '20%', textAlign: 'center' }}>ממוצע מחיר מקובל בשוק</th>
                    <th style={{ fontWeight: '400', width: '20%', textAlign: 'center' }}>לאיזה צד שייכת ההוצאה</th>
                    <th style={{ fontWeight: '400', width: '20%', textAlign: 'center' }}></th>
                    <th style={{ fontWeight: '400', width: '20%', textAlign: 'center' }}></th>
                </tr>
            </thead>
            <tbody>
                {allPays.map((pay, i) => (
                    <tr style={{ fontWeight: '300', textAlign: 'center', borderTop: '#c00000 1px solid' }} key={i}>
                        {edit.id !== pay.id && <Fragment>
                            <td style={{ textAlign: 'center' }}>{pay.id}</td>
                            <td style={{ textAlign: 'center' }}>{pay.name}</td>
                            <td style={{ textAlign: 'center' }}>{pay.priceRange}</td>
                            <td style={{ textAlign: 'center' }}>{pay.averagePrice} {pay.averagePrice > 0 ? 'ש"ח' : ''}</td>
                            <td style={{ textAlign: 'center' }}>{pay.isChtanOrKalaOr2 === 1 ? "כלה" : pay.isChtanOrKalaOr2 === 0 ? "חתן" : "שני הצדדים"}</td>
                            <td><IconButton onClick={() => { setEdit(pay); setPayEdit(pay) }} className='IconButton' size="medium">
                                <EditOutlined />
                            </IconButton></td>
                            <td><IconButton onClick={() => { setDel(pay.id) }} className='IconButton' size="medium">
                                <CloseOutlined />
                            </IconButton></td>
                        </Fragment>}
                        {edit.id === pay.id && <Fragment>
                            <td style={{ textAlign: 'center' }}>{pay.id}</td>
                            <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={payEdit.name} onChange={(e) => { if (e.target.value.length <= 50) { setPayEdit({ ...payEdit, name: e.target.value }) } }} defaultValue={pay.name}></input></td>
                            <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={payEdit.priceRange} onChange={(e) => { if (e.target.value.length <= 20) { setPayEdit({ ...payEdit, priceRange: e.target.value }) } }} defaultValue={pay.priceRange}></input></td>
                            <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={payEdit.averagePrice} onChange={(e) => { if (e.target.value <= 922337203685477 && (/^[0-9]*$/.test(e.target.value) || e.target.value === '')) { setPayEdit({ ...payEdit, averagePrice: e.target.value }) } }} defaultValue={pay.averagePrice}></input></td>
                            <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={payEdit.isChtanOrKalaOr2} onChange={(e) => { if (e.target.value <= 32767 && (/^[0-9]*$/.test(e.target.value) || e.target.value === '')) { setPayEdit({ ...payEdit, isChtanOrKalaOr2: e.target.value }) } }} defaultValue={pay.isChtanOrKalaOr2}></input></td>
                            <div onClick={() => { EditPayManagger(payEdit).then(data => { setAllPaysWhisSum(data) }).catch(error => console.error(error)); setPayEdit({ id: '', name: '', priceRange: '', averagePrice: '', isChtanOrKalaOr2: '' }); setEdit(false) }} style={{ width: '150%', marginTop: '18%', marginRight: '10%' }}><ButtonRed text='אישור'></ButtonRed></div>
                        </Fragment>}
                    </tr>
                ))}
                <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                    <td></td><td></td>
                    <td><span style={{ fontWeight: '400', marginBottom: '17%', marginLeft: '5%' }}>סה"כ: </span>{sum} ש"ח</td>
                </tr>
            </tbody>
        </table>}
        <div onClick={() => { setEdit(false); setPayEdit({ id: '', name: '', priceRange: '', averagePrice: '', isChtanOrKalaOr2: '' });; setAdd(true) }} className='buton' style={{ marginTop: '0', float: 'left', marginLeft: '10%', width: 'fit-content', cursor: 'pointer' }}><ButtonRed text='הוספת הוצאה'></ButtonRed></div>

        {del && <AlertDelete set={setDel} func={delPay} text={'הוצאה'}></AlertDelete>}

        {add && <div id='modal2'>
            <div className='animate__animated animate__zoomIn form78'>
                <CloseOutlined className='close2' onClick={() => { setAdd(false); setPayEdit({ id: '', name: '', priceRange: '', averagePrice: '', isChtanOrKalaOr2: '' }); }} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%' }}>
                    <div className='titl' style={{ marginTop: '3%', fontSize: '120%', marginBottom: '3%' }}>הוספת הוצאה</div>

                    <input className='inp' autoComplete="on" type="text" value={payEdit.name} onChange={(e) => { if (e.target.value.length <= 50) { setPayEdit({ ...payEdit, name: e.target.value }) } }} placeholder='שם ההוצאה' onClick={(e) => e.target.style.width = '80%'} onBlur={() => dinamicWidth(0)} ></input>
                    <input className='inp' autoComplete="on" type="text" value={payEdit.priceRange} onChange={(e) => { if (e.target.value.length <= 20) { setPayEdit({ ...payEdit, priceRange: e.target.value }) } }} placeholder='טווח מחירים מקובל בשוק' onClick={(e) => e.target.style.width = '80%'} onBlur={() => dinamicWidth(1)} ></input>
                    <input className='inp' autoComplete="on" type="text" value={payEdit.averagePrice} onChange={(e) => { if (e.target.value <= 922337203685477 && (/^[0-9]*$/.test(e.target.value) || e.target.value === '')) { setPayEdit({ ...payEdit, averagePrice: e.target.value }) } }} placeholder='מחיר ממוצע מקובל בשוק' onClick={(e) => e.target.style.width = '80%'} onBlur={() => dinamicWidth(2)} ></input>
                    <input className='inp' autoComplete="on" type="text" value={payEdit.isChtanOrKalaOr2} onChange={(e) => { if (e.target.value <= 32767 && (/^[0-9]*$/.test(e.target.value) || e.target.value === '')) { setPayEdit({ ...payEdit, isChtanOrKalaOr2: e.target.value }) } }} placeholder='למי שייכת ההוצאה? (חתן: 0, כלה: 1, שני הצדדים: 2)' onClick={(e) => e.target.style.width = '80%'} onBlur={() => dinamicWidth(3)} ></input>
                    <p>חתן: 0, כלה: 1, שני הצדדים:2.</p>
                    <div className='buton1' style={{ width: '20%', marginRight: 'auto', marginLeft: 'auto', marginTop: '2%' }} onClick={() => { AddPayManagger({ ...payEdit, id: 0 }).then((data) => { setAllPaysWhisSum(data); setPayEdit({ id: '', name: '', priceRange: '', averagePrice: '', isChtanOrKalaOr2: '' }); setAdd(false) }).catch((error) => console.error(error)); }}><ButtonRed text='הוסף'></ButtonRed></div>
                    {/* {errorMessage && <p><img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>} */}
                </div>
            </div>
        </div>}

    </div >
}

export default PaysManag