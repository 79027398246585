import './AddToDataBase.css'
import { ArrowRightOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import ButtonRed from "../../../global/button/button"
import RefReady from "../../../global/ref"
import Alert from "../../../global/alert/Alert"
import BottomMenu from "../../../global/bottomMenu/BottomMenu"
import { useNavigate, useParams } from "react-router-dom"
import EditBussinessPictures from "./EditBussinessPictures"
import { useDispatch, useSelector } from "react-redux"
import EditBussinessCategory from './EditBussinessCategory'
import EditBussinessDetails from './EditBussinessDetails'
import EditBussinessLotOfItems from './EditBussinessLotOfItems'
import EditBussinessSongs from './EditBussinessSongs'
import EditBussinessCalander from './EditBussinessCalander'
import { CompleteThisBussiness } from '../../../2. redux/actions/thisBussinessActions'
import { GetOneBussinessWhisData } from '../../../axios/api'
import ErrorPass from '../../10. login/ErrorPass'
import picError from '../../../../pic/animations/113.gif'
import pic2 from '../../../../pic/logo/444.jpg'

const EditBussinessMain = () => {

    const [alert2, setAlert2] = useState(false)
    const [send1, setSend1] = useState(false)
    const [isCalander, setIsCalander] = useState(true)
    const [alertErrorPass, setAlertErrorPass] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');

    const p = useParams()
    const which = Number(p.which)
    const s = useSelector((state) => state.thisBussinessReducer);
    const n = useNavigate()
    const d = useDispatch()

    useEffect(() => {
        // לעסק שנכנס דרך קישור לניהול יומן
        if (p.codeCategory) {
            setIsCalander(false)
            sessionStorage.setItem('noSystemMessage', JSON.stringify(true))
            GetOneBussinessWhisData(Number(p.codeCategory), Number(p.bussinessId)).then((data) => {
                d(CompleteThisBussiness(data));
            }).catch((error) => { console.error(error); });
        }
    }, [])

    const sendGood = () => {
        setAlert2(false); setSend1("ההודעה נשלחה בהצלחה !!!")
        setTimeout(() => { setSend1(false) }, 40 * 100);
    }

    const passBussinessSubmit = () => {
        setErrorMessage('')
        if (Number(document.getElementById('passManag').value) !== s.code)
            setErrorMessage('סיסמה שגויה')
        else { setIsCalander(true) }
    }

    return <div>
        <div className='buton1' style={{ width: '15%', position: 'absolute', top: '15%', left: '7%' }} onClick={() => { setAlert2(true) }}><ButtonRed text='תמיכה טכנית'></ButtonRed></div>

        <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%' }}>עריכת פרטי העסק {s.name}</p>
        <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-25px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
        {!p.codeCategory && <div onClick={() => { n(-1) }} style={{ backgroundColor: 'rgb(218, 166, 166)', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', borderRadius: '4px', cursor: 'pointer', width: '5%', height: '6vh', paddingTop: '10px', position: 'absolute', top: '20%', right: '17%', zIndex: '20' }}><ArrowRightOutlined /></div>}

        <div className='glow' style={{ position: 'relative', width: '70%', height: 'auto', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '15%', marginTop: '2%' }}>
            <div id='newBusinessForm' style={{ marginTop: '5%' }}>

                {/* המסך של בחירת תחום */}
                {which === 1 && <EditBussinessCategory></EditBussinessCategory>}
                {/* מילוי הפרטים + תשובות לשאלות נפוצות */}
                {which === 2 && <EditBussinessDetails></EditBussinessDetails>}
                {/* מסך מילוי תמונות */}
                {which === 3 && <EditBussinessPictures></EditBussinessPictures>}
                {/* מסך הכנסת מוצרים */}
                {which === 4 && <EditBussinessLotOfItems></EditBussinessLotOfItems>}
                {/* מסך הכנסת שירים */}
                {which === 5 && <EditBussinessSongs></EditBussinessSongs>}
                {/* מסך לניהול יומן */}
                {which === 6 && isCalander && <EditBussinessCalander></EditBussinessCalander>}

            </div>
        </div>

        {alert2 && <Alert set={setAlert2} sendGood={sendGood} text={"קול שמחה"} text2={"יש לכם שאלה? צריכים עזרה? אנחנו תמיד לשירותכם, נשמח לענות בהקדם"} mail={"info@kolsimcha.co.il"}></Alert>}

        {send1 && <div className="send animate__animated animate__bounceInUp"><div style={{ lineHeight: '8vh', textAlign: 'center', fontWeight: '400' }}>{send1}</div></div>}

        {which === 6 && !isCalander && <>
            <img loading='lazy' style={{ position: 'absolute', top: '0', right: '0', width: '100%', zIndex: '800' }} src={pic2}></img>
            <div id='modal2'>
                <div className='animate__animated animate__zoomIn'>
                    <div className='glow' style={{ width: '30%' }}>
                        <p className='titl' style={{ backgroundColor: '#820909', color: 'white', marginTop: '0', paddingTop: '6%', paddingBottom: '6%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>כניסה זו מיועדת לבעל העסק {s.name}</p>
                        <p className='titl' style={{ marginTop: '12%' }}>מהו קוד העסק?</p>
                        <input className='input20' id='passManag' style={{ width: '300px' }} onClick={(e) => e.target.style.width = '80%'} onBlur={(e) => e.target.style.width = '20%'} placeholder="סיסמה" type="password" />
                        <div id='submit30' onClick={() => { if (Number(document.getElementById('passManag').value) === s.code) { setErrorMessage(''); setIsCalander(true) } else {setErrorMessage('סיסמה שגויה')} }}><ButtonRed text={'אישור'}></ButtonRed></div>
                        {errorMessage && <p style={{ marginBottom: '-1%' }}><img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}
                        <br></br>
                        <div className='errorPass' onClick={() => { setAlertErrorPass(true); }}>שיחזור סיסמה</div>
                        {alertErrorPass && <ErrorPass set={setAlertErrorPass} bussiness={s} good={passBussinessSubmit}></ErrorPass>}
                    </div>
                </div>
            </div>
        </>}

        <div style={{ margin: '10%' }}></div>
        <BottomMenu></BottomMenu>
    </div>
}

export default EditBussinessMain