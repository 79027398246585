import './AddToDataBase.css'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import picError from '../../../../pic/animations/113.gif'
import ButtonRed from "../../../global/button/button";
import { CloseOutlined } from "@ant-design/icons";
import { EditCategoryCode, GetAllCategoriesOrDevition } from "../../../axios/api";
import Wait from "../../../global/Wait";
import { CompleteThisBussiness } from "../../../2. redux/actions/thisBussinessActions";

const EditBussinessCategory = (props) => {

    const [top100Films, setTop100Films] = useState();
    const [resultSearch, setResultSearch] = useState([])
    const [categoryName, setCategoryName] = useState('')
    const [cCode, setCCode] = useState();
    const [moreCategories, setMoreCategories] = useState([]);
    const [shopCategories, setShopCategories] = useState([]);
    const [shopCategoriesNames, setShopCategoriesNames] = useState([]);
    const [whichCategoryToOpen, setWhichCategoryToOpen] = useState(-1);
    const [errorMessage, setErrorMessage] = useState('');
    const [think, setThink] = useState(false)
    const [wait, setWait] = useState(true);
    const [cCodeMain, setCCodeMain] = useState();
    const isAddBus = props.isAddBus;

    const s = useSelector((state) => state.thisBussinessReducer);
    const n = useNavigate()
    const d = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0);
        GetAllCategoriesOrDevition().then((data) => {
            var newSet = Array.from(new Set(data.map(obj => obj.name))).map(name => data.find(obj => obj.name === name));
            if (!isAddBus)
                newSet = newSet.filter(a => a.code != 4 && a.code != 1 && !['2', '179', '182'].includes(a.code) && a.code != 3 && a.code != 82);
            var ccFirst = String(s.categoryCode).split(",")[0]
            var top100 = newSet.filter(a => a.id == ccFirst)[0]
            setCategoryName(top100?.name); setCCode(top100?.id); setCCodeMain(top100?.code)
            if (top100?.titleId)
                setMoreCategories(newSet.filter(a => a.code == top100.code));
            if (String(s.categoryCode).split(",").length > 1) {
                var ShopC = s.categoryCode.split(","), ShopCNames = [], shops = [];
                ShopC.splice(0, 1);
                ShopC.forEach(c => {
                    ShopCNames.push(newSet.filter(x => x.id == c)[0].name)
                    shops.push(Number(c))
                });
                setShopCategories(shops); setShopCategoriesNames(ShopCNames);
            }
            setTop100Films(newSet); setWait(false);
        }).catch((error) => { console.error(error); });
    }, []);

    const searchData = (search) => {
        var result = []
        for (let i = 0; i < top100Films?.length; i++) {
            if (top100Films[i].name.includes(search))
                result.push(top100Films[i])
        }
        setResultSearch(result)
    }

    const filterResultSearch = (arrResultSearch) => {
        arrResultSearch = arrResultSearch.filter(a => a.code !== 1 && a.code !== 82 && a.code !== 2 && a.code !== 3 && a.code !== 4)
        var temp;
        //מוריד את הקטגוריה הראשונה
        temp = top100Films.filter(a => a.id === cCode)[0]
        if (temp && temp.titleId !== 0)
            arrResultSearch = arrResultSearch.filter(a => a.code !== temp.code)
        else
            arrResultSearch = arrResultSearch.filter(a => a.id !== cCode)
        //מוריד את שאר הקטגוריות
        for (let i = 0; i < shopCategories.length; i++) {
            temp = top100Films.filter(a => a.id === shopCategories[i])[0]
            if (temp && temp.titleId !== 0)
                arrResultSearch = arrResultSearch.filter(a => a.code !== temp.code)
            else
                arrResultSearch = arrResultSearch.filter(a => a.id !== shopCategories[i])
        }
        return arrResultSearch;
    };

    const onChoozeCategory = (i, x) => {
        setShopCategoriesNames((prevNames) => { const updatedNames = [...prevNames]; updatedNames[i] = x.name; return updatedNames; });
        setShopCategories((prevNames) => { const updateCodes = [...prevNames]; updateCodes[i] = x.id; return updateCodes; });
        setResultSearch([]);
    };

    const handleCategoryCodeChange = (e) => {
        var top100 = top100Films.filter(a => a.id == e)[0]
        if (top100.titleId)
            setMoreCategories(top100Films.filter(a => a.code == top100.code))
        setCategoryName(top100.name)
        setCCode(top100.id)
        setCCodeMain(top100.code)
    };

    const handleSubmit = (e) => {//סוף
        e.preventDefault();
        setErrorMessage('');
        if (!cCode) { setErrorMessage('עדיין לא נבחר תחום ראשי'); }
        else {
            setThink(true);
            var newCC = top100Films.filter(x => x.id === cCode)[0].titleId !== 0 ? cCodeMain : cCode;
            sessionStorage.setItem('SpecialFields', JSON.stringify(top100Films.find(x => x.id === newCC).specialFields ?? []))
            if (![1, 2, 3, 82, 4].includes(cCodeMain)) {
                shopCategories.forEach(c => { // מוסיף אם יש קטגוריות נוספות
                    var top100 = top100Films.filter(x => x.id === c)[0]
                    top100.titleId !== 0 ? newCC += ',' + top100.code : newCC += ',' + c;
                });
            }
            if (!isAddBus)
                EditCategoryCode(s.id, s.categoryCode, newCC).then((data) => {
                    d(CompleteThisBussiness({ ...s, categoryCode: newCC }))
                    n(-1);
                }).catch((error) => { console.error(error); setThink(false); });
            else {
                d(CompleteThisBussiness({ ...s, categoryCode: newCC }));
                sessionStorage.setItem('mainCC', JSON.stringify(String(cCodeMain)))
                //קוד לשמירת התחומים אליהם העסק משתייך - כדי להשתמש בזה אח"כ בשליחת מיילים לעסק ולקול שמחה
                var op = moreCategories.length > 0 ? "" : `<b>תחום 1:</b> ${categoryName}. <br/>`, sumOp = 1;
                moreCategories.forEach(c => { op += `<b>תחום ${sumOp++}:</b> ${c.name}. <br/>` });
                shopCategories.forEach(c => { op += `<b>תחום ${sumOp++}:</b> ${top100Films.filter(a => a.id === c)[0].name}. <br/>` });
                sessionStorage.setItem('optionsNames', JSON.stringify(String(op)))
                // קוד להמשך אם נחליט להוסיף את כל הקטגוריות שהצטרפו אוטומטית
                // for (let i1 = 0; i1 < shopCategories.length; i1++) { temp = top100Films.filter(a => a.id === shopCategories[i1])[0]; if (temp) { if (temp.titleId !== 0) { arrMoreCodes = top100Films.filter(a => a.code === temp.code); for (let i2 = 0; i2 < arrMoreCodes.length; i2++) { op += `<b>תחום ${sumOp++}:</b> ${arrMoreCodes[i2].name}. <br/>` } } else {op += `<b>תחום ${sumOp++}:</b> ${shopCategoriesNames[i1]}. <br/>`} } }
                props.enter()
            }
        }
    }

    return <div>
        <form onSubmit={handleSubmit}>

            <p style={{ fontSize: '110%' }}>בחרו מהו תחום העסק?</p>
            <input id="search1" type="text" className="input20" value={categoryName} onChange={(e) => { searchData(e.target.value); setCategoryName(e.target.value); setMoreCategories([]); if (cCode) { setCCode(null) }; if (resultSearch.length === 1 && resultSearch[0].name === e.target.value) { setCategoryName(resultSearch[0].name); searchData(resultSearch[0].name); handleCategoryCodeChange(resultSearch[0].id); setResultSearch([]) } }} onClick={(e) => { searchData(e.target.value); setWhichCategoryToOpen(-1) }} onBlur={() => setTimeout(() => { setResultSearch([]) }, 500)} autoComplete="off" autoCapitalize="off"></input>
            {resultSearch[0] && whichCategoryToOpen === -1 && <div className='resultSearchRound animate__animated animate__fadeInDown' style={{ width: '30%', marginRight: 'auto', marginLeft: 'auto', maxHeight: '30vh', marginTop: '-3%', minWidth: 'max-content' }}>{resultSearch.map((x, i) => (
                <div className='resultSearch' key={i} onClick={(e) => { setCategoryName(x.name); searchData(x.name); handleCategoryCodeChange(x.id); setResultSearch([]) }}>{x.name}</div>
            ))}</div>}

            {moreCategories.length > 1 && <div>
                <p style={{ fontWeight: '400' }}>העסק שלכם יכנס אוטומטית גם לקטגוריות הבאות:</p>
                {moreCategories.map((x, i) => (
                    <p key={i}>{x.name}</p>
                ))}
                <hr style={{ width: '20%', marginRight: 'auto', marginLeft: 'auto' }}></hr>
            </div>}

            {![1, 2, 3, 82, 4].includes(cCodeMain) && <div>
                {shopCategories.map((x, i) => (<span key={i}>
                    {/* {shopCategories[i] && <p style={{ fontSize: '110%', fontWeight:'400' }}>תחום נוסף:</p>} */}
                    {!shopCategories[i] && <p style={{ fontSize: '110%', fontWeight: '400' }}>בחרו תחום נוסף:</p>}
                    <input id="search1" type="text" className="input20" value={shopCategoriesNames[i]} onChange={(e) => {
                        searchData(e.target.value); setShopCategoriesNames((prevNames) => { const updatedNames = [...prevNames]; updatedNames[i] = e.target.value; return updatedNames; }); setShopCategories((prevNames) => { const updateCodes = [...prevNames]; updateCodes[i] = null; return updateCodes; });
                        if (filterResultSearch(resultSearch).length === 1 && filterResultSearch(resultSearch)[0].name === e.target.value) { onChoozeCategory(i, filterResultSearch(resultSearch)[0]) }
                    }}
                        onClick={(e) => { searchData(e.target.value); setWhichCategoryToOpen(i); }} onBlur={() => setTimeout(() => { setResultSearch([]); }, 500)} autoComplete="off" autoCapitalize="off" style={{ display: 'inline-block', marginRight: '20%' }} />
                    <CloseOutlined className='closeCategory' onClick={() => { setShopCategories((prevCategories) => prevCategories.filter((_, index) => index !== i)); setShopCategoriesNames((prevNames) => prevNames.filter((_, index) => index !== i)); }} style={{ cursor: 'pointer', display: 'inline-block', fontSize: '17px', marginLeft: '20%' }} />
                    {filterResultSearch(resultSearch)[0] && whichCategoryToOpen === i && <div className='resultSearchRound animate__animated animate__fadeInDown'
                        style={{ width: '30%', marginRight: 'auto', marginLeft: 'auto', maxHeight: '30vh', marginTop: '-3%' }}>
                        {filterResultSearch(resultSearch).map((x, j) => (<div className='resultSearch' key={j} onClick={() => { onChoozeCategory(i, x) }}>
                            {x.name}
                        </div>))}
                    </div>}


                    {shopCategories[i] && top100Films.filter(a => a.id === shopCategories[i])[0]?.titleId !== 0 && top100Films.filter(a => a.code === top100Films.filter(a => a.id === shopCategories[i])[0]?.code).length > 1 && <div>
                        <p style={{ fontWeight: '400', marginTop: '-10px' }}>העסק שלכם יכנס אוטומטית גם לקטגוריות הבאות:</p>
                        {top100Films.filter(a => a.code === top100Films.filter(a => a.id === shopCategories[i])[0].code).map((x, indx) => (
                            <p key={indx}>{x.name !== shopCategoriesNames[i] && <span>{x.name}</span>}</p>
                        ))}
                        <hr style={{ width: '20%', marginRight: 'auto', marginLeft: 'auto' }}></hr>
                    </div>}

                </span>))}

                {shopCategories.length < 7 && (<button type="button" className="submit1 input" onClick={() => setShopCategories([...shopCategories, ''])} style={{ display: 'block', marginRight: 'auto', marginLeft: 'auto', minWidth: 'max-content', width: '30%' }}>העסק משתייך לתחום נוסף</button>)}
            </div>}

            <div style={{ marginBottom: '20%' }}></div>
            <span style={{ cursor: `${think ? 'wait' : 'pointer'}` }}><button type="submit" className='buton' style={{ pointerEvents: `${think ? 'none' : 'auto'}`, width: '20%', minWidth: '20vh', marginTop: '-10%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto', zIndex: '0' }}><ButtonRed text={isAddBus ? 'המשך    -->' : 'סיים עריכה'}></ButtonRed></button></span>
            {errorMessage && <p><img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}
        </form>

        {wait && <Wait></Wait>}

    </div>
}

export default EditBussinessCategory