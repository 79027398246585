import { ArrowUpOutlined } from '@ant-design/icons';
import './OnScroll.css'

const OnScroll = () => {
  // --------------------------start top button-----------------------------
  window.onscroll = () => scrollFunction()
  
  function scrollFunction() {
    const topButton = document.getElementById('topBtn')
    if (topButton) {
      if ((document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) && window.innerWidth > 768) {
        topButton.style.display = "block";
      } else {
        topButton.style.display = "none";
      }
    }
  }

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  // --------------------------end top button-----------------------------
  return <button className='animate__animated animate__fadeInUp' onClick={() => topFunction()} id='topBtn'><ArrowUpOutlined /></button>
}

export default OnScroll