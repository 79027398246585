import './Bussiness.css'
import ButtonRed from '../button/button';
import picError from '../../../pic/animations/113.gif'
import ErrorPass from '../../1. menu/10. login/ErrorPass';
import { GetBussinessEnter, GetOneBussinessWhisData } from '../../axios/api';
import { CompleteThisBussiness } from '../../2. redux/actions/thisBussinessActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const BusinessArea = () => {

    const [errorMessage, setErrorMessage] = useState('');
    const [alert, setAlert] = useState(false);
    const d = useDispatch()
    const n = useNavigate()

    const enterBussiness = () => {
        setErrorMessage('')
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(document.getElementById('field1').value))
            setErrorMessage('כתובת מייל לא תקינה');
        else if (document.getElementById('field2').value.length === 0)
            setErrorMessage('קוד ניהול הוא שדה חובה');
        else {
            GetBussinessEnter(document.getElementById('field1').value, document.getElementById('field2').value).then((data) => {
                if (data === "" || data[0] === "null") { setErrorMessage('קוד ניהול העסק שגוי!'); }
                else
                    GetOneBussinessWhisData(data[0], data[1]).then((dataBus) => {
                        d(CompleteThisBussiness(dataBus))
                        sessionStorage.setItem('mainCC', JSON.stringify(data[2]))
                        n(`/Kochavit/${dataBus.name}/${data[2]}`);
                    }).catch((error) => { console.error(error); });
            });
        }
    }

    return <div>
        <div id='PicBack' style={{ width: '100%', height: '100vh', zIndex: '800', position: 'absolute', top: '0', right: '0' }}>
            <div className='glow animate__animated animate__zoomIn' style={{ width: '500px', maxWidth: '80%' }}>
                <p className='titl' style={{ backgroundColor: '#820909', color: 'white', marginTop: '0', paddingTop: '6%', paddingBottom: '6%', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>כניסת עסקים</p>
                <p className='titl' style={{ marginTop: '20%' }}>הכניסו פרטים:</p>
                <input className='input20' id='field1' style={{ width: '300px' }} onClick={(e) => e.target.style.width = '90%'} onBlur={(e) => e.target.style.width = '50%'} placeholder="כתובת מייל" type="email" />
                <input className='input20' id='field2' style={{ width: '300px' }} onClick={(e) => e.target.style.width = '90%'} onBlur={(e) => e.target.style.width = '50%'} placeholder="סיסמת ניהול" type="password" />
                <div id='submit30' onClick={() => { enterBussiness() }}><ButtonRed text={'אישור'}></ButtonRed></div>
                <br></br>
                <div className='errorPass' onClick={() => { setAlert(true); }}>שיחזור סיסמה</div>
                {errorMessage && <p><img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}
            </div>
        </div>

        {alert && <ErrorPass set={setAlert} bussiness={true}></ErrorPass>}

    </div>
}

export default BusinessArea