import React, { useState } from 'react';
import { HDate } from 'hebcal';
import './Calender.css'
import RefReady from '../../ref';
import { AddCatchDate, SendEmailToChoozeDate } from '../../../axios/api';
import { CloseOutlined } from '@ant-design/icons';
import ButtonRed from '../../button/button';
import { useDispatch, useSelector } from 'react-redux';
import Connection from '../../../1. menu/11. connection/Connection';
import Login from '../../../1. menu/10. login/Login';
import { COMPLETEallShoppingCart } from '../../../2. redux/actions/shoppingCartAction';


var shanaMehuberet = false

const HebrewDaysNames = ['0', "א'", "ב'", "ג'", "ד'", "ה'", "ו'", "ז'", "ח'", "ט'", "י'", 'י"א', 'י"ב', 'י"ג', 'י"ד', 'ט"ו', 'ט"ז', 'י"ז', 'י"ח', 'י"ט', "כ'", 'כ"א', 'כ"ב', 'כ"ג', 'כ"ד', 'כ"ה', 'כ"ו', 'כ"ז', 'כ"ח', 'כ"ט', "ל'"];
const HebrewMonthNames = ['תשרי', 'חשון', 'כסלו', 'טבת', 'שבט', 'אדר', 'ניסן', 'אייר', 'סיון', 'תמוז', 'אב', 'אלול'];
const HebrewMonthNamesShanaMehuberet = ['תשרי', 'חשון', 'כסלו', 'טבת', 'שבט', "אדר א'", "אדר ב'", 'ניסן', 'אייר', 'סיון', 'תמוז', 'אב', 'אלול'];


const mounth = [[], [], [], [], [], [], [], [], [], [], [], [], []]
var numMounth

function convertToHebrewDate(date) {
    const secularDate1 = new Date(date);
    const hebrewDate = new HDate(secularDate1);
    const hebrewDateString = `${hebrewDate.getDate()} ${hebrewDate.getMonthName('h')} ${hebrewDate.getFullYear()}`;
    return hebrewDateString;
}


const formatDate = (date) => {
    const secularDate1 = date.split('.')
    return `${secularDate1[2]}-${secularDate1[1]}-${secularDate1[0]}`;
};

var hadarA = false

const firstMonth = () => {
    var today = new Date();
    var hebrewDate, hebrewDay, nextDay, secularDate, numDayOfMounth, arr = [], numDayOfWeek
    for (let index = 0; index < 12; index++) {
        arr = []
        numDayOfWeek = today.getDay();
        secularDate = formatDate(today.toLocaleDateString())
        hebrewDate = convertToHebrewDate(secularDate)
        hebrewDay = hebrewDate.split(' ')
        if (index === 0 || hebrewDay[1] === 'תשרי')
            numDayOfMounth = HebrewMonthNames.indexOf(hebrewDay[1])
        else
            numDayOfMounth = numDayOfMounth + 1
        numMounth = numDayOfMounth

        for (let index = 0; index < numDayOfWeek && index < 5 && numDayOfWeek < 5; index++) {
            arr.push({ H: '-', E: '-' })
        }

        while (convertToHebrewDate(formatDate(today.toLocaleDateString())).split(' ')[1] == hebrewDay[1]) {
            // if (today.getDay() !== 5 && today.getDay() !== 6) {
            secularDate = formatDate(today.toLocaleDateString())
            hebrewDate = convertToHebrewDate(secularDate)
            hebrewDay = hebrewDate.split(' ')
            hebrewDate = `${HebrewDaysNames[hebrewDay[0]] + ' ' + hebrewDay[1]}`
            if (hebrewDay[2] == "א'") {
                arr.push({ H: hebrewDate + " א'", E: today.toISOString() })
                shanaMehuberet = true
                hadarA = true
            }
            else if (hebrewDay[2] == "ב'" && hadarA) {
                hadarA = false
                if (today.getDay() !== 0) {
                    for (let i = today.getDay(); i < 5; i++) {
                        arr.push({ H: '-', E: '-' })
                    }
                }
                mounth[numDayOfMounth] = arr
                arr = []
                numDayOfWeek = today.getDay();
                for (let index = 0; index < numDayOfWeek && index < 5 && numDayOfWeek < 5; index++) {
                    arr.push({ H: '-', E: '-' })
                }
                numDayOfMounth = numDayOfMounth + 1
                numMounth = numDayOfMounth
                arr.push({ H: hebrewDate + " ב'", E: today.toISOString() })
            }
            else if (hebrewDay[2] == "ב'")
                arr.push({ H: hebrewDate + " ב'", E: today.toISOString() })
            else
                arr.push({ H: hebrewDate, E: today.toISOString() })
            // }
            nextDay = new Date(today);
            nextDay.setDate(today.getDate() + 1);
            today = new Date(nextDay)
        }

        if (today.getDay() !== 0) {
            for (let i = today.getDay(); i < 5; i++) {
                arr.push({ H: '-', E: '-' })
            }
        }
        mounth[numDayOfMounth] = arr
    }
    return today
};

const Calender = (prop) => {

    firstMonth()
    const [num, setNum] = useState(numMounth + 1)
    const [fullDatesH, setFullDatesH] = useState(prop.fullDatesH)
    const [openMod, setOpenMod] = useState(false)
    const sBussiness = useSelector((state) => state.thisBussinessReducer)
    const sUser = useSelector(a => a.userReducer)
    const d = useDispatch()

    const [openGlobal, setOpenGlobal] = useState(false)
    const [login, setLogin] = useState(false);
    const [connection, setConnection] = useState(false);
    const [checkedDate, setCheckedDate] = useState(false);

    const addDate = (dateE, dateH) => {
        prop.addDate(dateE)
        setFullDatesH([...fullDatesH, dateH]);
    }

    const remove = (dateE, dateH) => {
        prop.removeDate(dateE)
        setFullDatesH(prev => {
            var dates = prev;
            return dates.filter(a => a !== dateH);
        });
    }

    return <div id="dates1" style={{ height: 'auto', display: 'block', marginBottom: '0' }}>
        {!shanaMehuberet && <div id="mySidenav2" className="sidenav2">
            <div>{HebrewMonthNames.map((monthName, index) => (
                index > numMounth &&
                <div className='divSide1' key={index} onClick={() => setNum(index)}>{monthName}</div>
            ))}</div>
            <div>{HebrewMonthNames.map((monthName, index) => (
                index <= numMounth &&
                <div className='divSide1' key={index} onClick={() => setNum(index)}>{monthName}</div>
            ))}</div>
        </div>}

        {shanaMehuberet && <div id="mySidenav2" className="sidenav2">
            <div>{HebrewMonthNamesShanaMehuberet.map((monthName, index) => (
                index > numMounth &&
                <div className='divSide1' key={index} onClick={() => setNum(index)}>{monthName}</div>
            ))}</div>
            <div>{HebrewMonthNamesShanaMehuberet.map((monthName, index) => (
                index <= numMounth &&
                <div className='divSide1' key={index} onClick={() => setNum(index)}>{monthName}</div>
            ))}</div>
        </div>}

        <div className='column'>
            <div className='tit'>יום ראשון</div>
            <div className='tit'>יום שני</div>
            <div className='tit'>יום שלישי</div>
            <div className='tit'>יום רביעי</div>
            <div className='tit'>יום חמישי</div>
            <div className="tit">יום שישי</div>
            <div className="tit">שבת</div>

            {/* ---------------------------------------------------------------------------------
                               anotherMonth
--------------------------------------------------------------------------------- */}
            {fullDatesH !== undefined && <>{mounth[num].map((x, i) => (
                <div key={i} className='date2' style={{ position: 'relative' }}>

                    <RefReady tag={<div style={{ position: 'absolute' }} className='date'></div>
                    } event={'animate__flipInX'}></RefReady>

                    {fullDatesH.filter(a => a == x.H).length == 0 && <div style={{ position: 'absolute' }} className='date2'>{x.H}<RefReady tag={<div className='kavv'></div>} event={'kavv2'}></RefReady>
                        {x.H !== '-' && <>
                            {checkedDate != x.H && <div className='btn2 animate__animated animate__fadeInDown' onClick={() => { prop.bussinessId === 0 ? addDate(x.E.split('T')[0], x.H) : sUser.id ? setOpenMod({ H: x.H, E: x.E.split('T')[0] }) : setOpenGlobal({ text: 'עדיין לא התחברת למערכת' }) }}>קבע תאריך</div>}
                            {checkedDate === x.H && <div className='btn'>נשלח לבדיקה</div>}
                            <p style={{ position: 'absolute', bottom: '5px', margin: '0', width: '100%' }}>{new Date(x.E.split('T')[0]).toLocaleDateString('en-GB').replace('/', '-').replace('/', '-')}</p></>}
                    </div>}
                    {fullDatesH.filter(a => a == x.H).length > 0 && prop.bussinessId !== 0 && <div className='date2' style={{ position: 'absolute', cursor: 'no-drop', color: 'darkgrey' }}>{x.H}<RefReady tag={<div className='kavv'></div>} event={'kavv2'}></RefReady>
                        <div className='btn'>תאריך מלא</div>
                        <p style={{ position: 'absolute', bottom: '5px', margin: '0', width: '100%' }}>{new Date(x.E.split('T')[0]).toLocaleDateString('en-GB').replace('/', '-').replace('/', '-')}</p>
                    </div>}
                    {fullDatesH.filter(a => a == x.H).length > 0 && prop.bussinessId === 0 && <div className="flip-box date2">
                        <div className="flip-box-inner">
                            <div className="flip-box-front">{x.H}
                                <div className='btn'>תאריך מלא</div>
                                <p style={{ position: 'absolute', bottom: '-20%', width: '100%' }}>{new Date(x.E.split('T')[0]).toLocaleDateString('en-GB').replace('/', '-').replace('/', '-')}</p>
                            </div>
                            <div className="flip-box-back" onClick={() => remove(x.E.split('T')[0], x.H)}>{x.H}
                                <div className='btn'>ביטול תאריך</div>
                                <p style={{ position: 'absolute', bottom: '-20%', width: '100%' }}>{new Date(x.E.split('T')[0]).toLocaleDateString('en-GB').replace('/', '-').replace('/', '-')}</p>
                            </div>
                        </div>
                    </div>}
                </div>
            ))}</>}


            {openMod && <div id='modal2'>
                <div className='animate__animated animate__zoomIn'>
                    <CloseOutlined className='close2' onClick={() => { setOpenMod(false) }} style={{ cursor: 'pointer', position: 'absolute', left: '22%', marginTop: '1.5%' }} />
                    <div className='glow' style={{ backgroundColor: 'white', width: '60%', height: '40vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '20%', marginTop: '30vh' }}>
                        <p style={{ marginTop: '8%' }}>קיבלנו את הפניה לקביעת התאריך ונחזור אליך בהקדם.</p>
                        <p>עד לאישור התאריך סופית - אין לסמוך על קביעת התאריך.</p>
                        <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => {
                            AddCatchDate(sUser.id, { date1: openMod.E, categoryCode: prop.categoryCode, bussinessId: prop.bussinessId }).then((data) => {
                                setCheckedDate(openMod.H)
                                setTimeout(() => {
                                    setCheckedDate(false)
                                }, 4000);
                                d(COMPLETEallShoppingCart(data));
                                //שליחת מייל לספק שלקוח רוצה את התאריך
                                var mes = `<div style="direction: rtl; font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 120%;">שלום לעסק ${sBussiness.name}</p></div><br/><br/> אנחנו משפחת ${sUser.lastName} רצינו לסגור איתכם לתאריך:  <br/><b style="font-size: 120%;">${openMod.H} - ${openMod.E}</b> <br/> <br/> נשמח שתיצרו איתנו קשר בהקדם. <br/> <br/> <b>שם הלקוח: </b> ${sUser.lastName}. <br/> <b>מספר טלפון: </b> ${sUser.phone}<br/> <b>כתובת מייל: </b> ${sUser.mail}<br/> <br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
                                SendEmailToChoozeDate({ name: "", emailTo: sBussiness.mail, emailFrom: sUser.mail, subject: '', message: mes }).then(() => { debugger }).catch((error) => { console.error(error); });
                                setOpenMod(false);
                            }).catch((error) => { console.error(error); });
                        }}><ButtonRed text='אישור'></ButtonRed></div>
                    </div>
                </div>
            </div>}

            {login && <Login setLogin={setLogin}></Login>}
            {connection && <Connection set={setConnection}></Connection>}

            {openGlobal && <div id='modal2'>
                <div className='animate__animated animate__zoomIn'>
                    <CloseOutlined className='close2' onClick={() => setOpenGlobal(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                    <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '40vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '30vh' }}>
                        <div className='titl' style={{ marginTop: '4%', fontSize: '120%', marginBottom: '5%' }}>{openGlobal.text}</div>
                        <div style={{ marginTop: '-2%' }}>{openGlobal.text2}</div>
                        {openGlobal.input && <div>
                            <div>{openGlobal.input}</div>
                            {/* <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => isUpdatePaid()}><ButtonRed text='אישור'></ButtonRed></div> */}
                        </div>}
                        {!openGlobal.input && <div>
                            <div style={{ width: '10%', display: 'inline-block', marginTop: '4%' }} onClick={() => { setLogin(true); setOpenGlobal(false) }}><ButtonRed text='התחבר'></ButtonRed></div>
                            <div style={{ width: '10%', display: 'inline-block' }} onClick={() => { setConnection(true); setOpenGlobal(false) }}><ButtonRed text='הירשם'></ButtonRed></div>
                        </div>}
                    </div>
                </div>
            </div>}

        </div>
    </div>;
};

export default Calender