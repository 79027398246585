import { CloseOutlined } from '@ant-design/icons';
import './ErrorPass.css'
import { useEffect, useRef, useState } from 'react';
import ButtonRed from '../../global/button/button';
import picError from '../../../pic/animations/113.gif'
import { GetBussinessEnter, GetOneBussinessWhisData, SendEmailBusForgetPass, SendEmailToBussiness } from '../../axios/api';
import RefReady from '../../global/ref';
import { CompleteThisBussiness } from '../../2. redux/actions/thisBussinessActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const ErrorPass = (prop) => {
    const [userCurrent, setUserCurrent] = useState(prop.userCurrent);
    const [name, setName] = useState();
    const [mail125, setMail125] = useState();
    const [bussiness, setBussiness] = useState(prop.bussiness);

    const [pass, setPass] = useState();
    const [temp, setTemp] = useState(false);
    const d = useDispatch()
    const n = useNavigate()

    const [errorMessage, setErrorMessage] = useState('');
    const [first, setFirst] = useState(true);
    const [second, setSecond] = useState(false);

    const handlePassChange = (e) => {
        const inputValue = e.target.value;
        if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
            setPass(inputValue);
        }
    };

    const handleNameChange = (e) => {
        const inputValue = e.target.value;
        // Validate: Only letters allowed
        if (/^[A-Za-zא-ת ]+$/.test(inputValue) || inputValue === '') {
            setName(inputValue);
        }
    };

    const handleSubmit = (e) => {
        setErrorMessage('');
        if (first && !prop.bussiness) {
            userCurrent.sort((a, b) => a.id > b.id ? 1 : -1)
            if (name === '') {
                setErrorMessage('לא הוכנסו כל הפרטים');
            } else {
                setErrorMessage('');
                userCurrent.forEach(element => {
                    if (element.lastName === name) {
                        setTemp(true)
                        setFirst(false)
                        setSecond(true)
                        var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום למשפחת ${name} </p></div><br/>  <br/>   <b>סיסמתכם השמורה במערכת היא: </b> ${element.pass}.<br/> בשמחות תמיד <br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
                        SendEmailToBussiness({ name: "מיזם קול שמחה", emailTo: element.mail, emailFrom: 'info@kolsimcha.co.il', subject: 'מיזם קול שמחה - שחזור סיסמה', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                    }
                });
                if (temp)
                    setErrorMessage('הפרטים אינם נכונים, נסו להתחבר מחדש');
            }
        }

        else if (first && prop.bussiness) {
            if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(mail125) || mail125 === "")
                setErrorMessage('כתובת מייל לא תקינה');
            else {
                setErrorMessage('');
                if (bussiness?.mail) {
                    if (bussiness.mail.toLowerCase() === mail125.toLowerCase()) {
                        setFirst(false)
                        setSecond(true)
                        var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום לעסק ${bussiness.name} </p></div><br/>  <br/>   <b>סיסמתכם השמורה במערכת היא: </b> ${bussiness.code}.<br/> <br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
                        SendEmailToBussiness({ name: "מיזם קול שמחה", emailTo: bussiness.mail, emailFrom: 'info@kolsimcha.co.il', subject: 'מיזם קול שמחה - שחזור סיסמה', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                    }
                    else
                        setErrorMessage('הפרטים אינם נכונים, נסו להתחבר מחדש');
                } else {
                    SendEmailBusForgetPass(mail125).then((data) => {
                        if (data === false)
                            setErrorMessage('כתובת המייל אינה רשומה במערכת - ניתן לפנות למזכירות קול שמחה: K3137663@gmail.com');
                        else {
                            setErrorMessage('')
                            setFirst(false)
                            setSecond(true)
                        }
                    }).catch((error) => { console.error(error); });
                }
            }
        }
        else {
            if (pass === '') {
                setErrorMessage('לא הוכנסה סיסמה');
            }
            else if (!prop.bussiness) {
                userCurrent.forEach(element => {
                    if (element.pass === pass) {
                        prop.good(element)
                        return;
                    }
                })
                setErrorMessage('סיסמה שגויה');
            }
            else if (bussiness?.code) {
                if (Number(pass) !== bussiness.code)
                    setErrorMessage('סיסמה שגויה');
                else {
                    prop.good(true)
                }
            }
            else {
                GetBussinessEnter(mail125, pass).then((data) => {
                    if (data === null) { setErrorMessage('קוד ניהול העסק שגוי!'); }
                    else
                        GetOneBussinessWhisData(data[0], data[1]).then((dataBus) => {
                            d(CompleteThisBussiness(dataBus))
                            n(`/Kochavit/${dataBus.name}/${dataBus.categoryCode}`);
                        }).catch((error) => { console.error(error); });
                }).catch((error) => { console.error(error); });
            }
        }
    };

    return <div id='modal'>
        <div id='formCon111' className='animate__animated animate__zoomIn'>
            <CloseOutlined className='close' onClick={() => prop.set(false)} style={{ cursor: 'pointer', position: 'absolute', left: '1.5%', marginTop: '1.5%' }} />
            <div style={{ fontSize: '150%', paddingTop: '3%' }}>שיחזור סיסמה</div>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#FF7D7D', marginLeft: 'auto', marginRight: 'auto' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            <div>
                {first && !prop.bussiness && <><div style={{ fontSize: '120%', paddingTop: '4%' }}>מהו שם המשפחה שלכם?</div>
                    <input className='input111' autoComplete="on" type="text" value={name || ''} onChange={handleNameChange} /></>}

                {first && prop.bussiness && <><div style={{ fontSize: '120%', paddingTop: '4%' }}>מהי כתובת המייל של העסק הרשומה במערכת?</div>
                    <input className='input111' autoComplete="on" type="text" value={mail125 || ''} onChange={(e) => { setMail125(e.target.value) }} /></>}

                {second && <><div style={{ fontSize: '120%', paddingTop: '2%' }}>ברגעים אלו נשלחת אליכם הודעה למייל בה כתובה סיסמתכם</div>
                    <div style={{ fontSize: '110%', paddingTop: '2%' }}>הכנס סיסמה</div>
                    <input className='input111' autoComplete="on" type="text" value={pass || ''} onChange={handlePassChange} /></>}

            </div>
            {errorMessage && <p style={{ position: 'absolute', bottom: '10%', width: '20%', right: '40%' }}><img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}
            <div onClick={() => handleSubmit()} className='buttonRed' style={{ width: '20%', display: 'inline-block', position: 'absolute', bottom: '-5%', backgroundColor: 'white', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', left: '10%', border: '#FF7D7D 5px solid' }}><ButtonRed text={'אישור'}></ButtonRed></div>

        </div>
    </div>
}

export default ErrorPass

