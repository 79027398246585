import './button.css'

const ButtonRed = (props) => {

    const move = (event) => {
        const elem = event.currentTarget.children[0];
        var width = 0;
        const frame = () => {
            if (width >= 100) {
                clearInterval(id);
            } else {
                width = width + 5;
                elem.style.width = width + '%';
            }
        }
        var id = setInterval(frame, 10);
    }

    const moveBack = (event) => {
        var elem = event.currentTarget.children[0];
        var width = 100;
        const frame = () => {
            if (width <= 0) {
                clearInterval(id);
            } else {
                width = width - 5;
                elem.style.width = width + '%';
            }
        }
        var id = setInterval(frame, 10);
    }

    return (
        <div
            id="button"
            onMouseOver={(event) => move(event)}
            onMouseLeave={(event) => moveBack(event)}
            style={{
                background: `${props.is ? 'linear-gradient(to left, #c00000, #FF7D7D)' : 'white'}`,
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <div style={{ height: '45px' }} id="buttonColor"></div>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    // zIndex: '900',
                    // paddingRight: '10px',
                    // paddingLeft: '10px',
                    width: '100%', // ����� ����� ���� ������ �- '100%' ��� ������ ����� ����
                    maxWidth: '100%', // ����� ����� ����
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                    overflowWrap: 'break-word',
                    textAlign: 'center' // ���������, �� ����� ������ ����� ����� �����
                }}
            >
                {props.text}
            </div>
        </div>
        // <div id="button" onMouseOver={(event)=>move(event)} onMouseLeave={(event)=>moveBack(event)} style={{background:`${props.is ? 'linear-gradient(to left, #c00000, #FF7D7D)' : 'white'}`}}><div style={{ height:'45px'}} id="buttonColor"></div><div style={{ position:'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex:'900', paddingRight:'10px', paddingLeft:'10px', width:'max-content', wordWrap: 'break-word',
        //   whiteSpace: 'normal',
        //   overflowWrap: 'break-word'}}>{props.text}</div></div>
    )
}

export default ButtonRed