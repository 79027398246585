import './AddToDataBase.css'
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import ButtonRed from "../../../global/button/button"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import picError from '../../../../pic/animations/113.gif'
import { EditImages } from "../../../axios/api"
import { CompleteBussinessData, CompleteThisBussiness } from "../../../2. redux/actions/thisBussinessActions"

const EditBussinessPictures = (props) => {

    const [logo, setLogo] = useState()
    const [mainImage, setMainImage] = useState()
    const [pictures, setPictures] = useState([])
    const [openPic, setOpenPic] = useState(false)
    const [delPic, setDelPic] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [think, setThink] = useState(false)
    const [noPictures, setNoPictures] = useState(false)
    const [ccToAllTables, setCcToAllTables] = useState()
    const isAddBus = props.isAddBus;

    const cc = JSON.parse(sessionStorage.getItem('mainCC'))
    const s = useSelector((state) => state.thisBussinessReducer);
    const n = useNavigate()
    const d = useDispatch()
    const baseImageUrl = "https://kolsimcha.co.il/images";

    useEffect(() => {
        window.scrollTo(0, 0);
        setLogo(s.logo);
        // var pics = JSON.parse(JSON.stringify(s.bussinessData.images ?? []));
        var pics = s.bussinessData?.images ?? [];
        if (s.mainImage && !pics.find(a => a.picture === s.mainImage))
            pics.push({ picture: s.mainImage, categoryCode: !['1', '2', '179', '182', '3', '82', '4'].includes(cc) ? 0 : s.categoryCode, bussinessId: s.id })
        setPictures(pics)
        setMainImage(s.mainImage)
        setCcToAllTables(!['1', '2', '179', '182', '3', '82', '4'].includes(cc) ? 0 : s.categoryCode)
    }, [s])

    const handleSubmit = (e) => {
        if (e != 3) e.preventDefault();
        setErrorMessage('');
        if (!mainImage && e != 3) { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה - חובה לבחור תמונה ראשית'); }
        else {
            setThink(true)
            var images = pictures.filter(x => x.picture != mainImage);
            if (!isAddBus) {
                //נשלח לשרת: מערך של תמונות לשים בשרת, מערך מחרוזות URL למחוק מהשרת, ואת שאר הפרמטרים.
                var imagesToAdd = [], logo2 = logo, mainImage2 = mainImage;
                for (let i = 0; i < images.length; i++) {
                    if (images[i].picture instanceof File) { imagesToAdd.push(new File([images[i].picture], "BussinessPics\\" + images[i].picture.name, { type: images[i].picture.type })); images[i] = "\\BussinessPics\\" + images[i].picture.name; }
                    else { images[i] = images[i].picture; }
                }
                if (logo instanceof File) { imagesToAdd.push(new File([logo], "logo\\" + logo.name, { type: logo.type })); logo2 = "\\logo\\" + logo.name; }
                if (mainImage instanceof File) { imagesToAdd.push(new File([mainImage], "BussinessPics\\" + mainImage.name, { type: mainImage.type })); mainImage2 = "\\BussinessPics\\" + mainImage.name; }
                EditImages({ imagesToAdd: imagesToAdd, images: images, logo: logo2, mainImage: mainImage2 }, s.id, s.categoryCode).then((data) => {
                    d(CompleteThisBussiness({ ...s, mainImage: data[0], logo: data[1] }))
                    d(CompleteBussinessData({ ...s.bussinessData, images: data.slice(2) }))
                    n(-1);
                }).catch((error) => { console.error(error); setThink(false); });
            }
            else {
                d(CompleteThisBussiness({ ...s, mainImage: mainImage, logo: logo }));
                d(CompleteBussinessData({ ...s.bussinessData, images: images }))
                props.enter();
            }
        }
    }

    const resizeImageTo300Pixels = (numSize, inputImage, funcKod) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.src = URL.createObjectURL(inputImage);
        image.onload = function () {
            canvas.width = numSize;
            canvas.height = (numSize * image.height) / image.width;
            ctx.drawImage(image, 0, 0, numSize, (numSize * image.height) / image.width);
            canvas.toBlob((blob) => {
                const resizedFile = new File([blob], inputImage.name, { type: 'image/png' });
                funcKod(resizedFile)
            }, 'image/png', 0.9);
        };
    }

    const changePic = async (e) => {
        if (e.target.files.length) {
            const funcK = (file) => { setLogo(file) }
            resizeImageTo300Pixels(800, e.target.files[0], funcK)
        }
    }

    const changePics = (e) => {
        if (e.target.files.length) {
            var pics = [...pictures];
            const promises = []; // נאסוף את כל ההבטחות (promises)

            for (let i = 0; i < e.target.files.length; i++) {
                if (pics.length + i < 5) {
                    const promise = new Promise((resolve) => {
                        const funcK = (file) => {
                            pics.push({ picture: file, categoryCode: ccToAllTables, bussinessId: s.id });
                            resolve(); // לאחר שהוספנו את התמונה, נסיים את ההבטחה
                        };
                        resizeImageTo300Pixels(800, e.target.files[i], funcK); // קריאה לפונקציה שמבצעת resizing
                    });
                    promises.push(promise); // הוסף את ההבטחה למערך
                }
            }
            // לאחר שכולן סיימו, עדכן את ה-state
            Promise.all(promises).then(() => { setPictures(pics); });

            // איפוס הקלט כדי לאפשר העלאה מחדש
            e.target.value = '';
        }
    }

    const chooseDelPic = () => {
        var pics = [...pictures];
        delPic.forEach(picToDel => {
            pics = pics.filter(x => picToDel != x.picture)
        });
        setPictures(pics); setOpenPic(false); setDelPic([]);
    }

    return <div>
        <form onSubmit={handleSubmit}>
            {isAddBus && <div className='buton' onClick={() => { d(CompleteThisBussiness({ ...s, mainImage: mainImage, logo: logo })); d(CompleteBussinessData({ ...s.bussinessData, images: pictures.filter(x => x.picture != mainImage) })); props.prev() }} style={{ position: 'absolute', top: '-5vh', right: '-40%', width: '8vh', zIndex: '5' }}><ButtonRed text='<--'></ButtonRed></div>}

            <input type="file" id="fileElem" accept="image/*" onChange={changePic} style={{ display: 'none' }} />
            <input type="button" className="submit1 input in1" id="fileSelect" onClick={() => { document.getElementById("fileSelect").addEventListener("click", document.getElementById("fileElem").click()); }} value={!logo ? "הכנס לוגו של העסק" : "החלף לוגו של העסק"} />
            <div id="fileList" style={{ marginBottom: '7%' }}> {!logo && <p>עדיין לא נבחרה תמונה!</p>} {logo && <img loading='lazy' src={logo instanceof Blob || logo instanceof File ? URL.createObjectURL(logo) : encodeURI(baseImageUrl + logo)} width={280}></img>} </div>

            <div style={{ backgroundColor: '#c00000', width: '100%', height: '1px', marginBottom: '4%' }}></div>

            <input type="file" id="fileElem1" accept="image/*" multiple onChange={changePics} style={{ display: 'none' }} />
            <input style={{ marginRight: '14%', marginLeft: '5%', maxWidth: '60%', marginBottom: '1%' }} type="button" className="submit1 input in1" id="fileSelect1" onClick={() => { document.getElementById("fileSelect1").addEventListener("click", document.getElementById("fileElem1").click()); }} value={!pictures.length > 0 ? "הכנס תמונות לשווק העסק" : "הוסף תמונות לשווק העסק"} />
            <div title="מחק תמונות" style={{ userSelect: 'none', marginBottom: '1%' }} className="submit1 input in2" id="fileSelect2" onClick={() => { setOpenPic(2); setErrorMessage('') }}><DeleteOutlined /></div>
            <div style={{ lineHeight: '20px' }}><p style={{ fontWeight: '400' }}>שימו לב, המערכת מיועדת לציבור החרדי -</p>
                <p>אנא השתדלו להכניס תמונות המתאימות לאופי האתר והמשתמשים.</p>
                <p>אין להכניס תמונות נשים כלל.</p>
                <p>המערכת רשאית להסיר תמונות שלא מתאימות לצביון הלקוחות - לפי שיקול דעתה.</p></div>

            <div id="fileList1" style={{ width: '100%', height: '20%', margin: '10% 0 10% 0' }}> {pictures.length === 0 && <p>עדיין לא נבחרו תמונות!</p>} {pictures.map((x, i) => (<img loading='lazy' key={i} src={x.picture instanceof Blob || x.picture instanceof File ? URL.createObjectURL(x.picture) : encodeURI(baseImageUrl + x.picture)} width={280}></img>))} </div>
            <p style={{ marginTop: '-60px', float: 'right', marginRight: '10%', fontSize: '90%' }}>*עד 5 תמונות - חשוב שהתמונות יהיו לרוחב ולא לאורך.</p>

            <div style={{ backgroundColor: '#c00000', width: '100%', height: '1px', marginBottom: '4%' }}></div>

            <div style={{ userSelect: 'none', color: '#c00000' }} className="submit1 input in1" id="fileSelect2" onClick={() => { setOpenPic(pictures.length === 0 ? 1 : 3); setErrorMessage('') }}>*בחר תמונה ראשית</div>
            <div id="fileList30" style={{ width: '90%', height: '20%', margin: '5%', marginTop: '2%' }}> {!mainImage && <p>עדיין לא נבחרה תמונה!</p>} {mainImage && <img loading='lazy' src={mainImage instanceof Blob || mainImage instanceof File ? URL.createObjectURL(mainImage) : encodeURI(baseImageUrl + mainImage)} width={280}></img>} </div>

            <div style={{ marginBottom: '15%' }}></div>
            <span style={{ cursor: `${think ? 'wait' : 'pointer'}` }}><button type="submit" className='buton' style={{ pointerEvents: `${think ? 'none' : 'auto'}`, width: '20%', marginTop: '-10%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto', minWidth: '20vh', zIndex: '0' }}><ButtonRed text={isAddBus ? 'המשך    -->' : 'סיים עריכה'}></ButtonRed></button></span>
            {errorMessage && <p><img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}
        </form>

        {openPic && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => { setOpenPic(false); setErrorMessage("") }} style={{ cursor: 'pointer', position: 'absolute', left: '5%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '95%', height: '95vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '2.5%', marginTop: '2.5vh' }}>
                    <div style={{ width: '100%', height: '10vh', backgroundColor: '#820909', lineHeight: '10vh', color: 'white', fontWeight: '400', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>{openPic === 1 || openPic === 3 ? "בחירת תמונה ראשית" : "מחיקת תמונות"}</div>
                    {openPic === 1 && <div style={{ marginTop: '6vh' }}><p>קודם בחר עד 5 תמונות לשיווק העסק, <br></br>ואח"כ מתוכם - בחר תמונה אחת ראשית:</p>
                        <input type="file" id="fileElem1" accept="image/*" multiple onChange={changePics} style={{ display: 'none' }} />
                        <input style={{ marginRight: '14%', marginLeft: '5%', maxWidth: '60%', marginBottom: '0' }} type="button" className="submit1 input in1" id="fileSelect1" onClick={() => { document.getElementById("fileSelect1").addEventListener("click", document.getElementById("fileElem1").click()); }} value={!pictures.length > 0 ? "הכנס תמונות לשווק העסק" : "הוסף תמונות לשווק העסק"} />
                        <div title="מחק תמונות" style={{ userSelect: 'none', marginBottom: '0' }} className="submit1 input in2" id="fileSelect2" onClick={() => { setOpenPic(2); setErrorMessage('') }}><DeleteOutlined /></div><br></br>
                        <div style={{ lineHeight: '15px' }}><p style={{ fontWeight: '400' }}>שימו לב, המערכת מיועדת לציבור החרדי -</p>
                            <p>אנא השתדלו להכניס תמונות המתאימות לאופי האתר והמשתמשים.</p>
                            <p>אין להכניס תמונות נשים כלל.</p>
                            <p>המערכת רשאית להסיר תמונות שלא מתאימות לצביון הלקוחות - לפי שיקול דעתה.</p>
                            <p>*חשוב שהתמונות יהיו לרוחב ולא לאורך.</p></div>
                        <div id="fileList1" style={{ width: '100%', maxHeight: '150px', margin: '1% 0 1% 0', overflowY: 'auto' }}>{pictures.map((x, i) => (<img loading='lazy' key={i} src={x.picture instanceof Blob || x.picture instanceof File ? URL.createObjectURL(x.picture) : encodeURI(baseImageUrl + x.picture)} height={150}></img>))} </div>

                        {pictures.length === 0 && <input style={{ maxWidth: '60%', marginBottom: '1%' }} type="button" className="submit1 input in1" onClick={() => { setNoPictures(true) }} value="אין לי תמונות" />}
                        {pictures.length > 0 && <div style={{ userSelect: 'none', color: '#c00000' }} className="submit1 input in1" id="fileSelect2" onClick={() => { setOpenPic(3); setErrorMessage('') }}>*בחר תמונה ראשית</div>}
                    </div>}
                    {openPic === 3 && <div style={{ marginTop: '10vh' }}>לחצו על התמונה הנבחרת:
                        <div id='fileList12' style={{ marginTop: '5%', width: '80%', marginRight: 'auto', marginLeft: 'auto', maxHeight: '45vh', overflow: 'auto' }}>{pictures.map((x, i) => (<img loading='lazy' key={i} src={x.picture instanceof Blob || x.picture instanceof File ? URL.createObjectURL(x.picture) : encodeURI(baseImageUrl + x.picture)} className="remove" onClick={() => { if (openPic === 2) { if (!delPic.includes(x.picture)) { if (x.picture === mainImage) { setErrorMessage("לא ניתן למחוק - כיוון שהתמונה הוגדרה כתמונה ראשית") } else { setErrorMessage(""); setDelPic([...delPic, x.picture]) } } } else { setMainImage(x.picture); setOpenPic(false) } }} width={280}></img>))}</div></div>}
                    {openPic === 2 && <div>
                        <br></br><p>לחץ על התמונות למחיקה:</p>
                        <div id='fileList12' style={{ marginTop: '3%', width: '80%', marginRight: 'auto', marginLeft: 'auto', maxHeight: '45vh', overflow: 'auto' }}>{pictures.map((x, i) => (<img loading='lazy' key={i} src={x.picture instanceof Blob || x.picture instanceof File ? URL.createObjectURL(x.picture) : encodeURI(baseImageUrl + x.picture)} className="remove" onClick={() => { if (openPic === 2) { if (!delPic.includes(x.picture)) { if (x.picture === mainImage) { setErrorMessage("לא ניתן למחוק - כיוון שהתמונה הוגדרה כתמונה ראשית") } else { setErrorMessage(""); setDelPic([...delPic, x.picture]) } } } else { setMainImage(x.picture); setOpenPic(false) } }} width={280}></img>))}</div>
                        <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => { chooseDelPic(); setErrorMessage("") }}><ButtonRed text='אישור'></ButtonRed></div>
                        <div id='fileList13' style={{ marginTop: '2%', maxWidth: '100%', maxHeight: '25vh', overflowX: 'auto', textOrientation: 'landscape' }}>{delPic.map((x, i) => (<img loading='lazy' key={i} src={x instanceof Blob || x instanceof File ? URL.createObjectURL(x) : encodeURI(baseImageUrl + x)} width={280}></img>))}</div>
                    </div>}
                    {errorMessage && <p><img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}
                </div>
            </div>
        </div>}

        {noPictures && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <div className='glow' style={{ width: '100vh', maxWidth: '90%', height: 'auto', position: 'relative', marginRight: 'auto', marginLeft: 'auto' }}>
                    <p className='titl' style={{ backgroundColor: '#820909', color: 'white', marginTop: '0', paddingTop: '6%', paddingBottom: '6%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '5vh' }}>עסק עם תמונות מוכר יותר</p>
                    <p className='titl' style={{ marginTop: '5%', width: '90%', marginRight: '5%', fontSize: '97%' }}>ממליצים בהרשמה להכניס לפחות תמונה אחת, <br />לוגו / תמונה ממאגר חיצוני,<br />ובהמשך תוכלו להוסיף / להחליף תמונות.</p>
                    <div style={{ marginBottom: '50px' }}>
                        <div id='submit30' style={{ width: '30vh', maxWidth: '100%', display: 'inline-block', marginTop: '0' }} onClick={() => { setNoPictures(false) }}><ButtonRed text={'חזור לבחירת תמונה'}></ButtonRed></div>
                        <div id='submit30' style={{ width: '30vh', maxWidth: '100%', display: 'inline-block', marginTop: '0' }} onClick={() => {
                            handleSubmit(3);
                        }}><ButtonRed text={'המשך ללא תמונה'}></ButtonRed></div>
                    </div>
                </div>
            </div>
        </div>}

    </div>
}

export default EditBussinessPictures