import { CloseOutlined } from '@ant-design/icons';
import './TaskList.css';
import { useState } from 'react';
import ButtonRed from '../../global/button/button';
import { AddPersonalPay } from '../../axios/api';
import { useDispatch, useSelector } from 'react-redux';
import { COMPLETEpays } from '../../2. redux/actions/paysActions';
import picError from '../../../pic/animations/113.gif'

const AlertAddPersonalPay = (prop) => {

    const s = useSelector(a => a.userReducer)
    const [personalPay, setPersonalPay] = useState({ name: '', pricePaid: '', userId: '' })
    const [errorMessage, setErrorMessage] = useState('');
    const d = useDispatch()

    const dinamicWidth = (num) => {
        const input = document.getElementsByClassName("inp")[num];
        input.style.width = '28%'
        setTimeout(() => {
            input.style.width = "auto"; // Reset to auto width to measure the content
            input.style.width = (input.scrollWidth + 5) + "px"; // Add some padding or margin if needed
        }, 1000);
    }

    const submit = () => {
        if (personalPay.name == '' || personalPay.pricePaid == '')
            setErrorMessage('כל השדות הן חובה');
        else {
            var perPay = {name: personalPay.name, pricePaid: personalPay.pricePaid, userId: s.id}
            AddPersonalPay(perPay).then((data) => {
                prop.setAllPays(data)
                d(COMPLETEpays(data))
            }).catch((error) => { console.error(error); });
            prop.set(false)
        }
    }

    return <div id='modal2'>
        <div className='animate__animated animate__zoomIn'>
            <CloseOutlined className='close2' onClick={() => prop.set(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />

            <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '60vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '20vh' }}>
                <div className='titl' style={{ marginTop: '3%', fontSize: '120%', marginBottom: '5%' }}>הוספת הוצאה אישית לרשימת ההוצאות</div>

                <input className='inp' placeholder="שם ההוצאה" onClick={(e) => e.target.style.width = '80%'} onBlur={() => dinamicWidth(0)} type="text" value={personalPay.name} onChange={(e) => {if(/^[A-Za-zא-ת ]*$/.test(e.target.value) && e.target.value.length <= 60) {setPersonalPay({ ...personalPay, name: e.target.value })}}} /><br/>
                <input className='inp' placeholder='סכום ההוצאה' onClick={(e) => e.target.style.width = '80%'} onBlur={() => dinamicWidth(1)} type="text" value={personalPay.pricePaid} onChange={(e) => {if(/^[0-9]*$/.test(e.target.value) && e.target.value < 922337203685477) { setPersonalPay({ ...personalPay, pricePaid: e.target.value })}}} />
                <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => submit()}><ButtonRed text='הוסף'></ButtonRed></div>
                {errorMessage && <p><img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}

            </div>
        </div>
    </div>
};

export default AlertAddPersonalPay
