import './LotOfItems.css'
import { styled } from 'styled-components';
import RefReady from '../../ref';
import { useEffect, useState } from 'react';
import ButtonRed from '../../button/button';
import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { AddPersonalPaid, AddShoppingCart, FinishBuy, GetPayId, GetPaysInit, GetShopCartBussiness, GetpersonalPriceIdBy, SendEmailToBussiness, UpdatePersonalPaid } from '../../../axios/api';
import { useDispatch, useSelector } from 'react-redux';
import Connection from '../../../1. menu/11. connection/Connection';
import Login from '../../../1. menu/10. login/Login';
import { COMPLETEDelCart, COMPLETEallShoppingCart, COMPLETEshoppingCartLotOfItems } from '../../../2. redux/actions/shoppingCartAction';
import { COMPLETEAddorders } from '../../../2. redux/actions/ordersActions';
import pic2 from '../../../../pic/logo/444.jpg'


const ImageDiv = styled.div`
        background-image: url(${props => props.pic}); 
    `;

const LotOfItems = (prop) => {

    const [chooze, setChooze] = useState(false)
    const [allChooze, setAllChooze] = useState([])
    const [sumToPay, setSumToPay] = useState(0)
    const [mishlochimYes, setMishlochimYes] = useState(false)
    const s = useSelector(a => a.userReducer)
    const d = useDispatch()
    const sBussiness = useSelector(a => a.thisBussinessReducer)
    const [openMod, setOpenMod] = useState(false)
    const [opUser, setOpUser] = useState(false)
    const [login, setLogin] = useState(false);
    const [connection, setConnection] = useState(false);
    const shoppingCartDetails = useSelector(a => a.shoppingCartReducer.shoppingCartLotOfItems)
    const [opPay, setOpPay] = useState(false);
    const [numPay, setNumPay] = useState(-1);
    const [allPays, setAllPays] = useState([]);
    const [good, setGood] = useState(false);

    const [boolAddres, setBoolAddres] = useState("");
    const [boolPhone, setBoolPhone] = useState("");
    const [boolPhoneMore, setBoolPhoneMore] = useState("");
    const [boolMore, setBoolMore] = useState("");
    const [boolMail, setBoolMail] = useState("");
    const baseImageUrl = "https://kolsimcha.co.il/images";


    useEffect(() => {
        if (s.id) {
            setBoolPhone(s.phone)
            setBoolMail(s.mail)
        }
    }, [s])

    const cartSelector = useSelector(a => a.shoppingCartReducer.allShoppingCart)

    const fillListCart = (listCart) => {
        if (listCart !== undefined && listCart.length > 0) {
            var arr1 = [], sum1 = 0
            listCart.forEach(element => {
                for (let i = 0; i < element.amount; i++) {
                    sum1 = sum1 + element.price
                    arr1.push(element)
                }
            });
            if (arr1.length > 0) {
                setAllChooze(arr1)
                setSumToPay(sum1)
            }
        }
    }

    useEffect(() => {
        if (s.id && allChooze.length === 0) {
            var listCart = []
            if (cartSelector.length <= 0) {
                GetShopCartBussiness(s.id).then((data) => {
                    var allListCart = data.filter(a => a.bussinessId === sBussiness.id && a.categoryCode === String(sBussiness.categoryCode));
                    allListCart.forEach(lc => {
                        if (lc?.shoppingCartDetails.length > 0)
                            listCart = lc.shoppingCartDetails
                    });
                    d(COMPLETEallShoppingCart(data))
                    fillListCart(listCart);
                }).catch((error) => { console.error(error); });
            } else {
                var allListCart = cartSelector.filter(a => a.bussinessId === sBussiness.id && a.categoryCode === String(sBussiness.categoryCode));
                allListCart.forEach(lc => {
                    if (lc?.shoppingCartDetails.length > 0)
                        listCart = lc.shoppingCartDetails
                });
                fillListCart(listCart);
            }
        }
    }, [s])

    // useEffect(() => {
    //     if (shoppingCartDetails.length > 0 && allChooze.length === 0) {
    //         var arr = [], sum = 0
    //         shoppingCartDetails.forEach(element => {
    //             for (let i = 0; i < element.amount; i++) {
    //                 sum = sum + element.price
    //                 arr.push(element)
    //             }
    //         });
    //         // for (let i = 0; i < arr.length; i++) {
    //         //     delete arr[i].amount
    //         // }
    //         if (arr.length > 0) {
    //             setAllChooze(arr)
    //             setSumToPay(sum)
    //         }
    //     }
    // }, [shoppingCartDetails])


    const fillPays = () => {
        GetPaysInit(2).then((data) => {
            setAllPays(data)
        }).catch((error) => { console.error(error); });
    }


    const finishAllBuy = () => {
        var shopping = { userId: s.id, nameBussiness: prop.bussiness.name, mailBussiness: prop.bussiness.mail, date: new Date(), price: sumToPay }

        var details = [], det = []
        Array.from(new Set(allChooze.map(obj => obj.id))).map(id => allChooze.find(obj => obj.id === id)).forEach(element => {
            details.push({ shoppingId: element.s, lotOfItemsId: element.id, amount: allChooze.filter(a => a.id === element.id).length })
            det.push({ id: element.id, name: element.name, price: element.price, picture: element.picture, description: element.description, amount: allChooze.filter(a => a.id === element.id).length })
        });
        FinishBuy({ shopping: shopping, details: details }).then((data1) => {
            d(COMPLETEAddorders({ categoryCode: sBussiness.categoryCode === 1 || sBussiness.categoryCode === 82 || [2, 179, 182].includes(sBussiness.categoryCode) || sBussiness.categoryCode === 3 || sBussiness.categoryCode === 4 ? sBussiness.categoryCode : 0, bussinessId: sBussiness.id, date: new Date().toISOString(), nameBussiness: sBussiness.name, mailBussiness: sBussiness.mail, detailsShopping: det, sum: sumToPay }))
            d(COMPLETEDelCart(data1))
            setAllChooze([])
            setSumToPay(0)
            //שליחת המיילים לבעל העסק וללקוח
            var listAll = ''
            var allChoozeUnique = allChooze.filter((x, index) => {
                return allChooze.findIndex((y) => y.code === x.code) === index;
            });
            allChoozeUnique.forEach(element => {
                listAll += `<li><b>קוד/מק"ט מוצר: </b>${element.code}, <b>שם מוצר: </b>${element.name}, <b>תיאור: </b>${element.description}, <b>מחיר: </b>${element.price}, <b>כמות: </b>${allChooze.filter(a => a.id === element.id).length}. </li>`
            })
            var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום ל${sBussiness.name} </p></div><br/> <br/><b>  לקוח ביצע הזמנה, </b> <br/>  <br/>  <b>שם הלקוח: </b> ${s.lastName}.<br/>  <b>מספר טלפון: </b> ${boolPhone}${boolPhoneMore ? ',' : ''} ${boolPhoneMore}.  ${boolMail ? '<br/><b>כתובת מייל: </b>' : ' '} ${boolMail} ${boolAddres ? '<br/> <b>כתובת לקבלת השירות: </b>' : ''} ${boolAddres}<br/><br/> <div style="background-color: #c00000; height: 1px; width: 60%; margin-right: 20%;"></div><br/><b>צור בהקדם קשר עם הלקוח על מנת לאשר את העיסקה.</b><br/><br/> <div style="border:1px solid #c00000; border-right: none; border-left: none; width: 60%; margin-right: 20%;"> <br/> <b>פרטי ההזמנה: </b><br/> <ul style="text-align: right;"> ${listAll} </ul> <br/> </div> <br/> <b> ${mishlochimYes ? 'הלקוח סימן: אני רוצה משלוח' : 'הלקוח אינו סימן: אני רוצה משלוח'} </b> ${boolMore ? '<br/><b>הערות שכתב הלקוח: </b>' : ''} ${boolMore} <br/><b>סה"כ סכום לתשלום:</b> ${sumToPay} ש"ח <br/><br/> <b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
            SendEmailToBussiness({ name: "", emailTo: sBussiness.mail, emailFrom: boolMail, subject: 'קול שמחה - לקוח ביצע הזמנה', message: mes }).then(() => { }).catch((error) => { console.error(error); });
            var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום למשפחת ${s.lastName} </p></div><br/> <br/><b>  תודה שבחרתם קול שמחה </b> <br/>  <br/>הספק ${sBussiness.name} קיבל את פנייתכם ויצור איתכם קשר לסיום ההזמנה. <br/>  <br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
            SendEmailToBussiness({ name: "", emailTo: s.mail, emailFrom: 'info@kolsimcha.co.il', subject: 'תודה שבחרתם קול שמחה', message: mes }).then(() => { }).catch((error) => { console.error(error); });
            //עד כאן שליחת המיילים
            if (numPay !== -1) {
                GetpersonalPriceIdBy(numPay, s.id).then((data) => {
                    if (data === -1)
                        AddPersonalPaid({ pricePaid: sumToPay, paysId: numPay, userId: s.id }).then((data) => { setOpenMod(false); setOpPay(false); setGood(true) }).catch((error) => console.error(error))
                    else
                        UpdatePersonalPaid(numPay, sumToPay + data, s.id).then((data3) => { setOpenMod(false); setOpPay(false); setGood(true) }).catch((error) => console.error(error))
                }).catch((error) => { console.error(error); });
            }
            else {
                setOpenMod(false)
                setOpPay(false)
                setGood(true)
            }
        }).catch((error) => { console.error(error); });
    }

    const [imageZoom, setImageZoom] = useState(false)

    return <div id='lot'>
        {prop.lotOfItems?.length > 0 && <div style={{ width: '90%', marginRight: 'auto', marginLeft: 'auto' }}>
            <p style={{ fontSize: '130%', fontWeight: '500', paddingTop: '5%', marginBottom: '45px' }}>ניתן להזמין בקלות</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-45px', marginBottom: '2%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
            {prop.lotOfItems.map((x, i) => (
                <div key={i} className='cardim'>
                    <ImageDiv className='imagg' pic={encodeURI(`${baseImageUrl}${x.picture.replace(/\\/g, '\/')}?format=avif`)} onClick={() => { setImageZoom(x.picture) }}></ImageDiv>
                    <div className='inCard1'>
                        <div style={{ fontWeight: '400' }}>{x.name}</div>
                        <div style={{ paddingRight: '2%', paddingLeft: '2%', fontSize: `${x.description.length < 67 ? '100%' : x.description.length < 85 ? '90%' : '80%'}`, lineHeight: `${x.description.length < 67 ? '37px' : x.description.length < 85 ? '30px' : '25px'}` }}>{x.description}</div>
                        <div><span style={{ fontWeight: '400' }}>מחיר:</span> {x.price} ש"ח</div>
                    </div>
                    {allChooze.filter(a => a.id == x.id).length <= 0 && <div className='inCard2' onClick={() => { if (s.id) { AddShoppingCart(x.id, { userId: s.id, codeCategory: sBussiness.categoryCode === 1 || sBussiness.categoryCode === 82 || [2, 179, 182].includes(sBussiness.categoryCode) || sBussiness.categoryCode === 3 || sBussiness.categoryCode === 4 ? sBussiness.categoryCode : 0, bussinessId: sBussiness.id, nameBussiness: prop.bussiness.name, mailBussines: prop.bussiness.mail, date: new Date() }).then((data) => { d(COMPLETEallShoppingCart(data)); setChooze(false); setTimeout(() => { setChooze(x) }, 3); setTimeout(() => { setChooze(false) }, 100 * 60 * 2); var add = [...allChooze, x]; add.sort((a, b) => a.code > b.code ? 1 : -1); setAllChooze(add); d(COMPLETEshoppingCartLotOfItems(add)); setSumToPay(prev => prev += x.price) }).catch((error) => { console.error(error); }); } else { setOpUser(true) } }}>הזמן</div>}
                    {allChooze.filter(a => a.id == x.id).length > 0 && <div className='inCard2'>
                        <span className='spanIn' onClick={() => {
                            AddShoppingCart(x.id, { userId: s.id, codeCategory: sBussiness.categoryCode === 1 || sBussiness.categoryCode === 82 || [2, 179, 182].includes(sBussiness.categoryCode) || sBussiness.categoryCode === 3 || sBussiness.categoryCode === 4 ? sBussiness.categoryCode : 0, bussinessId: sBussiness.id, nameBussiness: prop.bussiness.name, mailBussines: prop.bussiness.mail, date: new Date() }).then((data) => {
                                var add = [...allChooze, x];
                                add.sort((a, b) => a.code > b.code ? 1 : -1);
                                setAllChooze((prev) => { return [...prev, x] });
                                d(COMPLETEshoppingCartLotOfItems(add));
                                setSumToPay(prev => prev += x.price);
                                d(COMPLETEallShoppingCart(data));
                                setChooze(false);
                                setTimeout(() => { setChooze(x) }, 3);
                                setTimeout(() => { setChooze(false) }, 100 * 60 * 2);
                            }).catch((error) => { console.error(error); });
                        }}><PlusOutlined /></span>
                        <span>{allChooze.filter(a => a.id == x.id).length}</span>
                        <span className='spanIn' onClick={() => { AddShoppingCart(x.id, { id: -1, userId: s.id, codeCategory: sBussiness.categoryCode === 1 || sBussiness.categoryCode === 82 || [2, 179, 182].includes(sBussiness.categoryCode) || sBussiness.categoryCode === 3 || sBussiness.categoryCode === 4 ? sBussiness.categoryCode : 0, bussinessId: sBussiness.id, nameBussiness: prop.bussiness.name, mailBussines: prop.bussiness.mail, date: new Date() }).then((data) => { d(COMPLETEallShoppingCart(data)); var add = allChooze.filter(a => a.id == x.id).length - 1; var del = allChooze.filter(a => a.id !== x.id); for (let i = 0; i < add; i++) { del.push(x) }; del.sort((a, b) => a.code > b.code ? 1 : -1); setAllChooze(del); d(COMPLETEshoppingCartLotOfItems(del)); setSumToPay(prev => prev -= x.price) }).catch((error) => { console.error(error); }) }}><MinusOutlined /></span>
                    </div>}
                </div>
            ))}
        </div>}

        <div style={{ marginTop: '2%', marginBottom: '7%', width: '20%', marginRight: '40%' }} className='buton' onClick={() => { setChooze(false); setOpenMod(true); fillPays() }}><ButtonRed text='סיום הזמנה'></ButtonRed></div>



        {chooze && <div className="product animate__animated animate__bounceInUp">
            <div className='added' onClick={() => { AddShoppingCart(chooze.id, { id: -1, userId: s.id, codeCategory: sBussiness.categoryCode === 1 || sBussiness.categoryCode === 82 || [2, 179, 182].includes(sBussiness.categoryCode) || sBussiness.categoryCode === 3 || sBussiness.categoryCode === 4 ? sBussiness.categoryCode : 0, bussinessId: sBussiness.id, nameBussiness: prop.bussiness.name, mailBussines: prop.bussiness.mail, date: new Date() }).then((data) => { d(COMPLETEallShoppingCart(data)) }).catch((error) => { console.error(error); }); setChooze(false); var add = allChooze.filter(a => a == chooze).length - 1; var del = allChooze.filter(a => a !== chooze); for (let i = 0; i < add; i++) { del.push(chooze) }; del.sort((a, b) => a.code > b.code ? 1 : -1); setAllChooze(del); setSumToPay(prev => prev -= chooze.price) }} style={{ height: '11.7vh', display: 'inline-block', width: '25%', textAlign: 'center', paddingTop: '4%' }} onMouseEnter={(e) => { e.target.innerText = 'ביטול' }} onMouseLeave={(e) => { e.target.innerText = 'נוסף להזמנה' }}>נוסף<br></br>להזמנה</div>
            <div style={{ height: '12vh', display: 'inline-block' }} className='prudu'>{chooze.name}<br></br>{chooze.price} ש"ח</div>
            <img loading='lazy' src={encodeURI(`${baseImageUrl}${chooze.picture.replace(/\\/g, '\/')}?format=avif`)} className='proPic'></img>
        </div>}

        {openMod && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => { { if (mishlochimYes) { setSumToPay((p) => { return p - prop.mishlochim }) }; setOpenMod(false) } }} style={{ cursor: 'pointer', position: 'absolute', left: '22%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '60%', minHeight: '90vh', height: 'auto', maxHeight: '95vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '20%', marginTop: '5vh', overflowY: 'auto' }}>
                    <p style={{ marginTop: '5%' }}>הזמנתכם כוללת {allChooze.length} פריטים</p>
                    <div style={{ width: '60%', marginRight: '20%', maxHeight: '40vh', overflow: `${allChooze.length <= 3 ? 'none' : 'auto'}` }}>
                        {Array.from(new Set(allChooze.map(obj => obj.id))).map(id => allChooze.find(obj => obj.id === id)).map((x, i) => (
                            <div key={i} style={{ border: 'rgb(128, 14, 14) 2px solid', height: '12vh', marginTop: '1%', position: 'relative' }}>
                                <div className='added' style={{ height: '11.6vh', display: 'inline-block', width: '25%', textAlign: 'center', paddingTop: '4%', float: 'right' }}><span className='spanIn' onClick={() => { AddShoppingCart(x.id, { userId: s.id, codeCategory: sBussiness.categoryCode === 1 || sBussiness.categoryCode === 82 || [2, 179, 182].includes(sBussiness.categoryCode) || sBussiness.categoryCode === 3 || sBussiness.categoryCode === 4 ? sBussiness.categoryCode : 0, bussinessId: sBussiness.id, nameBussiness: prop.bussiness.name, mailBussines: prop.bussiness.mail, date: new Date() }).then((data) => { d(COMPLETEallShoppingCart(data)) }).catch((error) => { console.error(error); }); setChooze(false); setTimeout(() => { setChooze(x) }, 3); setTimeout(() => { setChooze(false) }, 100 * 60 * 2); var add = [...allChooze, x]; add.sort((a, b) => a.code > b.code ? 1 : -1); setAllChooze(add); setSumToPay(prev => prev += x.price) }}><PlusOutlined /></span><span>{allChooze.filter(a => a.id == x.id).length}</span><span className='spanIn' onClick={() => { AddShoppingCart(x.id, { id: -1, userId: s.id, codeCategory: sBussiness.categoryCode === 1 || sBussiness.categoryCode === 82 || [2, 179, 182].includes(sBussiness.categoryCode) || sBussiness.categoryCode === 3 || sBussiness.categoryCode === 4 ? sBussiness.categoryCode : 0, bussinessId: sBussiness.id, nameBussiness: prop.bussiness.name, mailBussines: prop.bussiness.mail, date: new Date() }).then((data) => { d(COMPLETEallShoppingCart(data)) }).catch((error) => { console.error(error); }); var add = allChooze.filter(a => a.id == x.id).length - 1; var del = allChooze.filter(a => a.id !== x.id); for (let i = 0; i < add; i++) { del.push(x) }; del.sort((a, b) => a.code > b.code ? 1 : -1); setAllChooze(del); setSumToPay(prev => prev -= x.price) }}><MinusOutlined /></span></div>
                                <div style={{ height: '12vh', display: 'inline-block', width: '30vh' }} className='prudu2'>{x.name}<br></br>{x.price} ש"ח</div>
                                <img loading='lazy' src={encodeURI(`${baseImageUrl}${x.picture.replace(/\\/g, '\/')}?format=avif`)} className='proPic2'></img>
                            </div>
                        ))}
                    </div>
                    <div></div>
                    {prop.mishlochim && prop.mishlochim !== -1 && prop.mishlochim !== -2 && <div style={{ marginTop: '2%' }}>מחיר משלוח הוא: {prop.mishlochim} ש"ח</div>}
                    {prop.mishlochimNote && <p style={{ width: '80%', marginRight: '10%', fontSize: '90%' }}>{prop.mishlochimNote}</p>}
                    {prop.mishlochim && prop.mishlochim !== -1 && prop.mishlochim !== -2 && <div><div style={{ position: 'relative' }}><label className="checkbox-container"><input type="checkbox" className="checkbox1" value={mishlochimYes} onClick={(e) => { setSumToPay(prev => e.target.checked ? prev += prop.mishlochim : prev -= prop.mishlochim); setMishlochimYes(!mishlochimYes) }} /><span className="checkbox-custom">&#10003;</span> &nbsp; אני רוצה משלוח.</label></div><br></br><span style={{ fontSize: '80%' }}>*בסימון אני רוצה משלוח - מחיר המשלוח נוסף להזמנה.</span></div>}
                    <p>סה"כ סכום לתשלום: {sumToPay} ש"ח</p>
                    <div className='buton1' style={{ width: '20%', marginRight: 'auto', marginLeft: 'auto', marginTop: '2%', marginBottom: '5%' }} onClick={() => {
                        GetPayId(Number(prop.categoryCode)).then((dataPaysId) => {
                            setOpPay(true)
                            setNumPay(dataPaysId)
                        }).catch((error) => { console.error(error); });
                    }}><ButtonRed text='המשך'></ButtonRed></div>
                </div>
            </div>
        </div>}


        {opPay && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => { setOpPay(false) }} style={{ cursor: 'pointer', position: 'absolute', left: '22%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '60%', height: '90vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '20%', marginTop: '5vh' }}>

                    <p style={{ marginTop: '10%', fontSize: '120%' }}>לצורך אירגון חישוב הוצאות בחרו לאיזו הוצאה לשייך הזמנה זו</p>
                    <select className="custom-select1" onChange={(e) => { setNumPay(e.target.value) }} style={{ marginTop: '1%', marginBottom: '8%' }}>
                        {allPays.map((x, i) => (
                            <option value={x.id} selected={x.id == numPay ? true : false}>{x.name}</option>
                        ))}
                        <option style={{ fontWeight: '400' }} value={-1}>אחר</option>
                    </select>
                    {/* {numPay === "-1" && <p>הוספת הוצאה אישית</p>} */}

                    <input className='input20 ii' style={{ width: '70%' }} placeholder="כתובת מדוייקת לקבלת השירות" autoComplete="on" type="text" value={boolAddres} onChange={(e) => { setBoolAddres(e.target.value) }} />
                    <input className='input20 ii' style={{ width: '70%' }} placeholder="*מספר טלפון ליצירת קשר" autoComplete="on" type="text" value={boolPhone} onChange={(e) => { if (e.target.value.length <= 10 && (/^[0-9]*$/.test(e.target.value) || e.target.value === '')) { setBoolPhone(e.target.value) } }} />
                    <input className='input20 ii' style={{ width: '70%' }} placeholder="מספר טלפון נוסף" autoComplete="on" type="text" value={boolPhoneMore} onChange={(e) => { if (e.target.value.length <= 10 && (/^[0-9]*$/.test(e.target.value) || e.target.value === '')) { setBoolPhoneMore(e.target.value) } }} />
                    <input className='input20 ii' style={{ width: '70%' }} placeholder="כתובת מייל" autoComplete="on" type="text" value={boolMail} onChange={(e) => { setBoolMail(e.target.value) }} />
                    <input className='input20 ii' style={{ width: '70%' }} placeholder="כתוב למוכר" autoComplete="on" type="text" value={boolMore} onChange={(e) => { setBoolMore(e.target.value) }} />


                    <div className='buton1' style={{ width: '20%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => {
                        if (boolPhone.length === 9 || boolPhone.length === 10) {
                            if (s.id)
                                finishAllBuy()
                            else
                                setOpUser(true)
                        }
                    }}><ButtonRed text='המשך'></ButtonRed></div>
                </div>
            </div>
        </div>}

        {good && <div id='modal' style={{ backgroundImage: `url(${pic2})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%' }}><div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <div className='glow' style={{ width: '30%' }}>
                    <p className='titl' style={{ backgroundColor: '#820909', color: 'white', marginTop: '0', paddingTop: '6%', paddingBottom: '6%' }}>ההזמנה בוצעה בהצלחה</p>
                    <p className='titl' style={{ marginTop: '12%' }}>הספק קיבל את פנייתכם <br />ויצור איתכם קשר לסיום ההזמנה. <br /><br />בהצלחה והרבה סייעתא דשמייא <br />בהמשך ההכנות לחתונה!!</p>
                    <div id='submit30' onClick={() => { setGood(false) }}><ButtonRed text={'אישור'}></ButtonRed></div>
                </div>
            </div>
        </div></div>}


        {opUser && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => setOpUser(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '40vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '30vh' }}>
                    <div className='titl' style={{ marginTop: '8%', fontSize: '120%', marginBottom: '5%' }}>לא ניתן לבצע פעולה זו, עליכם להתחבר או להירשם למערכת</div>
                    <div style={{ width: '10%', display: 'inline-block' }} onClick={() => setLogin(true)}><ButtonRed text='התחבר'></ButtonRed></div>
                    <div style={{ width: '10%', display: 'inline-block' }} onClick={() => setConnection(true)}><ButtonRed text='הירשם'></ButtonRed></div>
                </div>
            </div>
        </div>}

        {imageZoom && <div id='modal2' style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
            <div>
                <CloseOutlined onClick={() => setImageZoom(false)} style={{ cursor: 'pointer', position: 'absolute', left: '5%', marginTop: '1.5%', color: 'white' }} />
                <img loading='lazy' className='animate__animated animate__zoomIn' style={{ height: '90vh', width: 'auto', display: 'inline-block', marginTop: '5vh' }} src={encodeURI(`${baseImageUrl}${imageZoom.replace(/\\/g, '\/')}?format=avif`)} onClick={() => { setImageZoom(true) }}></img>
            </div>
        </div>}

        {login && <Login setLogin={(bool) => { setLogin(false); setOpUser(false) }}></Login>}
        {connection && <Connection set={(bool) => { setConnection(false); setOpUser(false) }}></Connection>}

    </div>
}

export default LotOfItems
