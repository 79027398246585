import down from '../../pic/animations/134.gif'

const Down = () => {
    return <div id='downMore' style={{ position: 'absolute', bottom: '2%', left: '2%', zIndex: '200', position: 'fixed', backgroundColor: 'rgb(218, 166, 166)', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', borderRadius: '4px' }}>
        <div style={{ position: 'relative' }}>
            <img loading='lazy' alt='גלול עוד' src={down} style={{ width: '80px', height: '80px', padding: '1%' }}></img>
            <span style={{ fontSize: '70%', position: 'absolute', top: '34%', left: '38%', lineHeight:'18px', fontWeight:'400' }}>גלול<br></br>עוד</span>
        </div>
    </div>
}

export default Down