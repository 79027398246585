import './AddToDataBase.css'
import '../AddBusiness.css'
import { useEffect, useRef, useState } from "react";
import ButtonRed from "../../../global/button/button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import picError from '../../../../pic/animations/113.gif'
import { EditBussinessCateringDetails, EditBussinessShopDetails, EditBussinessSongsDetails, EditBussinessWallDetails, GetQuestions, GetSpecialFields, SendEmailChangeInPhone, SendEmailToBussiness } from "../../../axios/api";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { CompleteBussinessData, CompleteThisBussiness } from "../../../2. redux/actions/thisBussinessActions";
import pic2 from '../../../../pic/logo/444.jpg'

const EditBussinessDetails = (props) => {

    const [newBusiness, setNewBusiness] = useState([
        { name: '', description: '', adventage: '', bonus: '', openingHours: '', address: '', phone: '', mail: '', city: '', kishurLahatar: '', keywords: '' },//חנות רגילה
        { name: '', description: '', adventage: '', place: '', size: '', price: '', phone: '', mail: '', includePrice: '', city: '', kishurLahatar: '', keywords: '', rangeDishes: '', rangePriceToDishes: '' },//אולם
        { name: '', description: '', adventage: '', priceList: '', rangeDishes: '', rangeDishesKabalat: '', rangePrice: '', mail: '', phone: '', area: '', kishurLahatar: '', keywords: '' },// קייטרינג , צלמים
        { name: '', description: '', price: '', priceAdd: '', phone: '', mail: '', includePrice: '', signon: '', area: '', kishurLahatar: '', keywords: '' }//שירים
    ]);

    const [numBusiness, setNumBusiness] = useState(0);
    const [num, setNum] = useState(1)
    const [errorMessage, setErrorMessage] = useState('');
    const [address, setAddress] = useState([]);
    const [city, setCity] = useState([]);
    const [addQuestion, setAddQuestion] = useState(false);
    const [send1, setSend1] = useState(false)
    const [openPic, setOpenPic] = useState(false)
    const [notUpdateInPhone, setNotUpdateInPhone] = useState(false);
    const [createChanges, setCreateChanges] = useState(false);
    const [questiuons, setQuestions] = useState();
    const [answers, setAnswers] = useState([]);
    const [kosherLetters, setKosherLetters] = useState([]);
    const [delPic, setDelPic] = useState([]);
    const [think, setThink] = useState(false)
    const [noPrice, setNoPrice] = useState(false)
    const [ccToAllTables, setCcToAllTables] = useState()
    const isAddBus = props.isAddBus;
    const baseImageUrl = "https://kolsimcha.co.il/images";
    const didExios = useRef(false);

    const cc = JSON.parse(sessionStorage.getItem('mainCC'));
    const op = JSON.parse(sessionStorage.getItem('optionsNames'))
    const categoryName = JSON.parse(sessionStorage.getItem('categoryName')) ?? op?.substring(15, op.indexOf(".", 15));
    const [SpecialFields, setSpecialFields] = useState(JSON.parse(sessionStorage.getItem('SpecialFields')))

    const s = useSelector((state) => state.thisBussinessReducer);
    const n = useNavigate()
    const d = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0);
        var numBus = cc === "1" || cc === "82" ? 1 : cc === "3" || cc === "4" ? 2 : ['2', '179', '182'].includes(cc) ? 3 : 0
        setNumBusiness(numBus)

        if (s.categoryCode && SpecialFields === null && !didExios.GetSpecialFields) { // רק אם טרם התבצע
            didExios.GetSpecialFields = true;  // להימנע מביצוע נוסף
            GetSpecialFields(Number(String(s.categoryCode).split(',')[0])).then((data) => {
                setSpecialFields(data)
            }).catch((error) => { console.error(error); });
        }

        // מילוי התשובות
        if (s.categoryCode && !didExios.GetQuestions) {  // רק אם טרם התבצע
            didExios.GetQuestions = true;  // להימנע מביצוע נוסף
            GetQuestions(String(s.categoryCode).split(",")[0]).then((data) => {
                setQuestions(data)
                var ans = [], a = {};
                data.forEach(q => {
                    a = s.bussinessData.answers?.find(x => x.questionId === q.id);
                    a ? ans.push({ answer1: a.answerCon ? a.answerCon : a.answer1.split('.,')[1], questionId: a.questionId, categoryCode: numBus === 0 ? 0 : s.categoryCode, bussinessId: s.id }) : ans.push({ answer1: '', questionId: '', categoryCode: '', bussinessId: 0 });
                });
                setAnswers(ans)
            }).catch((error) => { console.error(error); });
        }

        // מילוי חנות רגילה
        if (numBus === 0) {
            var Saddress = s.address?.split(',.') ?? [], Scity = s.city?.split(',.') ?? [];
            if (s.address?.length) {
                var newSaddress = s.address, newScity = s.city
                var ad = [], ci = []
                for (let i = 1; i < Saddress.length || i < Scity.length; i++) { ad.push(Saddress[i]); ci.push(Scity[i]); }
                setAddress(ad); setCity(ci);
                var newS = s; newS.address = newSaddress; newS.city = newScity;
            }
            setNewBusiness((prev) => {
                const upBus = [...prev];
                upBus[0] = { name: s.name ?? '', description: s.description ?? '', answers: [], adventage: s.adventage ?? '', bonus: s.bonus ?? '', openingHours: s.openingHours ?? '', address: Saddress[0] ?? '', phone: s.phone ?? '', mail: s.mail ?? '', city: Scity[0] ?? '', kishurLahatar: s.kishurLahatar ?? '', keywords: s.keywords ?? '' }//חנות רגילה
                return upBus;
            });
        }
        // מילוי אולם
        else if (numBus === 1)
            setNewBusiness((prev) => {
                const upBus = [...prev];
                upBus[1] = { name: s.name ?? '', description: s.description ?? '', answers: [], adventage: s.adventage ?? '', place: s.place ?? '', size: s.size ?? '', price: s.price ?? '', rangeDishes: s.rangeDishes ?? '', rangePriceToDishes: s.rangePriceToDishes ?? '', phone: s.phone ?? '', mail: s.mail ?? '', includePrice: s.includePrice ?? '', city: s.city ?? '', kishurLahatar: s.kishurLahatar ?? '', keywords: s.keywords ?? '' }//אולם
                return upBus;
            });
        // מילוי קייטרינג , צלמים
        else if (numBus === 2) {
            setKosherLetters(JSON.parse(JSON.stringify(s.bussinessData.songs ?? [])))
            setNewBusiness((prev) => {
                const upBus = [...prev];
                upBus[2] = { name: s.name ?? '', description: s.description ?? '', answers: [], songs: [], adventage: s.adventage ?? '', priceList: s.priceList ?? '', rangeDishes: s.rangeDishes ?? '', rangeDishesKabalat: s.rangeDishesKabalat ?? '', rangePrice: s.rangePrice ?? '', mail: s.mail ?? '', phone: s.phone ?? '', area: s.area ?? '', kishurLahatar: s.kishurLahatar ?? '', keywords: s.keywords ?? '' }//קייטרינג , צלמים
                return upBus;
            });
        }
        // מילוי שירים
        else if (numBus === 3)
            setNewBusiness((prev) => {
                const upBus = [...prev];
                upBus[3] = { name: s.name ?? '', description: s.description ?? '', answers: [], price: s.price ?? '', priceAdd: s.priceAdd ?? '', phone: s.phone ?? '', mail: s.mail ?? '', includePrice: s.includePrice ?? '', signon: s.signon ?? '', area: s.area ?? '', kishurLahatar: s.kishurLahatar ?? '', keywords: s.keywords ?? '' }//שירים
                return upBus;
            });

        setCcToAllTables(numBus === 0 ? 0 : s.categoryCode)
    }, [s])

    const handleNameChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 40) {
            // if (/^[A-Za-zא-ת ]+$/.test(inputValue) || inputValue === '') {
            setNewBusiness((prevBusiness) => {
                const updatedBusiness = [...prevBusiness];
                updatedBusiness[numBusiness] = { ...updatedBusiness[numBusiness], name: inputValue };
                return updatedBusiness;
            });
            // }
        }
    };

    const handlePriceAdd = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 100) {
            setNewBusiness((prevBusiness) => {
                const updatedBusiness = [...prevBusiness];
                updatedBusiness[numBusiness] = { ...updatedBusiness[numBusiness], priceAdd: inputValue };
                return updatedBusiness;
            });
        }
    };

    // const handleCityChange = (e) => {
    //     const inputValue = e.target.value;
    //     if (/^[A-Za-zא-ת ]+$/.test(e.target.value) || e.target.value === '') {
    //         setNewBusiness((prevBusiness) => {
    //             const updatedBusiness = [...prevBusiness];
    //             updatedBusiness[numBusiness] = { ...updatedBusiness[numBusiness], city: inputValue };
    //             return updatedBusiness;
    //         });
    //     }
    // };

    const handleChange = (e, field, length) => {
        const inputValue = e.target.value;
        if (JSON.stringify(inputValue).length - 2 <= length) {
            if (field === 'city' && (/^[A-Za-zא-ת ]+$/.test(inputValue) || inputValue === '') || field != 'city')
                setNewBusiness((prevBusiness) => {
                    const upBus = [...prevBusiness];
                    upBus[numBusiness] = { ...upBus[numBusiness], [field]: inputValue };
                    return upBus;
                });
        }
    };

    const handlePhoneNumberChange = (e, field) => {
        var inputValue = e.target.value, buss = numBusiness

        if (field !== "phone" && inputValue !== "")
            inputValue = parseInt(inputValue)

        if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
            setNewBusiness((prevBusiness) => {
                const updatedBusiness = [...prevBusiness];
                updatedBusiness[buss] = { ...updatedBusiness[buss], [field]: inputValue };
                return updatedBusiness;
            });
        }
    };

    const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        // if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputValue) || inputValue === '') {
        setNewBusiness((prevBusiness) => {
            const updatedBusiness = [...prevBusiness];
            updatedBusiness[numBusiness] = { ...updatedBusiness[numBusiness], mail: inputValue };
            return updatedBusiness;
        });
        // }
    };

    const handleSubmit = (e) => { //סוף
        e.preventDefault();
        setErrorMessage('');
        if (newBusiness[numBusiness].name === '' || newBusiness[numBusiness].description === '' || newBusiness[numBusiness].adventage === '' || newBusiness[numBusiness].city === '' || newBusiness[numBusiness].phone === '' || newBusiness[numBusiness].mail === '')
            setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה');
        else if (!newBusiness[numBusiness].name.trim() || !newBusiness[numBusiness].description.trim() || newBusiness[numBusiness].adventage != undefined && !newBusiness[numBusiness].adventage.trim() || newBusiness[numBusiness].city != undefined && !newBusiness[numBusiness].city.trim())
            setErrorMessage('לא תקין למלא רק רווחים בשדה חובה');
        else if (!/^\d{9,10}$/.test(newBusiness[numBusiness].phone))
            setErrorMessage('מספר נייד לא תקין');
        else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newBusiness[numBusiness].mail))
            setErrorMessage('כתובת מייל לא תקינה');
        else {
            setThink(true)
            var b = newBusiness[numBusiness];
            var answersGood = answers.filter(x => x.bussinessId != 0);

            // איך שהתשובות צריכות להישמר ברידקס
            var answersToRedux = answersGood;
            for (let i = 0; i < answersToRedux?.length; i++) {
                answersToRedux[i].questionCon = questiuons[i].question1;
                answersToRedux[i].answerCon = answersToRedux[i].answer1;
            }

            //מטפל בסניפים הנוספים - אם יש
            for (let i = 0; i < address?.length; i++) {
                if (address[i]?.length > 0 || city[i]?.length > 0) {
                    if (b.city.length + city[i].length < 98)
                        b.city = b.city + ',.' + city[i]
                    if (b.address.length + address[i].length < 148)
                        b.address = b.address + ',.' + address[i]
                }
            }

            if (!isAddBus) {
                // for (let i = 0; i < answersGood.length; i++) {
                //     delete answersGood[i].questionCon;
                //     delete answersGood[i].answerCon;
                // }
                // אם זה חנות רגילה שילך לפונקציה שעורכת חנות
                if (numBusiness === 0) {
                    EditBussinessShopDetails(s.id, b.name, b.description, answersGood, b.adventage, b.bonus, b.openingHours, b.address, b.phone, b.mail, b.city, b.kishurLahatar, b.keywords).then((data) => {
                        d(CompleteThisBussiness(data))
                        d(CompleteBussinessData({ ...s.bussinessData, answers: answersToRedux }))
                        if (s.phoneToCall?.length)
                            setNotUpdateInPhone(true)
                        n(-1);
                    }).catch((error) => { console.error(error); setThink(false); });
                }

                // אם זה אולם שילך לפונקציה שעורכת אולם
                else if (numBusiness === 1) {
                    EditBussinessWallDetails(s.id, b.name, b.description, answersGood, b.adventage, b.place, b.size, b.price, b.rangeDishes, b.rangePriceToDishes, b.phone, b.mail, b.city, b.includePrice, b.kishurLahatar, b.keywords).then((data) => {
                        d(CompleteThisBussiness(data))
                        d(CompleteBussinessData({ ...s.bussinessData, answers: answersToRedux }))
                        if (s.phoneToCall?.length)
                            setNotUpdateInPhone(true)
                        n(-1);
                    }).catch((error) => { console.error(error); setThink(false); });
                }

                // אם זה קייטרינג , צלמים שילך לפונקציה שעורכת קייטרינג , צלמים
                else if (numBusiness === 2) {
                    var songsToAdd = [], songs = kosherLetters, priceList = b.priceList;
                    for (let i = 0; i < songs.length; i++) {
                        if (songs[i].song1 instanceof File) { songsToAdd.push(new File([songs[i].song1], "Songs\\" + songs[i].song1.name, { type: songs[i].song1.type })); songs[i] = "\\Songs\\" + songs[i].song1.name; }
                        else { songs[i] = songs[i].song1; }
                    }
                    if (priceList instanceof File) { songsToAdd.push(new File([priceList], "PriceList\\" + priceList.name, { type: priceList.type })); priceList = "\\PriceList\\" + priceList.name; }
                    EditBussinessCateringDetails({ songsToAdd: songsToAdd, answersDTO: answersGood, songsUrl: songs, priceList: priceList, id: s.id, name: b.name, description: b.description, adventage: b.adventage, rangeDishes: b.rangeDishes, rangeDishesKabalat: b.rangeDishesKabalat, rangePrice: b.rangePrice, phone: b.phone, mail: b.mail, area: b.area, kishurLahatar: b.kishurLahatar, keywords: b.keywords }).then((data) => {
                        d(CompleteThisBussiness(data.bussiness))
                        d(CompleteBussinessData(data.bussinessData))
                        if (s.phoneToCall?.length)
                            setNotUpdateInPhone(true)
                        n(-1);
                    }).catch((error) => { console.error(error); setThink(false); });
                }

                // אם זה שירים שילך לפונקציה שעורכת שירים
                else if (numBusiness === 3) {
                    EditBussinessSongsDetails(s.id, b.name, b.description, answersGood, b.includePrice, b.signon, b.price, b.priceAdd, b.phone, b.mail, b.area, b.kishurLahatar, b.keywords).then((data) => {
                        d(CompleteThisBussiness(data))
                        d(CompleteBussinessData({ ...s.bussinessData, answers: answersToRedux }))
                        if (s.phoneToCall?.length)
                            setNotUpdateInPhone(true)
                        n(-1);
                    }).catch((error) => { console.error(error); setThink(false); });
                }
            } else {
                d(CompleteThisBussiness({ ...s, ...b }));
                d(CompleteBussinessData({ ...s.bussinessData, answers: answersToRedux, songs: kosherLetters }))
                props.enter()
            }
        }
    };

    const resizeImageTo300Pixels = (numSize, inputImage, funcKod) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.src = URL.createObjectURL(inputImage);
        image.onload = function () {
            canvas.width = numSize;
            canvas.height = (numSize * image.height) / image.width;
            ctx.drawImage(image, 0, 0, numSize, (numSize * image.height) / image.width);
            canvas.toBlob((blob) => {
                const resizedFile = new File([blob], inputImage.name, { type: 'image/png' });
                funcKod(resizedFile)
            }, 'image/png', 0.9);
        };
    }

    const changePic = async (e) => {
        if (e.target.files.length) {
            const funcK = (fileBlob) => {
                setNewBusiness(prevBusiness => {
                    return prevBusiness.map((item, index) => {
                        if (index === numBusiness)
                            return { ...item, priceList: fileBlob };
                        return item;
                    });
                });
            }
            resizeImageTo300Pixels(800, e.target.files[0], funcK)
        }
    }

    const clickPics = (e) => {
        const fileSelect = document.getElementById("fileSelect1");
        const fileElem = document.getElementById("fileElem1");
        fileSelect.addEventListener("click", fileElem.click());
    }

    const changePics = (e) => {
        if (e.target.files.length) {
            var pics = [...kosherLetters];
            const promises = []; // נאסוף את כל ההבטחות (promises)

            for (let i = 0; i < e.target.files.length; i++) {
                if (pics.length + i < 3) {
                    const promise = new Promise((resolve) => {
                        const funcK = (fileBlob) => {
                            pics.push({ song1: fileBlob, bussinessId: s.id, categoryCode: ccToAllTables, name: null });
                            resolve(); // לאחר שהוספנו את התמונה, נסיים את ההבטחה
                        };
                        resizeImageTo300Pixels(800, e.target.files[i], funcK); // קריאה לפונקציה שמבצעת resizing
                    });
                    promises.push(promise); // הוסף את ההבטחה למערך
                }
            }
            // לאחר שכולן סיימו, עדכן את ה-state
            Promise.all(promises).then(() => { setKosherLetters(pics); });
        }
    }

    const chooseDelPic = () => {
        var pics = [...kosherLetters];
        delPic.forEach(picToDel => {
            pics = pics.filter(x => picToDel != x.song1)
        });
        setKosherLetters(pics); setOpenPic(false); setDelPic([]);
    }

    return <div>
        <form onSubmit={handleSubmit}>
            {num === 1 && isAddBus && <p style={{ marginBottom: '5%', fontSize: '90%', marginTop: '-4%' }}>בתהליך קצר של 5 דקות<br />ממלאים את פרטי העסק <span style={{ color: '#c00000' }}>&#8592;</span> ומצטרפים למאגר נותני השירות<br />ניתן בכל רגע  נתון לערוך ולהחליף את הנתונים.</p>}

            {/* המסך של מילוי הפרטים לאולם / קייטרינג , צלמים */}
            {num === 1 && (numBusiness === 1 || numBusiness === 2) && <div>
                <div style={{ width: '90%', marginRight: '5%', marginTop: '0%' }}>
                    <div className='gridTempAdd' style={{ display: 'grid' }}>
                        <div>
                            <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*שם העסק" autoComplete="on" type="text" name="your-name" value={newBusiness[numBusiness].name} onChange={(e) => { if (e.target.value.length <= 40) { handleNameChange(e) } }} />
                            <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*נייד של איש קשר" onFocus={(e) => { e.target.placeholder = "*נייד של איש קשר - לשימוש פנימי בלבד"; e.target.style.fontSize = "80%"; }} onBlur={(e) => { e.target.placeholder = "*נייד של איש קשר"; e.target.style.fontSize = "100%" }} autoComplete="on" type="text" name="txtPhone" value={newBusiness[numBusiness].phone} onChange={(e) => { if (e.target.value.length <= 10) { handlePhoneNumberChange(e, 'phone') } }} />
                            <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*דואר אלקטרוני" onFocus={(e) => { e.target.placeholder = "*דואר אלקטרוני - לשימוש פנימי בלבד"; e.target.style.fontSize = "80%"; }} onBlur={(e) => { e.target.placeholder = "*דואר אלקטרוני"; e.target.style.fontSize = "100%" }} autoComplete="email" type="email" name="email" value={newBusiness[numBusiness].mail} onChange={(e) => { if (e.target.value.length <= 254) { handleEmailChange(e) } }} />
                            <input className='input20 ii' style={{ width: '70%' }} placeholder="קישור לאתר" autoComplete="on" type="text" value={newBusiness[numBusiness].kishurLahatar} onChange={(e) => handleChange(e, 'kishurLahatar', 100)} />
                            {numBusiness === 1 && <div style={{ border: 'black 1px solid', marginTop: '50px', paddingBottom: '3%' }}>
                                <p style={{ padding: '2%', paddingBottom: '3%' }}>בשני השדות הבאים: <br></br>יש להכניס <span style={{ fontWeight: '400' }}>מספר-מספר</span> ואז ניתן להמשיך לכתוב. <br></br>לדוגמה: <span style={{ fontWeight: '400' }}>200-300</span> מנות, מעבר ל-300 מנות <br />לא תהיה הגשה אישית.</p>
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="טווח כמות מנות סעודה" autoComplete="on" type="text" value={newBusiness[numBusiness].rangeDishes} onChange={(e) => { var val = e.target.value; if (/^\d*$/.test(val) || /^\d+-*$/.test(val) || /^\d+-+\d+.*/.test(val)) { handleChange(e, 'rangeDishes', 60) } }} />
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="טווח מחירים למנת סעודה" autoComplete="on" type="text" value={newBusiness[numBusiness].rangePriceToDishes} onChange={(e) => { var val = e.target.value; if (/^\d*$/.test(val) || /^\d+-*$/.test(val) || /^\d+-+\d+.*/.test(val)) { handleChange(e, 'rangePriceToDishes', 25) } }} />
                            </div>}
                            {numBusiness === 2 && <div>

                                <select className="custom-select1" onChange={(e) => handleChange(e, 'area', 20)} style={{ marginTop: '7%', marginBottom: '7%' }} defaultChecked={newBusiness[numBusiness].area} defaultValue={newBusiness[numBusiness].area}>
                                    <option style={{ fontWeight: '400' }} value="" >*בחר איזור פעילות:</option>
                                    <option value="כל הארץ" >כל הארץ</option>
                                    <option value="צפון">צפון</option>
                                    <option value="מרכז">מרכז</option>
                                    <option value="ירושלים והסביבה">ירושלים והסביבה</option>
                                    <option value="דרום">דרום</option>
                                </select>

                                <div style={{ display: 'inline-block', marginTop: '8%' }}>*קובץ תמונה המכיל את {cc === "3" ? 'המחירון' : 'התפריט והמחירון'}</div><br></br>
                                <input type="file" id="fileElem" accept="image/*" onChange={changePic} style={{ display: 'none' }} />
                                <input type="button" className="submit1 input" id="fileSelect" onClick={() => { document.getElementById("fileSelect").addEventListener("click", document.getElementById("fileElem").click()); }} value={!newBusiness[numBusiness].priceList ? "העלה תמונה" : "החלף תמונה"} />
                                <div id="fileList" style={{ marginBottom: '7%' }}> {!newBusiness[numBusiness].priceList && <p>עדיין לא נבחרה תמונה!</p>} {newBusiness[numBusiness].priceList && <img loading='lazy' src={newBusiness[numBusiness].priceList instanceof Blob || newBusiness[numBusiness].priceList instanceof File ? URL.createObjectURL(newBusiness[numBusiness].priceList) : encodeURI(baseImageUrl + newBusiness[numBusiness].priceList)} width={280}></img>} </div>
                            </div>}
                        </div>
                        <div>
                            {numBusiness === 1 && <div>
                                <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*כתובת" autoComplete="on" type="text" value={newBusiness[numBusiness].place} onChange={(e) => handleChange(e, 'place', 100)} />
                                <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*עיר" autoComplete="on" type="text" value={newBusiness[numBusiness].city} onChange={(e) => handleChange(e, 'city', 20)} />
                                <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*מספר מקומות" autoComplete="on" type="text" value={newBusiness[numBusiness].size} onChange={(e) => { if (/^[0-9-]*$/.test(e.target.value)) { handleChange(e, 'size', 100) } }} />
                                <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*מחיר האולם" autoComplete="on" type="text" value={newBusiness[numBusiness].price} onChange={(e) => { if ((/^[0-9]*$/.test(e.target.value) && e.target.value < 214748) || e.target.value === '') { handlePhoneNumberChange(e, 'price') } }} />
                                <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*מה כולל המחיר?" autoComplete="on" type="text" value={newBusiness[numBusiness].includePrice} onChange={(e) => handleChange(e, 'includePrice', 70)} />
                            </div>}
                            {numBusiness === 2 && <div>
                                {cc !== "3" && s.categoryCode != 30018 && s.categoryCode != 30019 && <div style={{ border: 'black 1px solid', paddingTop: '5%', paddingBottom: '10%' }}>
                                    <p style={{ padding: '6%', paddingBottom: '5%' }}>בשלושת השדות הבאים: <br></br>יש להכניס <span style={{ fontWeight: '400' }}>מספר-מספר</span> ואז ניתן להמשיך לכתוב. <br></br>לדוגמה: <span style={{ fontWeight: '400' }}>200-300</span> מנות, מעבר ל-300 מנות לא תהיה הגשה אישית.</p>
                                    <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*טווח כמות מנות סעודה" autoComplete="on" type="text" value={newBusiness[numBusiness].rangeDishes} onChange={(e) => { var val = e.target.value; if (/^\d*$/.test(val) || /^\d+-*$/.test(val) || /^\d+-+\d+.*/.test(val)) { handleChange(e, 'rangeDishes', 60) } }} />
                                    <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*טווח כמות מנות קבלת פנים" autoComplete="on" type="text" value={newBusiness[numBusiness].rangeDishesKabalat} onChange={(e) => { var val = e.target.value; if (/^\d*$/.test(val) || /^\d+-*$/.test(val) || /^\d+-+\d+.*/.test(val)) { handleChange(e, 'rangeDishesKabalat', 25) } }} />
                                    <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*טווח מחירים למנת סעודה" autoComplete="on" type="text" value={newBusiness[numBusiness].rangePrice} onChange={(e) => { var val = e.target.value; if (/^\d*$/.test(val) || /^\d+-*$/.test(val) || /^\d+-+\d+.*/.test(val)) { handleChange(e, 'rangePrice', 25) } }} />
                                </div>}
                                {cc !== "3" && (s.categoryCode === 30018 || s.categoryCode === 30019) && <div style={{ border: 'black 1px solid', paddingTop: '10%', paddingBottom: '10%' }}>
                                    <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*מינימום כמות מגשים" autoComplete="on" type="text" value={newBusiness[numBusiness].rangeDishesKabalat} onChange={(e) => { var val = e.target.value; if (/^\d*$/.test(val) || /^\d+-*$/.test(val) || /^\d+-+\d+.*/.test(val)) { handleChange(e, 'rangeDishesKabalat', 25) } }} />
                                    <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*מינימום מחיר למגש" autoComplete="on" type="text" value={newBusiness[numBusiness].rangePrice} onChange={(e) => { var val = e.target.value; if (/^\d*$/.test(val) || /^\d+-*$/.test(val) || /^\d+-+\d+.*/.test(val)) { handleChange(e, 'rangePrice', 25) } }} />
                                </div>}
                                <div>
                                    {cc === "3" && <div style={{ border: 'black 1px solid', paddingTop: '12%', paddingBottom: '10%', margin: '6%' }}>
                                        <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*מחיר לחבילת צילום רגילה" autoComplete="on" type="text" value={newBusiness[numBusiness].rangePrice} onChange={(e) => { if ((/^[0-9]*$/.test(e.target.value) && e.target.value.length < 15) || e.target.value === '') { handlePhoneNumberChange(e, 'rangePrice') } }} />
                                        <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*מה כוללת החבילה?" autoComplete="on" type="text" value={newBusiness[numBusiness].rangeDishes} onChange={(e) => handleChange(e, 'rangeDishes', 60)} />
                                        <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*מחיר לכל שעה נוספת" autoComplete="on" type="text" value={newBusiness[numBusiness].rangeDishesKabalat} onChange={(e) => handlePhoneNumberChange(e, 'rangeDishesKabalat', 25)} />
                                    </div>}
                                </div>
                            </div>}
                            <div style={{ width: '70%', marginRight: '15%' }}><p style={{ marginTop: '8vh' }}><b>מומלץ: </b>רשום מילות מפתח שכאשר יחפשו אותם יגיעו לעסק שלך:</p>
                                <input className='input20 ii' style={{ width: '100%' }} placeholder="סמן פסיק בין מילה למילה" autoComplete="on" type="text" value={newBusiness[numBusiness].keywords} onChange={(e) => handleChange(e, 'keywords', 100)} />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginBottom: '16%' }}></div>
                    <div className='buton' onClick={() => {
                        debugger
                        if (s.categoryCode === 30018 || s.categoryCode === 30019) { newBusiness[numBusiness].rangeDishes = 0 }
                        if ((numBusiness === 2) && (newBusiness[numBusiness].priceList === '' || newBusiness[numBusiness].area === '' || newBusiness[numBusiness].rangeDishes === '' || newBusiness[numBusiness].rangeDishesKabalat === '' || newBusiness[numBusiness].rangePrice === '' || newBusiness[numBusiness].name === '' || newBusiness[numBusiness].phone === '' || newBusiness[numBusiness].mail === '')) { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                        else if ((numBusiness === 2) && (!newBusiness[numBusiness].name.trim() || !newBusiness[numBusiness].phone.trim() || !newBusiness[numBusiness].mail.trim())) { setErrorMessage('לא תקין למלא רק רווחים בשדה חובה'); }
                        else if (numBusiness === 1 && (newBusiness[numBusiness].includePrice === '' || newBusiness[numBusiness].price === '' || newBusiness[numBusiness].size === '' || newBusiness[numBusiness].city === '' || newBusiness[numBusiness].place === '' || newBusiness[numBusiness].name === '' || newBusiness[numBusiness].phone === '' || newBusiness[numBusiness].mail === '')) { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                        else if (numBusiness === 1 && (!newBusiness[numBusiness].includePrice.trim() || !newBusiness[numBusiness].size.trim() || !newBusiness[numBusiness].city.trim() || !newBusiness[numBusiness].place.trim() || !newBusiness[numBusiness].name.trim() || !newBusiness[numBusiness].phone.trim() || !newBusiness[numBusiness].mail.trim())) { setErrorMessage('לא תקין למלא רק רווחים בשדה חובה'); }
                        else {
                            if (!/^\d{9,10}$/.test(newBusiness[numBusiness].phone)) { setErrorMessage('מספר נייד לא תקין'); return; }
                            else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newBusiness[numBusiness].mail)) { setErrorMessage('כתובת מייל לא תקינה'); return; }
                            else { setErrorMessage(''); setNum(num + 1); window.scrollTo(0, 0); }
                        }
                    }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%', minWidth: '20vh', zIndex: '5' }}><ButtonRed text='המשך    -->'></ButtonRed></div>
                </div>
            </div>}

            {/* המסך של מילוי הפרטים לשירים */}
            {num === 1 && numBusiness === 3 && <div>
                <div style={{ width: '90%', marginRight: '5%', marginTop: '0%' }}>
                    <div className='gridTempAdd' style={{ display: 'grid', marginBottom: '16%' }}>
                        <div>
                            <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*שם העסק" autoComplete="on" type="text" name="your-name" value={newBusiness[numBusiness].name} onChange={(e) => { if (e.target.value.length <= 40) { handleNameChange(e) } }} />
                            <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*נייד של איש קשר" onFocus={(e) => { e.target.placeholder = "*נייד של איש קשר - לשימוש פנימי בלבד"; e.target.style.fontSize = "80%"; }} onBlur={(e) => { e.target.placeholder = "*נייד של איש קשר"; e.target.style.fontSize = "100%" }} autoComplete="on" type="text" name="txtPhone" value={newBusiness[numBusiness].phone} onChange={(e) => { if (e.target.value.length <= 10) { handlePhoneNumberChange(e, 'phone') } }} />
                            <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*דואר אלקטרוני" onFocus={(e) => { e.target.placeholder = "*דואר אלקטרוני - לשימוש פנימי בלבד"; e.target.style.fontSize = "80%"; }} onBlur={(e) => { e.target.placeholder = "*דואר אלקטרוני"; e.target.style.fontSize = "100%" }} autoComplete="email" type="email" name="email" value={newBusiness[numBusiness].mail} onChange={(e) => { if (e.target.value.length <= 254) { handleEmailChange(e) } }} />
                            <input className='input20 ii' style={{ width: '70%' }} placeholder="קישור לאתר / קליפ" autoComplete="on" type="text" value={newBusiness[numBusiness].kishurLahatar} onChange={(e) => handleChange(e, 'kishurLahatar', 100)} />
                            <div style={{ width: '70%', marginRight: '15%' }}><p style={{ marginTop: '8vh' }}><b>מומלץ: </b>רשום מילות מפתח שכאשר יחפשו אותם יגיעו לעסק שלך:</p>
                                <input className='input20 ii' style={{ width: '100%' }} placeholder="סמן פסיק בין מילה למילה" autoComplete="on" type="text" value={newBusiness[numBusiness].keywords} onChange={(e) => handleChange(e, 'keywords', 100)} />
                            </div>
                        </div>
                        <div>
                            <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*מחיר רגיל" autoComplete="on" type="text" value={newBusiness[numBusiness].price} onChange={(e) => { if (e.target.value.length <= 100) { handlePhoneNumberChange(e, 'price') } }} />
                            <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*מה כולל המחיר ושמות הכלים" autoComplete="on" type="text" value={newBusiness[numBusiness].includePrice} onChange={(e) => handleChange(e, 'includePrice', 70)} />
                            <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*מחיר לשעות נוספות" autoComplete="on" type="text" value={newBusiness[numBusiness].priceAdd} onChange={(e) => { if (e.target.value.length <= 100) { handlePriceAdd(e) } }} />

                            <select className="custom-select1" onChange={(e) => handleChange(e, 'area', 20)} defaultChecked={newBusiness[numBusiness].area} defaultValue={newBusiness[numBusiness].area}>
                                <option style={{ fontWeight: '400' }} value="">*בחר איזור פעילות:</option>
                                <option value="כל הארץ">כל הארץ</option>
                                <option value="צפון">צפון</option>
                                <option value="מרכז">מרכז</option>
                                <option value="ירושלים והסביבה">ירושלים והסביבה</option>
                                <option value="דרום">דרום</option>
                            </select>

                            <select className="custom-select1" onChange={(e) => handleChange(e, 'signon', 25)} defaultChecked={newBusiness[numBusiness].signon} defaultValue={newBusiness[numBusiness].signon}>
                                <option style={{ fontWeight: '400' }} value="">*בחר סגנון מוזיקה:</option>
                                <option value="כל הסגנונות">כל הסגנונות</option>
                                <option value="חסידי אותנטי">חסידי אותנטי</option>
                                <option value="חסידי ישיבתי">חסידי ישיבתי</option>
                                <option value="מוזיקה עדכנית וחדשנית">מוזיקה עדכנית וחדשנית</option>
                                <option value="ליטאי">ליטאי</option>
                                <option value='חבד"י'>חבד"י</option>
                                <option value="מזרחי">מזרחי</option>
                                <option value="תקליטן">תקליטן</option>
                                <option value="הגברה">הגברה</option>
                                <option value="אחר">אחר</option>
                            </select>

                        </div>
                    </div>
                    <div className='buton' onClick={() => {
                        if (newBusiness[numBusiness].signon === '' || newBusiness[numBusiness].area === '' || newBusiness[numBusiness].priceAdd === '' || newBusiness[numBusiness].includePrice === '' || newBusiness[numBusiness].name === '' || newBusiness[numBusiness].phone === '' || newBusiness[numBusiness].mail === '') { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                        else if (!newBusiness[numBusiness].priceAdd.trim() || !newBusiness[numBusiness].includePrice.trim() || !newBusiness[numBusiness].name.trim() || !newBusiness[numBusiness].phone.trim() || !newBusiness[numBusiness].mail.trim()) { setErrorMessage('לא תקין למלא רק רווחים בשדה חובה'); }
                        else {
                            if (!/^\d{9,10}$/.test(newBusiness[numBusiness].phone)) { setErrorMessage('מספר נייד לא תקין'); return; }
                            else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newBusiness[numBusiness].mail)) { setErrorMessage('כתובת מייל לא תקינה'); return; }
                            else if (newBusiness[numBusiness].price === '') { setNoPrice(true) }
                            else { setErrorMessage(''); setNum(num + 1); window.scrollTo(0, 0); }
                        }
                    }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%', minWidth: '20vh', zIndex: '5' }}><ButtonRed text='המשך    -->'></ButtonRed></div>
                </div>
            </div>}

            {/* המסך של מילוי הפרטים לחנות רגילה */}
            {num === 1 && numBusiness === 0 && <div>
                <div style={{ width: '90%', marginRight: '5%', marginTop: '0%' }}>
                    <div className='gridTempAdd' style={{ display: 'grid', marginBottom: '16%' }}>
                        <div>
                            <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*שם העסק" autoComplete="on" type="text" name="your-name" value={newBusiness[numBusiness].name} onChange={(e) => { if (e.target.value.length <= 40) { handleNameChange(e) } }} />
                            <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*נייד של איש קשר" onFocus={(e) => { e.target.placeholder = "*נייד של איש קשר - לשימוש פנימי בלבד"; e.target.style.fontSize = "80%"; }} onBlur={(e) => { e.target.placeholder = "*נייד של איש קשר"; e.target.style.fontSize = "100%" }} autoComplete="on" type="text" name="txtPhone" value={newBusiness[numBusiness].phone} onChange={(e) => { if (e.target.value.length <= 10) { handlePhoneNumberChange(e, 'phone') } }} />
                            <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*דואר אלקטרוני" onFocus={(e) => { e.target.placeholder = "*דואר אלקטרוני - לשימוש פנימי בלבד"; e.target.style.fontSize = "80%"; }} onBlur={(e) => { e.target.placeholder = "*דואר אלקטרוני"; e.target.style.fontSize = "100%" }} autoComplete="email" type="email" name="email" value={newBusiness[numBusiness].mail} onChange={(e) => { if (e.target.value.length <= 254) { handleEmailChange(e) } }} />
                            <input className='input20 ii' style={{ width: '70%' }} placeholder="קישור לאתר" autoComplete="on" type="text" value={newBusiness[numBusiness].kishurLahatar} onChange={(e) => handleChange(e, 'kishurLahatar', 100)} />
                        </div>
                        <div>
                            <input className='input20 ii' style={{ width: '70%' }} placeholder={SpecialFields?.find(a => a.oldField === "שעות פתיחה") ? SpecialFields?.find(a => a.oldField === "שעות פתיחה").newField : "שעות פתיחה"} autoComplete="on" type="text" value={newBusiness[numBusiness].openingHours} onChange={(e) => handleChange(e, 'openingHours', 80)} />
                            <input className='input20 ii' style={{ width: '70%' }} placeholder="כתובת" autoComplete="on" type="text" value={newBusiness[numBusiness].address} onChange={(e) => handleChange(e, 'address', 40)} />
                            <input className='input20 ii kochavitFields' style={{ width: '70%' }} placeholder="*עיר" autoComplete="on" type="text" value={newBusiness[numBusiness].city} onChange={(e) => { handleChange(e, 'city', 100) }} />
                            {address.map((x, i) => (<span key={i}>
                                <div style={{ marginBottom: '6vh' }}></div>
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="כתובת" autoComplete="on" type="text" value={x} onChange={(e) => { setAddress((p) => { var arr = [...p]; arr[i] = e.target.value; return arr; }) }} />
                                <input className='input20 ii' style={{ width: '70%' }} placeholder="עיר" autoComplete="on" type="text" value={city[i]} onChange={(e) => { if (/^[A-Za-zא-ת ]+$/.test(e.target.value) || e.target.value === '') { setCity((p) => { var arr = [...p]; arr[i] = e.target.value; return arr; }) } }} />
                            </span>))}

                            <button type="button" className="submit1 input" onClick={() => { setAddress([...address, '']); setCity([...city, '']) }}>סניף נוסף</button>

                            <div style={{ width: '70%', marginRight: '15%' }}><p style={{ marginTop: '8vh' }}><b>מומלץ: </b>רשום מילות מפתח שכאשר יחפשו אותם יגיעו לעסק שלך:</p>
                                <input className='input20 ii' style={{ width: '100%' }} placeholder="סמן פסיק בין מילה למילה" autoComplete="on" type="text" value={newBusiness[numBusiness].keywords} onChange={(e) => handleChange(e, 'keywords', 100)} />
                            </div>
                        </div>
                    </div>
                    <div className='buton' onClick={() => {
                        if (newBusiness[numBusiness].name === '' || newBusiness[numBusiness].city === '' || newBusiness[numBusiness].phone === '' || newBusiness[numBusiness].mail === '') { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                        else if (!newBusiness[numBusiness].name.trim() || !newBusiness[numBusiness].city.trim() || !newBusiness[numBusiness].phone.trim() || !newBusiness[numBusiness].mail.trim()) { setErrorMessage('לא תקין למלא רק רווחים בשדה חובה'); }
                        else {
                            if (!/^\d{9,10}$/.test(newBusiness[numBusiness].phone)) { setErrorMessage('מספר נייד לא תקין'); return; }
                            else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newBusiness[numBusiness].mail)) { setErrorMessage('כתובת מייל לא תקינה'); return; }
                            else { setErrorMessage(''); setNum(num + 1); window.scrollTo(0, 0); }
                        }
                    }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%', minWidth: '20vh', zIndex: '5' }}><ButtonRed text='המשך    -->'></ButtonRed></div>
                </div>
            </div>}

            {/* המשך מילוי הפרטים להכל חוץ מחנות רגילה */}
            {num === 2 && (numBusiness === 1 || numBusiness === 2 || numBusiness === 3) && <div>
                <div className='buton' onClick={() => { setNum(num - 1); window.scrollTo(0, 0); }} style={{ position: 'absolute', top: '-5vh', right: '-40%', width: '8vh', zIndex: '5' }}><ButtonRed text='<--'></ButtonRed></div>
                <div className='titl' style={{ marginTop: '3%', fontSize: '120%', marginBottom: '7%' }}>פרטים נוספים</div>

                <textarea style={{ width: '68%', height: '300px', marginTop: '2%', padding: '0.5%', display: 'block', marginLeft: 'auto', marginRight: 'auto', maxHeight: '100px', borderRadius: '10px 10px 10px 10px' }} placeholder='*תאר את העסק' value={newBusiness[numBusiness].description} onChange={(e) => handleChange(e, 'description', 800)}></textarea>
                <p style={{ float: 'left', marginLeft: '17%', fontSize: '90%' }}>*תיאור העסק יכול להכיל עד 800 תווים</p>

                {numBusiness !== 3 && <><textarea style={{ width: '68%', height: '80px', marginTop: '6%', padding: '0.5%', display: 'block', marginLeft: 'auto', marginRight: 'auto', maxHeight: '100px', borderRadius: '10px 10px 10px 10px' }} placeholder='*יתרון מיוחד הקים בעסק זה' value={newBusiness[numBusiness].adventage} onChange={(e) => handleChange(e, 'adventage', 80)}></textarea>
                    <p style={{ float: 'left', marginLeft: '17%', fontSize: '90%' }}>*יתרון יכול להכיל עד 80 תווים</p></>}

                {cc === "4" && <div>
                    <div style={{ marginTop: '10%' }}>*קובץ תמונה המכיל את תעודת הכשרות / כשרות המוצרים</div><br></br>
                    <input type="file" id="fileElem1" accept="image/*" onChange={changePics} multiple style={{ display: 'none' }} />
                    <input type="button" className="submit1 input" id="fileSelect1" onClick={clickPics} value={kosherLetters.length === 0 ? "העלה תמונה" : "הוסף תמונה"} />
                    {kosherLetters.length > 0 && <div title="מחק תמונות" style={{ userSelect: 'none' }} className="submit1 input in2" id="fileSelect2" onClick={() => { setOpenPic(true) }}><DeleteOutlined /></div>}
                    <div id="fileList1" style={{ marginBottom: '7%' }}> {kosherLetters.length === 0 && <p>עדיין לא נבחרה תמונה!</p>} {kosherLetters.map((x, i) => (<img loading='lazy' key={i} src={x.song1 instanceof Blob || x.song1 instanceof File ? URL.createObjectURL(x.song1) : encodeURI(baseImageUrl + x.song1)} width={280}></img>))} </div>
                    <p style={{ marginTop: '-60px', float: 'right', marginRight: '17%', fontSize: '90%' }}>*עד 3 תעודות כשרות</p>

                </div>}

                <div style={{ marginBottom: '20%' }}></div>
                {questiuons?.length <= 0 && <span style={{ cursor: `${think ? 'wait' : 'pointer'}` }}><button type="submit" className='buton' style={{ pointerEvents: `${think ? 'none' : 'auto'}`, width: '20%', marginTop: '-10%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto', minWidth: '20vh', zIndex: '5' }}><ButtonRed text={isAddBus ? 'המשך    -->' : 'סיים עריכה'}></ButtonRed></button></span>}
                {questiuons?.length > 0 && <div className='buton' onClick={() => {
                    if (newBusiness[numBusiness].adventage === '' || newBusiness[numBusiness].description === '') { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                    else if (!newBusiness[numBusiness].adventage.trim() || !newBusiness[numBusiness].description.trim()) { setErrorMessage('לא תקין למלא רק רווחים בשדה חובה'); }
                    else if (cc === "4" && (kosherLetters.length <= 0 || newBusiness[numBusiness].adventage === '' || newBusiness[numBusiness].description === '')) { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                    else { setErrorMessage(''); setNum(5) }
                }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%', minWidth: '20vh', zIndex: '5' }}><ButtonRed text='המשך    -->'></ButtonRed></div>}
            </div>}

            {/* המשך מילוי הפרטים לחנות רגילה */}
            {num === 2 && numBusiness === 0 && <div>
                <div className='buton' onClick={() => { setNum(num - 1); window.scrollTo(0, 0); }} style={{ position: 'absolute', top: '-5vh', right: '-40%', width: '8vh', zIndex: '5' }}><ButtonRed text='<--'></ButtonRed></div>

                <textarea style={{ width: '68%', height: '300px', marginTop: '8%', padding: '0.5%', display: 'block', marginLeft: 'auto', marginRight: 'auto', maxHeight: '100px', borderRadius: '10px 10px 10px 10px' }} placeholder='*תאר את העסק' value={newBusiness[numBusiness].description} onChange={(e) => handleChange(e, 'description', 800)}></textarea>
                <p style={{ float: 'left', marginLeft: '17%', fontSize: '90%' }}>*תיאור העסק יכול להכיל עד 800 תווים</p>

                <textarea style={{ width: '68%', height: '80px', marginTop: '2%', padding: '0.5%', display: 'block', marginLeft: 'auto', marginRight: 'auto', maxHeight: '100px', borderRadius: '10px 10px 10px 10px' }} placeholder='*יתרון מיוחד הקים בעסק זה' value={newBusiness[numBusiness].adventage} onChange={(e) => handleChange(e, 'adventage', 80)}></textarea>
                <p style={{ float: 'left', marginLeft: '17%', fontSize: '90%' }}>*יתרון יכול להכיל עד 80 תווים</p>

                <textarea style={{ width: '68%', height: '80px', marginTop: '2%', padding: '0.5%', display: 'block', marginLeft: 'auto', marginRight: 'auto', maxHeight: '100px', borderRadius: '10px 10px 10px 10px' }} placeholder='בונוס לחתנים וכלות הבאים דרך קול שמחה' value={newBusiness[numBusiness].bonus} onChange={(e) => handleChange(e, 'bonus', 100)}></textarea>
                <p style={{ float: 'left', marginLeft: '17%', fontSize: '90%' }}>*בונוס יכול להכיל עד 100 תווים</p>

                <div style={{ marginBottom: '20%' }}></div>
                {questiuons?.length <= 0 && <span style={{ cursor: `${think ? 'wait' : 'pointer'}` }}><button type="submit" className='buton' style={{ pointerEvents: `${think ? 'none' : 'auto'}`, width: '20%', marginTop: '-10%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto', minWidth: '20vh', zIndex: '5' }}><ButtonRed text={isAddBus ? 'המשך    -->' : 'סיים עריכה'}></ButtonRed></button></span>}
                {questiuons?.length > 0 && <div className='buton' onClick={() => {
                    if (newBusiness[numBusiness].adventage === '' || newBusiness[numBusiness].description === '') { setErrorMessage('השדות המסומנים בכוכבית הן שדות חובה'); }
                    else if (!newBusiness[numBusiness].adventage.trim() || !newBusiness[numBusiness].description.trim()) { setErrorMessage('לא תקין למלא רק רווחים בשדה חובה'); }
                    else { setErrorMessage(''); setNum(5) }
                }} style={{ position: 'absolute', bottom: '10vh', left: '10%', width: '20%', minWidth: '20vh', zIndex: '5' }}><ButtonRed text='המשך    -->'></ButtonRed></div>}
            </div>}

            {/* מסך מילוי תשובות לשאלות נפוצות */}
            {num === 5 && <div>
                <div className='buton' onClick={() => { setNum(2) }} style={{ position: 'absolute', top: '-5vh', right: '-40%', width: '8vh', zIndex: '5' }}><ButtonRed text='<--'></ButtonRed></div>
                <p className="titl" style={{ marginTop: '8%', fontSize: '120%', marginBottom: '2%' }}>שאלות נפוצות לעסק השייך לקטגורית {categoryName}</p>
                <p style={{ marginBottom: '8%' }}>מומלץ למלא את התשובות, התשובות הן על פי העסק האישי שלכם: {newBusiness[numBusiness].name}</p>
                {questiuons && <div>{questiuons.map((x, i) => (
                    <div key={i}><div className='questionCon' style={{ height: '7vh', lineHeight: '7vh' }}>{x.question1}</div>
                        <div className='animate__animated animate__fadeInDownBig'>
                            <input className='inpu' style={{ width: '100%', height: '7vh', border: 'none', marginTop: '1px', marginBottom: '1px' }} autoComplete="on" type="text" value={answers[i].answer1} onChange={(e) => {
                                if (e.target.value.length <= 200) {
                                    const updated = answers.map((item, index) => {
                                        if (index === i) {
                                            if (item.questionId !== '') {
                                                return { ...item, answer1: e.target.value }
                                            } else {
                                                return { ...item, answer1: e.target.value, bussinessId: s.id, categoryCode: ccToAllTables, questionId: x.id }
                                            };
                                        } return item;
                                    }); setAnswers(updated);
                                }
                            }}></input>
                        </div>
                    </div>
                ))}</div>}

                <div id='submit30' style={{ minWidth: '45vh', width: '50%' }} onClick={() => { setAddQuestion(true) }}><ButtonRed text={'האם יש שאלה שתרצו שנוסיף בתחום שלכם?'}></ButtonRed></div>

                <div style={{ marginBottom: '15%' }}></div>
                <span style={{ cursor: `${think ? 'wait' : 'pointer'}` }}><button type="submit" className='buton' style={{ pointerEvents: `${think ? 'none' : 'auto'}`, width: '20%', marginTop: '-10%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto', minWidth: '20vh', zIndex: '5' }}><ButtonRed text={isAddBus ? 'המשך    -->' : 'סיים עריכה'}></ButtonRed></button></span>
            </div>}


            {/* הוספת שאלה שלקוחות מרבים לשאול אתכם */}
            {addQuestion && <div id='modal2'>
                <div className='animate__animated animate__zoomIn'>
                    <CloseOutlined className='close2' onClick={() => { setAddQuestion(false); }} />
                    <div className='glow'>
                        <div className='titl' style={{ marginTop: '5%', marginBottom: '3%' }}>הוספת שאלה שלקוחות מרבים לשאול</div>

                        <input className='input20 ii ques' style={{ width: '60%', marginBottom: '2%' }} placeholder="הכנס שאלה 1" type="text" />
                        <input className='input20 ii ques' style={{ width: '60%', marginBottom: '2%' }} placeholder="הכנס שאלה 2" type="text" />
                        <input className='input20 ii ques' style={{ width: '60%', marginBottom: '2%' }} placeholder="הכנס שאלה 3" type="text" />

                        <span style={{ cursor: `${think ? 'wait' : 'pointer'}` }}><div className='buton' onClick={() => {
                            setThink(true);
                            var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 23px;">קול שמחה - עסק הוסיף שאלה נפוצה</p></div><br/> <br/>שלום לקול שמחה, <br/> עסק הוסיף שאלה שלקוחות מרבים לשאול בתחום שלו. <br/><b>תחום: </b>${cc}<br/><b>שאלה 1: </b>${document.getElementsByClassName('ques')[0].value}<br/><b>שאלה 2: </b>${document.getElementsByClassName('ques')[1].value}<br/><b>שאלה 3: </b>${document.getElementsByClassName('ques')[2].value}<br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
                            SendEmailToBussiness({ name: "", emailTo: 'K3137663@gmail.com', emailFrom: newBusiness[numBusiness].mail, subject: `הוספת שאלה נפוצה`, message: mes }).then(() => { setAddQuestion(false); setSend1('נשלח בהצלחה! השאלות יתעדכנו בעז"ה בהמשך'); setTimeout(() => { setSend1(false) }, 40 * 100); }).catch((error) => { console.error(error); });
                            setThink(false);
                        }} style={{ width: '20%', marginRight: 'auto', marginLeft: 'auto', pointerEvents: `${think ? 'none' : 'auto'}` }}><ButtonRed text='שלח'></ButtonRed></div></span>
                    </div>
                </div>
            </div>}

            {errorMessage && <p><img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}
        </form>

        {send1 && <div className="send animate__animated animate__bounceInUp">
            <div style={{ lineHeight: '8vh', textAlign: 'center', fontWeight: '400' }}>{send1}</div>
        </div>}

        {openPic && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => { setOpenPic(false); }} style={{ cursor: 'pointer', position: 'absolute', left: '5%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '95%', height: '95vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '2.5%', marginTop: '2.5vh' }}>
                    <div id='fileList12' style={{ marginTop: '5%', width: '80%', marginRight: 'auto', marginLeft: 'auto', maxHeight: '45vh', overflow: 'auto' }}>{kosherLetters.length === 0 && <p>עדיין לא נבחרה תמונה!</p>} {kosherLetters.map((x, i) => (<img loading='lazy' key={i} src={x.song1 instanceof Blob || x.song1 instanceof File ? URL.createObjectURL(x.song1) : encodeURI(baseImageUrl + x.song1)} className="remove" onClick={() => { if (!delPic.includes(x.song1)) { setDelPic([...delPic, x.song1]) } }} width={280}></img>))}</div>

                    <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => { chooseDelPic() }}><ButtonRed text='אישור'></ButtonRed></div>
                    <div id='fileList13' style={{ marginTop: '2%', maxWidth: '100%', maxHeight: '20vh', overflowX: 'auto', textOrientation: 'landscape' }}>{delPic.map((x, i) => (<img loading='lazy' key={i} src={x instanceof Blob || x instanceof File ? URL.createObjectURL(x) : encodeURI(baseImageUrl + x)} width={280}></img>))}</div>
                </div>
            </div>
        </div>}

        {notUpdateInPhone && <div id='modal' style={{ backgroundImage: `url(${pic2})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%' }}><div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <div className='glow' style={{ width: '40%', position: 'relative' }}>
                    <CloseOutlined className='close2' onClick={() => { n(-1) }} style={{ cursor: 'pointer', position: 'absolute', left: '5%', marginTop: '7%' }} />
                    <p className='titl' style={{ backgroundColor: '#820909', color: 'white', marginTop: '0', paddingTop: '6%', paddingBottom: '6%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>השינויים שהזנתם התעדכנו באתר בהצלחה!</p>
                    <p className='titl' style={{ marginTop: '5%', width: '90%', marginRight: '5%', fontSize: '97%' }}>שימו לב, הנתונים לא מתעדכנים אוטומטית<br />במערכת הטלפונית שלכם שמספרה:<br /> <span style={{ direction: 'ltr' }}>02-3137663 {s.numberToCall ? `> ${s.numberToCall}` : ''}</span>.<br /><br />ניתן לעדכן דרך מזכירות קול שמחה, <br />בתוספת תשלום של 150 ₪, עד שעה עבודה.</p>
                    <div id='submit30' style={{ width: 'max-content' }} onClick={() => { setCreateChanges(true) }}><ButtonRed text={'לשינויים במערכת הטלפונית'}></ButtonRed></div>
                </div>
            </div>
        </div></div>}

        {createChanges && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <div className='glow' style={{ position: 'relative' }}>
                    <CloseOutlined className='close2' onClick={() => { setCreateChanges(false) }} style={{ cursor: 'pointer', position: 'absolute', left: '3%', marginTop: '3%' }} />
                    <div className='titl' style={{ marginTop: '5%', marginBottom: '3%' }}>השינויים לביצוע במערכת הטלפונית:</div>
                    <textarea id="createChangesTextarea" style={{ width: '60%', marginBottom: '2%' }} placeholder="מהם השינויים?" type="text" />
                    <input id="createChangesInput" style={{ width: '60%', marginBottom: '2%' }} type="file" />
                    <div className='buton' onClick={() => {
                        var mes = `<div style="font-family:'Gill Sans MT', Calibri; font-size: 23px ; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 23px;">עסק רוצה לבצע שינוי במערכת הטלפונית</p></div><br/> <br/><b>שם העסק: </b> ${newBusiness[numBusiness].name}.<br/><b>מספר טלפון: </b> ${newBusiness[numBusiness].phone}.<br/><b>כתובת מייל: </b> ${newBusiness[numBusiness].mail}.<br/> <br/>תוכן הבקשה:<br/> ${document.getElementById('createChangesTextarea').value}<br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
                        const formData = new FormData();
                        formData.append('name', "");
                        formData.append('emailFrom', newBusiness[numBusiness].mail);
                        formData.append('subject', `עסק רוצה לבצע שינוי במערכת הטלפונית`);
                        formData.append('message', mes);
                        formData.append('attachment', document.getElementById('createChangesInput').files[0]); // הוספת הקובץ
                        SendEmailChangeInPhone(formData).then(() => {
                            n(-1)
                        }).catch((error) => { console.error(error); });

                    }} style={{ width: '20%', marginRight: 'auto', marginLeft: 'auto' }}><ButtonRed text='שלח'></ButtonRed></div>
                </div>
            </div>
        </div>}

        {noPrice && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <div className='glow' style={{ width: '100vh', maxWidth: '90%', height: 'auto', position: 'relative', marginRight: 'auto', marginLeft: 'auto' }}>
                    <CloseOutlined className='close2' onClick={() => { setNoPrice(false) }} style={{ cursor: 'pointer', position: 'absolute', left: '5%', marginTop: '7%' }} />
                    <p className='titl' style={{ backgroundColor: '#820909', color: 'white', marginTop: '0', paddingTop: '6%', paddingBottom: '6%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '5vh' }}>שימו לב</p>
                    <p className='titl' style={{ marginTop: '5%', width: '90%', marginRight: '5%', fontSize: '97%' }}>הסטטיסטיקה מוכיחה כי עסקים שמפרסמים מחיר, <br />מוכרים 70% יותר מעסקים שאינם מפרסמים מחיר.</p>
                    <div style={{ marginBottom: '50px' }}>
                        <div id='submit30' style={{ width: '30vh', maxWidth: '100%', display: 'inline-block', marginTop: '0' }} onClick={() => { setNoPrice(false) }}><ButtonRed text={'חזור להזין מחיר'}></ButtonRed></div>
                        <div id='submit30' style={{ width: '30vh', maxWidth: '100%', display: 'inline-block', marginTop: '0' }} onClick={() => { setErrorMessage(''); setNum(num + 1); window.scrollTo(0, 0); setNoPrice(false) }}><ButtonRed text={'המשך ללא מחיר'}></ButtonRed></div>
                    </div>
                </div>
            </div>
        </div>}

    </div>
}

export default EditBussinessDetails