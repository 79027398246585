import axios from 'axios';

// IIS הרצה דרך השרת
const baseUrl = 'https://kolsimcha.co.il/myapi/api';

// הרצה דרך ויזואל סטודיו בשרת
// const baseUrl = 'https://kolsimcha.co.il:44339/api';

// הרצה במחשב במשרד
// const baseUrl = 'https://localhost:44339/api';
// const baseUrl = 'https://api.kolsimcha.co.il/swagger/index.html/api';
// const baseUrl = 'https://APIkol55.azurewebsites.net/api';

//-----------------------------------------------------------------------------------
//                                    התחבר / הירשם
//-----------------------------------------------------------------------------------

// export const getUser = (tz, pass) => {
//     return axios.get(`${baseUrl}/User/GetUser?tz=${tz}&pass=${pass}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
// };

export const getUserByTz = (tz) => {
    return axios.get(`${baseUrl}/User/getUserByTz?tz=${tz}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

// export const GetUserById = (id) => {
//     return axios.get(`${baseUrl}/User/GetUserById?id=${id}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
// };

// export const addTwoUsers = (users) => {
//     return axios.post(`${baseUrl}/User/AddTwoUsers`, users).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
// };

export const AddNewUser = (user) => {
    return axios.post(`${baseUrl}/User/AddNewUser`, user).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddSecondUser = (tzOldUser, passOldUser, user) => {
    return axios.post(`${baseUrl}/User/AddSecondUser?tzOldUser=${tzOldUser}&passOldUser=${passOldUser}`, user).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const ChangeUserPass = (userId, newPass) => {
    return axios.put(`${baseUrl}/User/ChangeUserPass?userId=${userId}&newPass=${newPass}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const ChangeUserReminders = (userId, reminder) => {
    return axios.put(`${baseUrl}/User/ChangeUserReminders?userId=${userId}&reminder=${reminder}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const ChangeUserDates = (userId, newErusinDate, newWeddingDate) => {
    return axios.put(`${baseUrl}/User/ChangeUserDates?userId=${userId}&newErusinDate=${newErusinDate}&newWeddingDate=${newWeddingDate}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const UpdateUser = (userId, user) => {
    return axios.put(`${baseUrl}/User/UpdateUser?userId=${userId}`, user).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};
//-----------------------------------------------------------------------------------
//                                    תפריט
//-----------------------------------------------------------------------------------
export const getAllBigMenu = () => {
    return axios.get(`${baseUrl}/TitleTitle/GetAllBigMenu`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetAllIcons = () => {
    return axios.get(`${baseUrl}/Menu/GetAllIcons`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetAllCategoryWithDevition = () => {
    return axios.get(`${baseUrl}/Menu/GetAllCategoryWithDevition`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetBussinessForCategory = (categoryCode) => {
    return axios.get(`${baseUrl}/Categories/GetBussinessForCategory?categoryCode=${categoryCode}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetAllLogo = () => {
    return axios.get(`${baseUrl}/Bussiness/GetAllLogo`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

//-----------------------------------------------------------------------------------
//                                    עסק מסוים
//-----------------------------------------------------------------------------------
export const GetBussinessEnter = (mail, code) => {
    return axios.get(`${baseUrl}/Bussiness/GetBussinessEnter?mail=${mail}&code=${code}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetBussinessData = (bussinessId, categoryCode) => {
    if (typeof categoryCode === 'string')
        categoryCode = 0;
    return axios.get(`${baseUrl}/Bussiness/GetBussinessData?bussinessId=${bussinessId}&categoryCode=${categoryCode}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddNewOption = (newOption) => {
    return axios.post(`${baseUrl}/Options/AddNewOption`, newOption).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

const convertObjToFormData = (obj, formData = new FormData(), parentKey = "") => {
    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        const formKey = parentKey ? `${parentKey}.${key}` : key;
        if (Array.isArray(value)) {
            if (value.length === 0) { formData.append(formKey, 'empty'); return; }
            else value.forEach((item, index) => {// עבור כל פריט במערך, נוסיף אותו ל-FormData עם אינדקס
                const arrayKey = `${formKey}[${index}]`;
                if (typeof item === "object" && item !== null)
                    convertObjToFormData(item, formData, arrayKey); // אם הפריט במערך הוא אובייקט, נקרא לפונקציה ברקורסיה
                else formData.append(arrayKey, item === undefined && key === 'bussinessId' ? 0 : item); // אחרת, נוסיף את הפריט כערך רגיל
            });
        }
        // אם הערך הוא Blob/File, נוסיף אותו כקובץ
        else if (value instanceof Blob || value instanceof File)
            formData.append(formKey, value, value.name || "file");
        // אם הערך הוא אובייקט, נוסיף אותו ברקורסיה
        else if (typeof value === "object" && value !== null)
            convertObjToFormData(value, formData, formKey);
        else formData.append(formKey, value === undefined && key === 'bussinessId' ? 0 : value);
    });
    return formData;
};

export const AddBussinessWall = (newB) => {
    const formData = new FormData();
    convertObjToFormData(newB, formData, 'bussiness');
    return axios.post(`${baseUrl}/Bussiness/AddBussinessWall`, formData).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error });
};

export const AddBussinessShop = (newB) => {
    const formData = new FormData();
    convertObjToFormData(newB, formData, 'bussiness');
    return axios.post(`${baseUrl}/Bussiness/AddBussinessShop`, formData).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

//export const AddBussinessShop = (newB) => {
//    const formData = new FormData();
//    formData.append("bussiness", newB.bussiness.logo);
//    return axios.post(`${baseUrl}/Bussiness/AddBussinessShop`, formData).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
//};

export const AddBussinessCatering = (newB) => {
    const formData = new FormData();
    convertObjToFormData(newB, formData, 'bussiness');
    return axios.post(`${baseUrl}/Bussiness/AddBussinessCatering`, formData).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddBussinessSong = (newB) => {
    const formData = new FormData();
    convertObjToFormData(newB, formData, 'bussiness');
    return axios.post(`${baseUrl}/Bussiness/AddBussinessSong`, formData).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditBussinessShopDetails = (id, name, description, answers, adventage, bonus, openingHours, address, phone, mail, city, kishurLahatar, keywords) => {
    const payload = { id, name, description, answers, adventage, bonus, openingHours, address, phone, mail, city, kishurLahatar, keywords };
    return axios.post(`${baseUrl}/Bussiness/EditBussinessShopDetails`, payload).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditBussinessWallDetails = (id, name, description, answers, adventage, place, size, price, RangeDishes, RangePriceToDishes, phone, mail, city, includePrice, kishurLahatar, keywords) => {
    const payload = { id, name, description, answers, adventage, place, size, price, RangeDishes, RangePriceToDishes, phone, mail, city, includePrice, kishurLahatar, keywords }
    return axios.post(`${baseUrl}/Bussiness/EditBussinessWallDetails`, payload).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

// export const EditBussinessCateringDetails = (allFormData, id, name, description, adventage, rangeDishes, rangeDishesKabalat, rangePrice, phone, mail, area, kishurLahatar, keywords) => {
//     const formData = new FormData();
//     if (allFormData.answersDTO.length === 0) formData.append('answersDTO', null);
//     else allFormData.answersDTO.forEach((a) => { formData.append('answersDTO', a); });
//     if (allFormData.songsUrl.length === 0) formData.append('songsUrl', null);
//     else allFormData.songsUrl.forEach((a) => { formData.append('songsUrl', a); });
//     formData.append('priceList', allFormData.priceList);
//     allFormData.songsToAdd.forEach((file) => { // הוספת המערך של הקבצים
//         formData.append('songsToAdd', file);
//     });
//     return axios.post(`${baseUrl}/Bussiness/EditBussinessCateringDetails?id=${id}&name=${name}&description=${description}&adventage=${adventage}&rangeDishes=${rangeDishes}&rangeDishesKabalat=${rangeDishesKabalat}&rangePrice=${rangePrice}&area=${area}&phone=${phone}&mail=${mail}&kishurLahatar=${kishurLahatar}&keywords=${keywords}`, formData).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
// };
export const EditBussinessCateringDetails = (allFormData) => {
    const formData = new FormData();
    // הוספת מערך התשובות
    if (allFormData.answersDTO.length === 0) formData.append('answers', null);
    else allFormData.answersDTO.forEach((a, index) => { formData.append(`answers[${index}]`, JSON.stringify(a)); });
    // הוספת הקבצים
    allFormData.songsToAdd.forEach((file) => { formData.append('SongsToAdd', file); });
    // הוספת כתובות השירים
    if (allFormData.songsUrl.length === 0) formData.append('SongsUrl', null);
    else allFormData.songsUrl.forEach((url) => { formData.append('SongsUrl', url); });
    // הוספת יתר הפרמטרים
    formData.append('PriceList', allFormData.priceList);
    formData.append('Id', allFormData.id);
    formData.append('Name', allFormData.name);
    formData.append('Description', allFormData.description);
    formData.append('Adventage', allFormData.adventage);
    formData.append('RangeDishes', allFormData.rangeDishes);
    formData.append('RangeDishesKabalat', allFormData.rangeDishesKabalat || '');
    formData.append('RangePrice', allFormData.rangePrice);
    formData.append('Area', allFormData.area || '');
    formData.append('Phone', allFormData.phone);
    formData.append('Mail', allFormData.mail);
    formData.append('KishurLahatar', allFormData.kishurLahatar || '');
    formData.append('Keywords', allFormData.keywords || '');
    // שליחת הבקשה לשרת
    return axios.post(`${baseUrl}/Bussiness/EditBussinessCateringDetails`, formData).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditBussinessSongsDetails = (id, name, description, answers, includePrice, signon, price, priceAdd, phone, mail, area, kishurLahatar, keywords) => {
    const payload = { id, name, description, answers, includePrice, signon, price: price !== undefined && price !== null ? String(price) : "", priceAdd, phone, mail, area, kishurLahatar, keywords }
    return axios.post(`${baseUrl}/Bussiness/EditBussinessSongsDetails`, payload).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditImages = (allImages, bussinessId, cc) => {
    const formData = new FormData();
    allImages.images.forEach((a) => { // הוספת המערך של המחרוזות
        formData.append('images', a);
    });
    formData.append('logo', allImages.logo);
    formData.append('mainImage', allImages.mainImage);
    allImages.imagesToAdd.forEach((file) => { // הוספת המערך של הקבצים
        formData.append('imagesToAdd', file);
    });
    return axios.post(`${baseUrl}/Bussiness/EditImages?bussinessId=${bussinessId}&cc=${cc}`, formData).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditCategoryCode = (bussinessId, cc, newCC) => {
    return axios.post(`${baseUrl}/Bussiness/EditCategoryCode?bussinessId=${bussinessId}&cc=${cc}&newCC=${newCC}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditCatchDates = (dates, bussinessId, cc) => {
    return axios.post(`${baseUrl}/Bussiness/EditCatchDates?bussinessId=${bussinessId}&cc=${cc}`, dates).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditProducts = (imagesToAdd, lotOfItems, bussinessId, cc, phoneToCall, priceShipping, notesShipping) => {
    const formData = new FormData();
    imagesToAdd.forEach((file) => { // הוספת המערך של הקבצים
        formData.append('imagesToAdd', file);
    });
    // lotOfItems.forEach((a) => { 
    //     formData.append('lotOfItemsDTO', a);
    // });
    lotOfItems.forEach((item, index) => { // הוספת המערך של האובייקטים
        Object.keys(item).forEach((key) => {
            formData.append(`lotOfItemsDTO[${index}].${key}`, item[key]);
        });
    });
    formData.append('bussinessId', bussinessId);
    formData.append('cc', cc);
    formData.append('phoneToCall', phoneToCall);
    formData.append('priceShipping', priceShipping);
    formData.append('notesShipping', notesShipping);
    return axios.post(`${baseUrl}/Bussiness/EditProducts`, formData).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditSongs = (songsToAdd, songs, bussinessId, cc) => {
    const formData = new FormData();
    songsToAdd.forEach((file) => { // הוספת המערך של הקבצים
        formData.append('songsToAdd', file);
    });
    songs.forEach((item, index) => { // הוספת המערך של האובייקטים
        Object.keys(item).forEach((key) => {
            formData.append(`songsDTO[${index}].${key}`, item[key]);
        });
    });
    formData.append('bussinessId', bussinessId);
    formData.append('cc', cc);
    return axios.post(`${baseUrl}/Bussiness/EditSongs`, formData).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetOneBussinessWhisData = (categoryCode, bussinessId) => {
    return axios.get(`${baseUrl}/Bussiness/GetOneBussinessWhisData?categoryCode=${categoryCode}&bussinessId=${bussinessId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetQuestions = (categoryCode) => {
    return axios.get(`${baseUrl}/Bussiness/GetQuestions?categoryCode=${categoryCode}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetSpecialFields = (categoryCode) => {
    return axios.get(`${baseUrl}/Bussiness/GetSpecialFields?categoryCode=${categoryCode}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditQuestion = (id, question) => {
    return axios.post(`${baseUrl}/Bussiness/EditQuestion?id=${id}&question=${question}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DelQuestion = (id) => {
    return axios.delete(`${baseUrl}/Bussiness/DelQuestion?id=${id}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddQuestion = (question) => {
    return axios.put(`${baseUrl}/Bussiness/AddQuestion`, question).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetAllCategoriesOrDevition = () => {
    return axios.get(`${baseUrl}/Categories/GetAllCategoriesOrDevition`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetCategories = () => {
    return axios.get(`${baseUrl}/Categories/GetCategories`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddCatchDate = (userId, date) => {
    return axios.post(`${baseUrl}/Bussiness/AddCatchDate?userId=${userId}`, date).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const FinishBuy = (finishBuy) => {
    return axios.post(`${baseUrl}/Bussiness/FinishBuy`, finishBuy).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const FinishBuyDate = (idShopCart, FinishBuyDate) => {
    return axios.post(`${baseUrl}/Bussiness/FinishBuyDate?idShopCart=${idShopCart}`, FinishBuyDate).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetPayId = (categoryId) => {
    return axios.get(`${baseUrl}/Categories/GetPayId?categoryId=${categoryId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const ChangePassBussiness = (codeCategory, bussinessId, newCode) => {
    return axios.put(`${baseUrl}/Bussiness/ChangePassBussiness?codeCategory=${codeCategory}&bussinessId=${bussinessId}&newCode=${newCode}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetOneBussiness = (codeCategory, bussinessId) => {
    return axios.get(`${baseUrl}/Bussiness/GetOneBussiness?categoryCode=${codeCategory}&bussinessId=${bussinessId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddPhoneToCall = (bussinessId, categoryCode, phone) => {
    return axios.post(`${baseUrl}/Bussiness/AddPhoneToCall?bussinessId=${bussinessId}&categoryCode=${categoryCode}&phone=${phone}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DelOption = (optionId) => {
    return axios.delete(`${baseUrl}/Bussiness/DelOption?optionId=${optionId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditOption = (optionId, name, description) => {
    return axios.post(`${baseUrl}/Options/EditOption?optionId=${optionId}&name=${name}&description=${description}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

// export const GetNameCategoryById = (categoryId) => {
//     return axios.get(`${baseUrl}/Categories/GetNameCategoryById?categoryId=${categoryId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
// };
//-----------------------------------------------------------------------------------
//                                    ארגונית אישית
//-----------------------------------------------------------------------------------
export const GetComesAddress = (userId) => {
    return axios.get(`${baseUrl}/User/GetComesAddress?userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetPays = (userId) => {
    return axios.get(`${baseUrl}/User/GetPays?userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetPaysInit = (chatanOrKala) => {
    return axios.get(`${baseUrl}/User/GetPaysInit?chatanOrKala=${chatanOrKala}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetFinallTasks = (userId) => {
    return axios.get(`${baseUrl}/User/GetFinallTasks?userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetFinallTasksToManagger = (userId) => {
    return axios.get(`${baseUrl}/User/GetFinallTasksToManagger?userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetShopBussiness = (userId) => {
    return axios.get(`${baseUrl}/User/GetShopBussiness?userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddPersonalTask = (dateE, dateW, personalTask) => {
    return axios.post(`${baseUrl}/User/AddPersonalTask?dateE=${dateE}&dateW=${dateW}`, personalTask).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

// export const AddShopping = (shopping) => {
//     return axios.post(`${baseUrl}/User/AddShopping`, shopping).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
// };

export const DoTask = (did) => {
    return axios.post(`${baseUrl}/User/DoTask`, did).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DelPersonalTask = (id) => {
    return axios.delete(`${baseUrl}/User/DelPersonalTask?dt=${id}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const NotDoTask = (task) => {
    return axios.post(`${baseUrl}/User/NotDoTask`, task).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DelGlobalTask = (taskId, userId) => {
    return axios.delete(`${baseUrl}/User/DelGlobalTask?taskId=${taskId}&userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddPersonalPay = (pay) => {
    return axios.post(`${baseUrl}/User/AddPersonalPay`, pay).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DelPersonalPay = (ppId, userId) => {
    return axios.delete(`${baseUrl}/User/DelPersonalPay?ppId=${ppId}&userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddPersonalPaid = (pay) => {
    return axios.post(`${baseUrl}/User/AddPersonalPaid`, pay).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const UpdatePersonalPaid = (paysId, pp, userId) => {
    return axios.put(`${baseUrl}/User/UpdatePersonalPaid?paysId=${paysId}&pp=${pp}&userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetpersonalPriceIdBy = (paysId, userId) => {
    return axios.get(`${baseUrl}/User/GetpersonalPriceIdBy?paysId=${paysId}&userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddAddress = (address) => {
    return axios.post(`${baseUrl}/User/AddAddress`, address).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const CopyAddress = (userId, address) => {
    return axios.post(`${baseUrl}/User/CopyAddress?userId=${userId}`, address).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DelAddress = (id, userId) => {
    return axios.delete(`${baseUrl}/User/DelAddress?id=${id}&userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const UpdateAddress = (id, address) => {
    return axios.put(`${baseUrl}/User/UpdateAddress?id=${id}`, address).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const ChangeReminders = (userId, reminders) => {
    return axios.put(`${baseUrl}/User/ChangeReminders?userId=${userId}&reminders=${reminders}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddShoppingCart = (lotOfItemsId, shopping) => {
    return axios.post(`${baseUrl}/Bussiness/AddShoppingCart?lotOfItemsId=${lotOfItemsId}`, shopping).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetShopCartBussiness = (userId) => {
    return axios.get(`${baseUrl}/User/GetShopCartBussiness?userId=${userId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DelOneFromShopCart = (idShopCart) => {
    return axios.get(`${baseUrl}/User/DelOneFromShopCart?idShopCart=${idShopCart}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

//-----------------------------------------------------------------------------------
//                                    מנהל מערכת
//-----------------------------------------------------------------------------------
export const updateCategory = (pic, icon, categoryId, category) => {
    const formData = new FormData();
    formData.append("pic", pic);
    formData.append("icon", icon);
    formData.append("categoryId", categoryId);
    formData.append("category.code", category.code);
    formData.append("category.name", category.name);
    formData.append("category.titleId", category.titleId);
    formData.append("category.pic", category.pic);
    formData.append("category.icon", category.icon);
    return axios.put(`${baseUrl}/Categories/updateCategory`, formData).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const deleteCategory = (categoryId) => {
    return axios.delete(`${baseUrl}/Categories/deleteCategory?categoryId=${categoryId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetBussinessToManagger = () => {
    return axios.get(`${baseUrl}/Bussiness/GetBussinessToManagger`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetBussinessToManaggerStatistic = () => {
    return axios.get(`${baseUrl}/Bussiness/GetBussinessToManaggerStatistic`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetAllOptions = () => {
    return axios.get(`${baseUrl}/Bussiness/GetAllOptions`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetAllPays = () => {
    return axios.get(`${baseUrl}/User/GetAllPays`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditPayManagger = (pay) => {
    return axios.post(`${baseUrl}/User/EditPayManagger`, pay).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddPayManagger = (pay) => {
    return axios.put(`${baseUrl}/User/AddPayManagger`, pay).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DeletePayManagger = (payId) => {
    return axios.delete(`${baseUrl}/User/DeletePayManagger?payId=${payId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditTaskManagger = (task) => {
    return axios.post(`${baseUrl}/User/EditTaskManagger`, task).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditTaskDetailManagger = (id, name) => {
    return axios.post(`${baseUrl}/User/EditTaskDetailManagger?id=${id}&name=${name}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddTaskDetailManagger = (taskId, name) => {
    return axios.put(`${baseUrl}/User/AddTaskDetailManagger?taskId=${taskId}&name=${name}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DelTaskDetailManagger = (id) => {
    return axios.delete(`${baseUrl}/User/DelTaskDetailManagger?id=${id}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddTaskManagger = (task) => {
    return axios.put(`${baseUrl}/User/AddTaskManagger`, task).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const DeleteTaskManagger = (taskId) => {
    return axios.delete(`${baseUrl}/User/DeleteTaskManagger?taskId=${taskId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};
//-----------------------------------------------------------------------------------
//                                    מיונים וסינונים
//-----------------------------------------------------------------------------------
export const GetCatchDate = (date, categoryCode) => {
    return axios.get(`${baseUrl}/Bussiness/GetCatchDate?date=${date}&categoryCode=${categoryCode}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

//-----------------------------------------------------------------------------------
//                                    שליחת מיילים
//-----------------------------------------------------------------------------------
export const SendEmailNewBussiness = (body) => {
    return axios.post(`${baseUrl}/SendMail/SendEmailNewBussiness`, body).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const SendEmailToNewBussiness = (email, emailSubject) => {
    return axios.post(`${baseUrl}/SendMail/SendEmailToNewBussiness?emailTo=${email}&emailSubject=${emailSubject}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const SendEmailForNewUser = (body) => {
    return axios.post(`${baseUrl}/SendMail/SendEmailForNewUser`, body).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const SendEmailToChoozeDate = (body) => {
    return axios.post(`${baseUrl}/SendMail/SendEmailToChoozeDate`, body).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const SendEmailToBussiness = (body) => {
    return axios.post(`${baseUrl}/SendMail/SendEmailToBussiness`, body).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const SendEmailBusForgetPass = (mail) => {
    return axios.post(`${baseUrl}/SendMail/SendEmailBusForgetPass?emailTo=${mail}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const SendEmailChangeInPhone = (body) => {
    return axios.post(`${baseUrl}/SendMail/SendEmailChangeInPhone`, body).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const SendEmailWithListAtachmennt = (body) => {
    const formData = new FormData();
    formData.append('EmailFrom', body.emailFrom);
    formData.append('Subject', body.subject);
    formData.append('Message', body.message);
    body.attachments.forEach((file) => { // הוספת המערך של הקבצים
        formData.append('Attachments', file);
    });
    return axios.post(`${baseUrl}/SendMail/SendEmailWithListAtachmennt`, formData).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const SendEmailNewBusFillDetFromMail = (emailTo, emailSubject) => {
    return axios.post(`${baseUrl}/SendMail/SendEmailNewBusFillDetFromMail?emailTo=${emailTo}&emailSubject=${emailSubject}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};
//-----------------------------------------------------------------------------------
//                                    EditHowMany
//-----------------------------------------------------------------------------------
export const EditHowManySeeYou = (codeCategory, bussinessId) => {
    return axios.post(`${baseUrl}/Bussiness/EditHowManySeeYou?codeCategory=${codeCategory}&bussinessId=${bussinessId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditHowManyCall = (codeCategory, bussinessId, howManyCall) => {
    return axios.post(`${baseUrl}/Bussiness/EditHowManyCall?codeCategory=${codeCategory}&bussinessId=${bussinessId}&howManyCall=${howManyCall}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditHowManySendEmail = (codeCategory, bussinessId) => {
    return axios.post(`${baseUrl}/Bussiness/EditHowManySendEmail?codeCategory=${codeCategory}&bussinessId=${bussinessId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditNumToCall = (codeCategory, bussinessId, numToCall) => {
    return axios.post(`${baseUrl}/Bussiness/EditNumToCall?codeCategory=${codeCategory}&bussinessId=${bussinessId}&numToCall=${numToCall}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditPhoneToCall = (codeCategory, bussinessId, phoneToCall) => {
    return axios.post(`${baseUrl}/Bussiness/EditPhoneToCall?codeCategory=${codeCategory}&bussinessId=${bussinessId}&phoneToCall=${phoneToCall}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

//-----------------------------------------------------------------------------------
//                                    Mehorasim
//-----------------------------------------------------------------------------------
export const GetAllMehorasim = () => {
    return axios.get(`${baseUrl}/MehorasimControler/GetAllMehorasim`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const AddMehorasim = (mehorasim) => {
    return axios.put(`${baseUrl}/MehorasimControler/AddMehorasim`, mehorasim).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

// export const EditMehorasim = (mehorasim) => {
//     return axios.post(`${baseUrl}/MehorasimControler/EditMehorasim`, mehorasim).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
// };

//-----------------------------------------------------------------------------------
//                                    Search
//-----------------------------------------------------------------------------------
export const GetSearchByProduct = () => {
    return axios.get(`${baseUrl}/Search/GetSearchByProduct`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetSearchByNameBussiness = () => {
    return axios.get(`${baseUrl}/Search/GetSearchByNameBussiness`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetBussinessToSearch = (arrToSearch) => {
    return axios.post(`${baseUrl}/Search/GetBussinessToSearch`, arrToSearch).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetAllBussinessToSearch = () => {
    return axios.get(`${baseUrl}/Search/GetAllBussinessToSearch`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

//-----------------------------------------------------------------------------------
//                                    PassControler
//-----------------------------------------------------------------------------------
export const GetPass = () => {
    return axios.get(`${baseUrl}/PassControler/GetPass`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

//-----------------------------------------------------------------------------------
//                                    StatisticControler
//-----------------------------------------------------------------------------------
export const GetBusStatistic = (categoryCode, bussinessId) => {
    return axios.get(`${baseUrl}/StatisticControler/GetBusStatistic?categoryCode=${categoryCode}&bussinessId=${bussinessId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const GetBusStatisticForMonth = (theDate) => {
    return axios.get(`${baseUrl}/StatisticControler/GetBusStatisticForMonth?date=${theDate}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditHowSeeOutside = (categoryCode, views) => {
    return axios.post(`${baseUrl}/StatisticControler/EditHowSeeOutside?cc=${categoryCode}`, views).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditHowSeeKishur = (categoryCode, bussinessId) => {
    return axios.post(`${baseUrl}/StatisticControler/EditHowSeeKishur?cc=${categoryCode}&bussinessId=${bussinessId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};

export const EditHowEditBussiness = (categoryCode, bussinessId) => {
    return axios.post(`${baseUrl}/StatisticControler/EditHowEditBussiness?cc=${categoryCode}&bussinessId=${bussinessId}`).then((response) => { return response.data; }).catch((error) => { console.error(error); throw error; });
};