import { useEffect } from 'react'
// import wait2 from '../../pic/לוגו/אפור.jpg'
import wait from '../../pic/logo/10.jpg'
import wait2 from '../../pic/logo/wait.jpg'
import waitEgul from '../../pic/animations/56.gif'

import './Wait.css'

const Wait = () => {

    return <div style={{ position: 'absolute', top: '0', left: '0', zIndex: '700', position: 'fixed', width: '100%', height: '100%', backgroundColor: 'black', alignItems: 'center', display: 'flex', userSelect:'none' }}>
        <div style={{ position: 'absolute', top: '0', right: '0', width: '100%', height: '43%', backgroundColor: 'black', zIndex: '600' }}></div>
        <div style={{ position: 'relative', width: '230px', height: '150px', marginRight: 'auto', marginLeft: 'auto' }}>
            <img loading='lazy' src={wait} alt={'קול שמחה'} style={{ position: 'absolute', top: '0', right: '0', width: '230px', height: '150px', zIndex: '200' }}></img>
            <div className='div555' style={{ position: 'absolute', top: '0', right: '0', width: '230px', height: '150px', zIndex: '202' }}></div>
            <img loading='lazy' src={wait2} alt={'קול שמחה'} style={{ position: 'absolute', top: '0', right: '0', width: '230px', height: '150px', zIndex: '204' }}></img>
        </div>
        <div style={{ position: 'absolute', width: '100%', height: '80px', bottom: '31%' }}>
            <img loading='lazy' src={waitEgul} alt={'קול שמחה'} style={{marginRight: 'auto', marginLeft: 'auto', width: '1500px', height: '20px', zIndex: '800' }}></img>
        </div>
        <p style={{position: 'absolute', width: '100%', color:'white', textAlign:'center', bottom: '25%'}}>טוען נתונים...</p>

    </div>
}

export default Wait