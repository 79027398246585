import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import './BottomMenu.css'
import FormNewBusiness from '../forms/FormNewBusiness'
import RefReady from '../ref'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import Alert from '../alert/Alert'

const BottomMenu = () => {

    const [getMenu, setGetMenu] = useState([]);
    const getMenuFromReducer = useSelector((state) => state.menuReducer)
    const d = useDispatch()

    useEffect(() => {
        if (getMenuFromReducer.length > 0)
            setGetMenu(getMenuFromReducer)
        // else {
        //     getAllBigMenu().then((data) => {
        //         setGetMenu(data);
        //         window.scrollTo(0, 0);
        //         d(CompleteMenu(data));
        //     }).catch((error) => { console.error(error); });
        // }
    }, [getMenuFromReducer])

    const [alert, setAlert] = useState(false)
    const [send, setSend] = useState(false)

    const sendGood = () => {
        setAlert(false)
        setSend("ההודעה נשלחה בהצלחה !!!")
        setTimeout(() => {
            setSend(false)
        }, 40 * 100);
    }


    return <div id='bottomMenu'>
        <div className='containerBottom' style={{ fontSize: '90%', color: 'white' }}>
            <div className='inContainerBottom' style={{ minWidth: 'max-content' }}>
                <div style={{ fontWeight: '400', userSelect: 'none' }}>ניווט ראשי</div>
                <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#FF7D7D', marginLeft: 'auto', marginRight: 'auto', marginTop: '2px', marginBottom: '2%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
                {getMenu.map((x, i) => (i < 6 && <span key={i}>
                    <Link style={{ textDecoration: 'none' }} to={`/${x.name}/${i}`}>{x.name}</Link><br></br>
                </span>))}
            </div>
            <div className='inContainerBottom' style={{ minWidth: 'max-content' }}>
                <div style={{ fontWeight: '400', userSelect: 'none' }}>ארגונית אישית</div>
                <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#FF7D7D', marginLeft: 'auto', marginRight: 'auto', marginTop: '2px', marginBottom: '2%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
                <Link className='linkBottom' to={`/howManyToPay`}>ארגון הוצאות החתונה</Link><br></br>
                <Link className='linkBottom' to={`/listAddress`}>רשימת מוזמנים וכתובות</Link><br></br>
                <Link className='linkBottom' to={`/choozedBussness`}>הזמנות שבוצעו</Link><br></br>
                <Link className='linkBottom' to={`/Shopping`}>סל קניות</Link><br></br>
                <Link className='linkBottom' to={`/PersonalArea`}>אזור אישי</Link><br></br>
            </div>
            <div className='inContainerBottom' style={{ minWidth: 'max-content' }}>
                <div style={{ fontWeight: '400', userSelect: 'none' }}>כלים נוספים</div>
                <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#FF7D7D', marginLeft: 'auto', marginRight: 'auto', marginTop: '2px', marginBottom: '2%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
                <Link className='linkBottom' to={`/הצטרפות לעסקים/:1`}>הצטרפות לעסקים</Link><br></br>
                <Link className='linkBottom' to={`/מאורסים/:undefined`}>מודעות מאורסים</Link><br></br>
                <Link className='linkBottom' onClick={() => { setAlert(true) }}>יצירת קשר</Link><br></br>
                <Link className='linkBottom' to={`/BusinessArea`}>כניסת עסקים</Link><br></br>
            </div>
        </div>
        <div style={{ position: 'absolute', bottom: '50px', width: '90%', right: '5%', color: 'white', display: 'inline-block', userSelect: 'none' }}><div style={{ display: 'inline-block', color: '#FF7D7D' }}>עסק חדש?</div> מלא את הפרטים ונחזור אליך בהקדם
            <div style={{ marginRight: '1%', display: 'inline-block' }}><FormNewBusiness show={true}></FormNewBusiness></div></div>
        <div style={{ position: 'absolute', bottom: '10px', color: 'white', right: '10%', width: '80%', userSelect: 'none' }}>
            <div style={{ display: 'inline-block', float: 'right', marginRight: '2%' }}>קול שמחה</div>
            <div style={{ float: 'left', display: 'inline-block', width: '70%', textAlign: 'left' }}><div style={{ paddingLeft: '3%', display: 'inline-block' }}>פיתוח ובנייה</div>
                <PhoneOutlined style={{ display: 'inline-block', paddingLeft: '1%', color: '#FF7D7D' }} /><span>0527117663</span>
                <MailOutlined style={{ display: 'inline-block', color: '#FF7D7D', paddingRight: '3%', paddingLeft: '1%' }} /><span style={{ userSelect: 'text' }}>k3137663@gmail.com</span>
                <div style={{ display: 'inline-block' }}></div>
            </div>
        </div>

        {alert && <Alert set={setAlert} sendGood={sendGood} text={"קול שמחה"} mail={"info@kolsimcha.co.il"}></Alert>}

    </div>
}

export default BottomMenu