import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ButtonRed from "../../global/button/button"
import Alert from "../../global/alert/Alert"
import { useState } from "react"

const SideNavPersonalOrganizer = (props) => {
    const s = useSelector(a => a.userReducer)
    const navigate = useNavigate()
    const [alert2, setAlert2] = useState(false)

    const [send, setSend] = useState(false)
    const sendGood = () => {
        setAlert2(false)
        setSend("ההודעה נשלחה בהצלחה !!!")
        setTimeout(() => {
            setSend(false)
        }, 40 * 100);
    }

    return <div style={{ position: 'absolute', top: '0', right: '0', zIndex: '10', width: '17%', position: 'fixed', height: '100%' }}>

        <div>
            <div style={{ width: '100%', height: '10vh', marginTop: '33%' }}>
                {s.id && props.page === 1 && <span>
                    <div>{props.taskYes} משימות בוצעו</div>
                    <div>{props.taskNo} משימות לא בוצעו</div>
                </span>}
            </div>

            <div style={{ width: '90%', marginRight: '10%' }}>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/taskList`)}><ButtonRed is={props.page === 1 ? 'this' : ''} text='רשימת משימות'></ButtonRed></div>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/choozedBussness`)}><ButtonRed is={props.page === 2 ? 'this' : ''} text='ההזמנות שלי'></ButtonRed></div>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/howManyToPay`)}><ButtonRed is={props.page === 3 ? 'this' : ''} text='ארגון הוצאות'></ButtonRed></div>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/ListAddress`)}><ButtonRed is={props.page === 4 ? 'this' : ''} text='רשימת כתובות'></ButtonRed></div>
                <div className='buton1' style={{ width: '100%' }} onClick={() => navigate(`/PersonalArea`)}><ButtonRed is={props.page === 5 ? 'this' : ''} text='איזור אישי'></ButtonRed></div>
            </div>

            {props.page === 1 && <div id='toHelp' style={{ width: '90%', marginRight: '10%', marginTop: '5%' }}>
                <p>לתועלת המשתמשים שתפו אותנו במידה ויש לכם משימות נוספות מעבר לרשימה.</p>
                <div className='buttonKishur' style={{ cursor: 'pointer', border: '#c00000 1px solid', marginTop: '-6%' }} onClick={() => { setAlert2(true) }}>עדכנו אותנו</div>
            </div>}

            {props.page === 3 && <div id='toHelp' style={{ width: '90%', marginRight: '10%', marginTop: '5%' }}>
                <p>המחירים להמלצה בלבד ללא אחריות.</p>
                <p>ההוצאות האלה דינמיות ומשתנות מעונה לעונה, לתועלת המשתמשים נשמח לקבל עידכונים שוטפים.</p>
                <div className='buttonKishur' style={{ cursor: 'pointer', border: '#c00000 1px solid', marginTop: '-20px' }} onClick={() => { setAlert2(true) }}>עדכנו אותנו</div>
            </div>}

        </div>

        {alert2 && <Alert set={setAlert2} sendGood={sendGood} text={"קול שמחה"} mail={"info@kolsimcha.co.il"}></Alert>}

        {send && <div className="send animate__animated animate__bounceInUp">
            <div style={{ lineHeight: '8vh', textAlign: 'center', fontWeight: '400' }}>{send}</div>
        </div>}

    </div>
}

export default SideNavPersonalOrganizer