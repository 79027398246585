import { useEffect, useState } from 'react';
import picMehorasim from '../../../pic/105.jpg';
import BottomMenu from '../../global/bottomMenu/BottomMenu';
import RefReady from '../../global/ref';
import { CloseOutlined } from '@ant-design/icons';
import ButtonRed from '../../global/button/button';
import Login from '../10. login/Login';
import Connection from '../11. connection/Connection';
import { useDispatch, useSelector } from 'react-redux';
import { AddMehorasim, GetAllMehorasim, SendEmailToBussiness } from '../../axios/api';
import { COMPLETEmehorasim } from '../../2. redux/actions/mehorasimActions';
import picError from '../../../pic/animations/113.gif'

const Mehorasim = () => {

    const s = useSelector(a => a.userReducer)
    const SallMehorasim = useSelector(a => a.mehorasimReducer)
    const [openNew, setOpenNew] = useState(false)
    const [openNew2, setOpenNew2] = useState(false)
    const [opUser, setOpUser] = useState(false)
    const [login, setLogin] = useState(false);
    const [connection, setConnection] = useState(false);
    const [mehorasim, setMehorasim] = useState({ nameChatan: '', fatherChatan: '', yeshiva: '', cityChatan: '', nameKala: '', fatherKala: '', seminar: '', cityKala: '', date: '', userId: 0 });
    const d = useDispatch()
    const [allMehorasim, setAllMehorasim] = useState([])
    const [errorMessage, setErrorMessage] = useState('');
    const [mail, setMail] = useState('');
    const [opMail, setOpMail] = useState(false);

    const [send, setSend] = useState(false)

    useEffect(() => {
        if (SallMehorasim.length > 0) {
            setAllMehorasim(SallMehorasim)
        } else
            GetAllMehorasim().then((data) => {
                setAllMehorasim(data)
                d(COMPLETEmehorasim(data))
            }).catch((error) => { console.error(error); });
    }, []);

    useEffect(() => {
        if (mehorasim.cityChatan === '' && s.chatanOrKala === "חתן")
            setMehorasim((p) => { return { ...p, cityChatan: s.city } })
        if (mehorasim.cityKala === '' && s.chatanOrKala === "כלה")
            setMehorasim((p) => { return { ...p, cityKala: s.city } })
        setMail(s.mail)
    }, [s]);

    return <div>

        <div className='buton1' style={{ width: '20%', minWidth: 'max-content', position: 'absolute', top: '15%', left: '4.8%' }} onClick={() => { setOpenNew(true) }}><ButtonRed text='פרסם מודעת מאורסים חינם'></ButtonRed></div>

        <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '15vh', marginBottom: '4px' }}>רשימת מאורסים</p>
        <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginBottom: '4vh' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

        <div>מודעות מאורסים נשלחות בקביעות לכל מייל רשום, כדי להירשם <span style={{ color: '#c00000', cursor: 'pointer', fontWeight: '400', borderBottom: '2px solid #c00000' }} onClick={() => { setOpMail(true) }}>לחץ כאן</span></div>

        {allMehorasim.map((x, i) => (
            <div key={i} style={{ backgroundImage: `url(${picMehorasim})`, fontSize: '86%', backgroundSize: '100% 100%', height: '250px', width: '650px', marginRight: 'auto', marginLeft: 'auto', position: 'relative', display: 'inline-block', margin: '1%', userSelect: 'none' }}>
                <span style={{ direction: 'rtl', lineHeight: '120%', width: '40%', textAlign: 'right', paddingTop: '7%', marginRight: '10%', display: 'inline-block' }}>
                    <div style={{ fontWeight: '400', fontSize: '110%' }}>{x.nameChatan}</div>
                    <div style={{ fontSize: `${mehorasim.fatherChatan.length > 30 ? '75%' : mehorasim.fatherChatan.length > 25 ? '90%' : '100%'}` }}>{x.fatherChatan}</div>
                    <div style={{ fontSize: `${mehorasim.fatherChatan.length > 30 ? '75%' : mehorasim.fatherChatan.length > 25 ? '90%' : '100%'}` }}>{x.yeshiva}</div>
                    <div>{x.cityChatan}</div>
                </span>

                <span style={{ direction: 'ltr', lineHeight: '120%', width: '40%', textAlign: 'left', paddingTop: '7%', marginLeft: '10%', display: 'inline-block' }}>
                    <div style={{ fontWeight: '400', fontSize: '110%' }}>{x.nameKala}</div>
                    <div style={{ fontSize: `${mehorasim.fatherChatan.length > 30 ? '75%' : mehorasim.fatherChatan.length > 25 ? '90%' : '100%'}` }}>{x.fatherKala}</div>
                    <div style={{ fontSize: `${mehorasim.fatherChatan.length > 30 ? '75%' : mehorasim.fatherChatan.length > 25 ? '90%' : '100%'}` }}>{x.seminar}</div>
                    <div>{x.cityKala}</div>
                </span>

                <span style={{ position: 'absolute', bottom: '19%', right: '0', width: '100%', textAlign: 'center' }}>יום חמישי אור לכ"ג שבט תשפ"ד</span>
            </div>
        ))}

        {allMehorasim.length === 0 && <div style={{height:'100px', width:'100%'}}></div>}



        {openNew && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => { setErrorMessage(false); setOpenNew(false) }} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '64vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '18vh' }}>
                    <div className='titl' style={{ marginTop: '3%', fontSize: '110%', marginBottom: '2%' }}>פירסום מודעת מאורסים</div>

                    <div style={{ backgroundImage: `url(${picMehorasim})`, fontSize: '86%', backgroundSize: '100% 100%', height: '320px', width: '800px', position: 'relative', marginRight: 'auto', marginLeft: 'auto' }}>
                        <span style={{ direction: 'rtl', lineHeight: '120%', width: '40%', textAlign: 'right', paddingTop: '7%', marginRight: '10%', display: 'inline-block' }}>
                            <input style={{ fontWeight: '400', fontSize: '110%' }} type='text' placeholder='*שם החתן' value={mehorasim.nameChatan} onChange={(e) => { if (e.target.value.length <= 25) { setMehorasim((p) => { return { ...p, nameChatan: e.target.value } }) } }}></input>
                            <input type='text' placeholder='*שם האב' value={mehorasim.fatherChatan} onChange={(e) => { if (e.target.value.length <= 35) { setMehorasim((p) => { return { ...p, fatherChatan: e.target.value } }) } }}></input>
                            <input type='text' placeholder='ישיבה' value={mehorasim.yeshiva} onChange={(e) => { if (e.target.value.length <= 35) { setMehorasim((p) => { return { ...p, yeshiva: e.target.value } }) } }}></input>
                            <input type='text' placeholder='*עיר מגורים' value={mehorasim.cityChatan} onChange={(e) => { if (e.target.value.length <= 15) { setMehorasim((p) => { return { ...p, cityChatan: e.target.value } }) } }}></input>
                        </span>

                        <span style={{ direction: 'ltr', lineHeight: '120%', width: '40%', textAlign: 'left', paddingTop: '7%', marginLeft: '10%', display: 'inline-block' }}>
                            <input style={{ fontWeight: '400', fontSize: '110%' }} type='text' placeholder='שם הכלה*' value={mehorasim.nameKala} onChange={(e) => { if (e.target.value.length <= 25) { setMehorasim((p) => { return { ...p, nameKala: e.target.value } }) } }}></input>
                            <input type='text' placeholder='שם האב*' value={mehorasim.fatherKala} onChange={(e) => { if (e.target.value.length <= 35) { setMehorasim((p) => { return { ...p, fatherKala: e.target.value } }) } }}></input>
                            <input type='text' placeholder='סמינר' value={mehorasim.seminar} onChange={(e) => { if (e.target.value.length <= 35) { setMehorasim((p) => { return { ...p, seminar: e.target.value } }) } }}></input>
                            <input type='text' placeholder='עיר מגורים*' value={mehorasim.cityKala} onChange={(e) => { if (e.target.value.length <= 15) { setMehorasim((p) => { return { ...p, cityKala: e.target.value } }) } }}></input>
                        </span>

                        <input type='text' placeholder='*תאריך' style={{ position: 'absolute', bottom: '19%', right: '0', width: '70%', textAlign: 'center', marginRight: '15%' }} value={mehorasim.date} onChange={(e) => { if (e.target.value.length <= 35) { setMehorasim((p) => { return { ...p, date: e.target.value } }) } }}></input>
                    </div>

                    <div className='buton1' style={{ width: '20%', minWidth: 'max-content', display: 'inline-block', marginTop: '1%', marginBottom: '0' }} onClick={() => { if (mehorasim.nameChatan !== "" && mehorasim.fatherChatan !== "" && mehorasim.cityChatan !== "" && mehorasim.nameKala !== "" && mehorasim.fatherKala !== "" && mehorasim.cityKala !== "" && mehorasim.date !== "") { setErrorMessage(false); setOpenNew2(true) } else { setErrorMessage('השדות המסומנות בכוכבית הן חובה') } }}><ButtonRed text='אישור'></ButtonRed></div>
                    {errorMessage && <p style={{ marginTop: '0' }}><img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}

                </div>
            </div>
        </div>}

        {openNew2 && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => setOpenNew2(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '64vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '18vh' }}>
                    <div className='titl' style={{ marginTop: '5%', fontSize: '110%', marginBottom: '2%' }}>נא לאשר את המודעה קודם הפרסום</div>

                    <div style={{ backgroundImage: `url(${picMehorasim})`, fontSize: '86%', backgroundSize: '100% 100%', height: '250px', width: '650px', marginRight: 'auto', marginLeft: 'auto', position: 'relative', userSelect: 'none' }}>
                        <span style={{ direction: 'rtl', lineHeight: '120%', width: '40%', textAlign: 'right', paddingTop: '7%', marginRight: '10%', display: 'inline-block' }}>
                            <div style={{ fontWeight: '400', fontSize: '110%' }}>{mehorasim.nameChatan}</div>
                            <div style={{ fontSize: `${mehorasim.fatherChatan.length > 30 ? '75%' : mehorasim.fatherChatan.length > 25 ? '90%' : '100%'}` }}>{mehorasim.fatherChatan}</div>
                            <div style={{ fontSize: `${mehorasim.yeshiva.length > 30 ? '75%' : mehorasim.yeshiva.length > 25 ? '90%' : '100%'}` }}>{mehorasim.yeshiva}</div>
                            <div>{mehorasim.cityChatan}</div>
                        </span>

                        <span style={{ direction: 'ltr', lineHeight: '120%', width: '40%', textAlign: 'left', paddingTop: '7%', marginLeft: '10%', display: 'inline-block' }}>
                            <div style={{ fontWeight: '400', fontSize: '110%' }}>{mehorasim.nameKala}</div>
                            <div style={{ fontSize: `${mehorasim.fatherKala.length > 30 ? '75%' : mehorasim.fatherKala.length > 25 ? '90%' : '100%'}` }}>{mehorasim.fatherKala}</div>
                            <div style={{ fontSize: `${mehorasim.seminar.length > 30 ? '75%' : mehorasim.seminar.length > 25 ? '90%' : '100%'}` }}>{mehorasim.seminar}</div>
                            <div>{mehorasim.cityKala}</div>
                        </span>

                        <span style={{ position: 'absolute', bottom: '19%', right: '0', width: '100%', textAlign: 'center' }}>{mehorasim.date}</span>
                    </div>

                    <div className='buton1' style={{ width: '20%', minWidth: 'max-content', display: 'inline-block', marginTop: '2%' }} onClick={() => {
                        debugger
                        if (!s.id) { setOpUser(true) }
                        else {
                            AddMehorasim({ ...mehorasim, userId: s.id }).then((data) => { d(COMPLETEmehorasim(data)); setAllMehorasim(data); setOpenNew2(false); setOpenNew(false) }).catch((error) => { console.error(error); });
                        }
                    }}><ButtonRed text='אישור'></ButtonRed></div>
                </div>
            </div>
        </div>}

        {opUser && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => setOpUser(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '40vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '30vh' }}>
                    <div className='titl' style={{ marginTop: '8%', fontSize: '120%', marginBottom: '5%' }}>לא ניתן לבצע פעולה זו, עליכם להתחבר או להירשם למערכת</div>
                    <div style={{ width: '10%', display: 'inline-block' }} onClick={() => setLogin(true)}><ButtonRed text='התחבר'></ButtonRed></div>
                    <div style={{ width: '10%', display: 'inline-block' }} onClick={() => setConnection(true)}><ButtonRed text='הירשם'></ButtonRed></div>
                </div>
            </div>
        </div>}

        {login && <Login setLogin={(bool) => { setLogin(false); setOpUser(false) }}></Login>}
        {connection && <Connection set={(bool) => { setConnection(false); setOpUser(false) }}></Connection>}

        {send && <div className="send animate__animated animate__bounceInUp">
            <div style={{ lineHeight: '8vh', textAlign: 'center', fontWeight: '400' }}>{send}</div>
        </div>}

        {opMail && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => { setErrorMessage(false); setOpMail(false) }} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '40vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '30vh' }}>
                    <div className='titl' style={{ marginTop: '4%', fontSize: '120%', marginBottom: '2%' }}>הכנס כתובת מייל</div>
                    <input className='input20 ii' style={{ width: '70%' }} placeholder="*דואר אלקטרוני" autoComplete="on" type="email" value={mail} onChange={(e) => { if (e.target.value.length <= 254 && (/^[א-תA-Za-z@.0-9]+$/.test(e.target.value) || e.target.value === '')) { setMail(e.target.value) } }} />
                    <div className='buton1' style={{ width: '20%', minWidth: 'max-content', display: 'inline-block', marginTop: '1%' }} onClick={() => {
                        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(mail)) {
                            setErrorMessage('כתובת מייל לא תקינה')
                        } else {
                            setErrorMessage(false)
                            var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;">להירשם לקבלת מאורסים</p></div>  <br/><br/> ${s.name ? `משפחת ${s.name}` : ''}<br/><b style="font-size:150%">${mail}</b><br/> <br/> <br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" class="button" style="text-decoration: none; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); border: #c00000 3px solid; color:black; padding: 10px; padding-left:30px; padding-right: 30px;">מעבר לאתר</a> <br/></div>`
                            SendEmailToBussiness({ name: "מיזם קול שמחה", emailTo: 'info@kolsimcha.co.il', emailFrom: 'info@kolsimcha.co.il', subject: "להירשם לקבלת מאורסים", message: mes }).then(() => {debugger; setSend("הצטרפתם בהצלחה !!!"); setTimeout(() => { setSend(false) }, 40 * 100); setOpMail(false); }).catch((error) => { console.error(error); });
                        }
                    }}><ButtonRed text='אישור'></ButtonRed></div>
                    {errorMessage && <p style={{ marginTop: '0' }}><img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}
                </div>
            </div>
        </div>}
        <BottomMenu></BottomMenu>
    </div>
};

export default Mehorasim