import { useEffect, useRef, useState } from 'react';
import './Bussiness.css'
import Carousel from './Carousel/Carousel';
import ButtonRed from '../button/button';
import SendMailFOrBussiness from '../forms/SendMailFOrBussiness';
import BottomMenu from '../bottomMenu/BottomMenu';
import Option from './options/AddOption';
import Calender from './Calender/Calender';
import { useDispatch, useSelector } from 'react-redux';
import { EditHowManySeeYou, EditHowSeeKishur, GetBussinessData } from '../../axios/api';
import { IconButton } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import RefReady from '../ref';
import { HDate } from 'hebcal';
import { CloseOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PlusOutlined } from '@ant-design/icons';
import Options from './options/Options';
import LotOfItems from './LotOfItems/LotOfItems';
import EnterManagger from '../../3. managger/1. enterManagger';
import { CompleteBussinessData } from '../../2. redux/actions/thisBussinessActions';
import Wait from '../Wait';

const Bussiness = () => {

    useEffect(() => {
        if (s.id && !didExios.EditHowManySeeYou) {  // רק אם טרם התבצע
            didExios.EditHowManySeeYou = true;  // להימנע מביצוע נוסף
            EditHowManySeeYou(!['1', '2', '179', '182', '3', '82', '4'].includes(p.codeCategory) ? 0 : s.categoryCode, s.id).then((data) => { }).catch((error) => { console.error(error); });
        }
    }, []);

    const s = useSelector((state) => state.thisBussinessReducer)
    const [bussinessData, setBussinessData] = useState()
    const navigate = useNavigate()
    const location = useLocation();
    const p = useParams()
    const d = useDispatch()
    const [imageZoom, setImageZoom] = useState(false)
    const [address, setAddress] = useState([])
    const [city, setCity] = useState([])
    const baseImageUrl = "https://kolsimcha.co.il/images";
    const didExios = useRef(false);
    const [chooze, setChooze] = useState(1)

    useEffect(() => {
        if (p.categoryName !== 'סל קניות')
            window.scrollTo(0, 0);
        else {
            window.scrollTo(0, 1300);
        }
        //בשביל עמוד כוכבית שידע האם להציג את האפשרות לנהל תאריכים - ניהול יומן
        sessionStorage.setItem('mainCC', JSON.stringify(p.codeCategory))
    }, []);


    const [op, setOp] = useState(true)
    const setOP = (data) => {
        setOp(false)
        for (let i = 0; i < data.length; i++) {
            data[i].numDates = Math.floor((new Date() - new Date(data[i].date)) / (24 * 60 * 60 * 1000))
        }
        setBussinessData({ ...bussinessData, options: data })
        // d(CompleteBussinessData({ ...bussinessData, options: data }))
        setTimeout(() => {
            setOp(true)
        }, 400);
    }

    useEffect(() => {
        if (s.address)
            setAddress(s.address.split(',.'))
        if (s.city)
            setCity(s.city.split(',.'))
        if (s.id && !didExios.GetBussinessData) {  // רק אם טרם התבצע
            didExios.GetBussinessData = true;  // להימנע מביצוע נוסף
            GetBussinessData(s.id, !['1', '2', '179', '182', '3', '82', '4'].includes(p.codeCategory) ? 0 : Number(s.categoryCode)).then((data) => {
                for (let i = 0; i < data.options.length; i++) {
                    data.options[i].numDates = Math.floor((new Date() - new Date(data.options[i].date)) / (24 * 60 * 60 * 1000))
                }
                for (let i = 0; i < data.answers.length; i++) {
                    data.answers[i].questionCon = data.answers[i].answer1.split('.,!')[0];
                    data.answers[i].answerCon = data.answers[i].answer1.split('.,!')[1];
                }
                setBussinessData(data);
                d(CompleteBussinessData(data))
            }).catch((error) => { console.error(error); });
        }
    }, []);

    const [addOpinion, setAddOpinion] = useState(false)


    const HebrewDaysNames = ['0', "א'", "ב'", "ג'", "ד'", "ה'", "ו'", "ז'", "ח'", "ט'", "י'", 'י"א', 'י"ב', 'י"ג', 'י"ד', 'ט"ו', 'ט"ז', 'י"ז', 'י"ח', 'י"ט', "כ'", 'כ"א', 'כ"ב', 'כ"ג', 'כ"ד', 'כ"ה', 'כ"ו', 'כ"ז', 'כ"ח', 'כ"ט', "ל'"];

    const fullDatesH = () => {
        var fullDatesH = []
        bussinessData.dates.forEach(element => {
            const hebrewDate0 = new HDate(new Date(element.date1))
            const hebrewDateString0 = `${HebrewDaysNames[hebrewDate0.getDate()]} ${hebrewDate0.getMonthName('h')}`;
            fullDatesH.push(hebrewDateString0)
        });
        return fullDatesH;
    }


    const [openAns, setOpenAns] = useState([])
    const [openManag, setOpenManag] = useState(false)
    const [errorMessage, setErrorMessage] = useState()


    const passManageSubmit = (x) => {
        if (Number(document.getElementById('passManag').value) !== s.code && !x)
            setErrorMessage('סיסמה שגויה')
        else {
            navigate(`/Kochavit/${p.categoryName}/${p.codeCategory}`)
            setOpenManag(false)
        }
    }



    //------------------------------------------------------------------------------
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // if(entry.target.classList.contains('k1'))
                    entry.target.classList.add('k11')
                    // if(entry.target.classList.contains('k2'))
                    // entry.target.classList.add('k22')
                }
            });
        }, { threshold: 0.8 });

        if (elementRef.current) { observer.observe(elementRef.current); }

        return () => { if (elementRef.current) { observer.unobserve(elementRef.current); } };
    }, []);
    //------------------------------------------------------------------------------
    const allKochavim = () => {
        var sum = 0, all = [];
        bussinessData.options.forEach(e => {
            sum = sum + e.kochvim
        });
        all = (sum / bussinessData.options.length + '.').split('.')
        all[1] = all[1].charAt('1')
        // var zero = 1;
        // for (let index = all[1].length - 1; index >= 0; index--) {
        //     zero = zero * 10;
        // }
        // all[1] = bussinessData.options.length * all[1] / zero
        // all[1] = Math.floor(parseFloat(all[1]))
        return all;
    }

    const kochavim = (k) => {
        var tag = [];
        for (let index = 0; index < k; index++) {
            tag.push(<p>&#9733;</p>)
        }
        for (let index = 0; index < 5 - k; index++) {
            tag.push(<p>&#9734;</p>)
        }
        return tag;
    }


    return <>{bussinessData && <div style={{ overflowX: 'hidden' }}>
        {p.categoryName !== s.name && <p style={{ fontSize: '90%', fontWeight: '300', marginTop: '80px', color: '#c00000', float: 'right', marginRight: '12%', cursor: 'pointer' }}><span style={{ display: 'inline-block' }} onClick={() => { navigate(-2) }}>{p.menu} &#8592;</span> <span style={{ display: 'inline-block' }} onClick={() => { localStorage.setItem("isScrollPosition", true); navigate(-1) }}>{p.categoryName} &#8592;</span> <span style={{ display: 'inline-block' }}>{s.name} &#8592;</span></p>}

        <div className='manag' onClick={() => setOpenManag(true)}><img loading='lazy' style={{ height: '10vh', width: 'auto' }} src={encodeURI(`${baseImageUrl}${s.logo?.replace(/\\/g, '\/')}?format=avif`)} alt='אין לוגו'></img></div>

        {/* תפריט בצד ימין */}
        <div style={{ position: 'absolute', right: '0', top: '17%', minWidth: 'fit-content', width: '23vh', position: 'fixed', zIndex: '100' }}>
            <div style={{ padding: '3px', color: '#c00000', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', width: '40px', backgroundColor: 'white' }} onClick={() => { var rightMen = document.getElementById('rightMen'); rightMen.style.display = rightMen.style.display === 'inline-block' ? 'none' : 'inline-block' }}><MenuUnfoldOutlined /></div>
            <div id='rightMen'>
                <div onClick={() => { window.scrollTo(0, 0) }} className='menuDivBus'>מידע כללי</div>
                {s.kishurLahatar && !['2', '179', '182'].includes(p.codeCategory) && <div onClick={() => { EditHowSeeKishur(!['1', '2', '179', '182', '3', '82', '4'].includes(p.codeCategory) ? 0 : s.categoryCode, s.id); window.open(s.kishurLahatar) }} className='menuDivBus'>מעבר לאתר</div>}
                {s.kishurLahatar && ['2', '179', '182'].includes(p.codeCategory) && <div onClick={() => { EditHowSeeKishur(!['1', '2', '179', '182', '3', '82', '4'].includes(p.codeCategory) ? 0 : s.categoryCode, s.id); window.open(s.kishurLahatar) }} className='menuDivBus'>מעבר לקליפ</div>}

                {/* {s.kishurLahatar && !['2', '179', '182'].includes(p.codeCategory) && <div className='buton' style={{ width: '100%', marginBottom: '20px', marginTop: '0', marginRight: '0' }}><ButtonRed text='מעבר לאתר'></ButtonRed></div>}
            {s.kishurLahatar && ['2', '179', '182'].includes(p.codeCategory) && <div className='buton' style={{ width: '100%', marginBottom: '20px', marginTop: '0', marginRight: '0' }} onClick={() => window.open(s.kishurLahatar)}><ButtonRed text='מעבר לקליפ'></ButtonRed></div>} */}


                {/* השארתי בשביל וואצאפ */}
                {/* {s.numberToCall && <div className='buton' style={{ width: '100%', marginBottom: '20px', marginTop: '1%', marginRight: '0', height: '56px' }}><a href='#callToBussiness' style={{ textDecoration: 'none' }}><ButtonRed text='התקשר אלינו'></ButtonRed></a></div>} */}
                {bussinessData.dates?.length > 0 && <div className='menuDivBus'><a className='AmenuDivBus' href='#nullDates' style={{ textDecoration: 'none' }}>תאריכים פנויים</a></div>}
                {bussinessData.songs?.length > 0 && bussinessData.songs[0].name && <div className='menuDivBus'><a className='AmenuDivBus' href='#songsDid' style={{ textDecoration: 'none' }}>שירים לדוגמה</a></div>}
                {bussinessData.lotOfItems.length > 0 && <div className='menuDivBus'><a className='AmenuDivBus' href='#lotDivOf' style={{ textDecoration: 'none' }}>מכירת מוצרים</a></div>}
                {bussinessData.songs?.length > 0 && !bussinessData.songs[0].name && <div className='menuDivBus'><a className='AmenuDivBus' href='#kasher' style={{ textDecoration: 'none' }}>כשרות</a></div>}
                {s.priceList?.length > 0 && <div className='menuDivBus'><a className='AmenuDivBus' href='#priceListDiv' style={{ textDecoration: 'none' }}>מחירון</a></div>}

                <div className='menuDivBus'><a className='AmenuDivBus' href='#options' style={{ textDecoration: 'none' }}>{bussinessData.options.length} חוות דעת
                    {bussinessData.options.length > 0 && <p className='pInDiv' style={{ direction: 'ltr', margin: '0' }}>
                        {kochavim(allKochavim()[0]).map((a, b) => (
                            <span key={b}>{a}</span>
                        ))}
                        {!allKochavim()[1] && <span>({allKochavim()[0]})</span>}
                        {allKochavim()[1] && <span>({allKochavim()[0]}.{allKochavim()[1]})</span>}
                    </p>}
                </a></div>

                <div className='menuDivBus'><a className='AmenuDivBus' href='#SendMailFOrBussiness' style={{ textDecoration: 'none' }}>יצירת קשר</a></div>
                {bussinessData.answers?.length > 0 && <div className='menuDivBus'><a className='AmenuDivBus' href='#Questions' style={{ textDecoration: 'none' }}>שאלות נפוצות</a></div>}
            </div>
        </div>

        <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%', width: '30%', marginRight: '35%', marginBottom: '2px' }}>{s.name}</p>
        <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

        {/* ------------------------------------------------------------------------------------------- */}

        <p style={{ fontSize: '110%', fontWeight: '400', marginTop: '2%', paddingRight: '15%', paddingLeft: '15%' }}>{s.description}</p>

        {p.codeCategory !== '1' && !['2', '179', '182'].includes(p.codeCategory) && p.codeCategory !== '4' && p.codeCategory !== '82' && p.codeCategory !== '3' && <div className='container3' style={{ marginTop: '-5%' }}>
            <div style={{ marginBottom: '20%', paddingTop: '20%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>כתובת</p>
                {address.map((x, i) => (<span key={i} style={{ fontSize: '95%', marginLeft: '3%', marginRight: '3%', display: 'inline-block' }}>{address.length > 1 && <><span style={{ fontWeight: '400' }}>סניף {i + 1}</span><br /></>}{address[i]}<br />{city[i]}</span>))}
            </div>
            <div style={{ margin: '20%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>יתרון</p>
                <p style={{ fontSize: '95%' }}>{s.adventage}</p>
            </div>
            <div style={{ margin: '20%', border: s.bonus ? '#c00000 2px solid' : 'none' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>בונוס</p>
                <p style={{ fontSize: '95%' }}>{s.bonus}</p>
            </div>
        </div>}

        {(p.codeCategory === '82' || (p.codeCategory === '1' && !s.rangeDishes && !s.rangePriceToDishes)) && <div className='container3'>
            <div style={{ margin: '20%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>כתובת</p>
                <p style={{ fontSize: '95%' }}>{s.place}<br />{s.city}</p>
            </div>
            <div style={{ margin: '20%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>מספר מקומות</p>
                <p style={{ fontSize: '95%' }}>{s.size}</p>
            </div>
            <div style={{ margin: '20%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>מחיר האולם</p>
                <p style={{ fontSize: '95%' }}>{s.price}</p>
            </div>
        </div>}

        {p.codeCategory === '1' && (s.rangeDishes || s.rangePriceToDishes) && <div className='container3' style={{ gridTemplateColumns: "repeat(5, 1fr)", marginBottom: '3%', marginTop: '3%' }}>
            <div style={{ margin: '7%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>כתובת</p>
                <p style={{ fontSize: '95%' }}>{s.place}<br />{s.city}</p>
            </div>
            <div style={{ margin: '7%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>מספר מקומות</p>
                <p style={{ fontSize: '95%' }}>{s.size}</p>
            </div>
            <div style={{ margin: '7%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>מחיר האולם</p>
                <p style={{ fontSize: '95%' }}>{s.price}</p>
            </div>
            <div style={{ margin: '7%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>טווח מנות</p>
                <p style={{ fontSize: '95%' }}>{s.rangeDishes}</p>
            </div>
            <div style={{ margin: '7%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>טווח מחירים למנה</p>
                <p style={{ fontSize: '95%' }}>{s.rangePriceToDishes}</p>
            </div>
        </div>}

        {['2', '179', '182'].includes(p.codeCategory) && <div className='container3'>
            <div style={{ margin: '20%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>מחיר</p>
                <p style={{ fontSize: '95%' }}>{s.price} ש"ח</p>
            </div>
            <div style={{ margin: '20%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>מה כולל המחיר</p>
                <p style={{ fontSize: '95%' }}>{s.includePrice}</p>
            </div>
            <div style={{ margin: '20%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>מחיר לזמן נוסף</p>
                <p style={{ fontSize: '95%' }}>{s.priceAdd}</p>
            </div>
        </div>}

        {p.codeCategory === '3' && <div className='container3'>
            <div style={{ margin: '20%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>מחיר</p>
                <p style={{ fontSize: '95%' }}>{s.rangePrice} ש"ח</p>
            </div>
            <div style={{ margin: '20%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>מה כולל המחיר</p>
                <p style={{ fontSize: '95%' }}>{s.rangeDishes}</p>
            </div>
            <div style={{ margin: '20%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>מחיר לזמן נוסף</p>
                <p style={{ fontSize: '95%' }}>{s.rangeDishesKabalat}</p>
            </div>
        </div>}

        {p.codeCategory === '4' && <div className='container3'>
            <div style={{ margin: '20%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>יתרון</p>
                <p style={{ fontSize: '95%' }}>{s.adventage}</p>
            </div>
            <div style={{ margin: '20%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>{s.categoryCode != 30018 && s.categoryCode != 30019 ? "טווח מנות" : "טווח מגשים"}</p>
                <p style={{ fontSize: '95%' }}>{s.rangeDishes}</p>
            </div>
            <div style={{ margin: '20%' }}>
                <p style={{ fontSize: '110%', fontWeight: '400' }}>טווח מחירים</p>
                <p style={{ fontSize: '95%' }}>{s.rangePrice}</p>
            </div>
        </div>}

        {/* ------------------------------------------------------------------------------------------- */}

        <RefReady tag={<div className='k1 k11 animate__animated'></div>} event={'animate__bounceInLeft'}></RefReady>

        {s.mainImage && <Carousel pics={[...bussinessData.images, { id: 0, categoryCode: s.categoryCode, bussinessId: s.id, picture: s.mainImage }]}></Carousel>}
        {bussinessData.images?.length > 0 && !s.mainImage && <Carousel pics={[...bussinessData.images]}></Carousel>}


        {bussinessData.dates?.length > 0 && <div id='nullDates' style={{ height: '80vh', marginBottom: '20vh' }} className='backround'>
            <p style={{ fontSize: '130%', fontWeight: '500', paddingTop: '6%', marginBottom: '30px' }}>תאריכים פנויים בתקופה הקרובה</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            <Calender fullDatesH={fullDatesH()} categoryCode={s.categoryCode} bussinessId={s.id}></Calender>
        </div>}

        {bussinessData.songs?.length > 0 && bussinessData.songs[0].name && <div id='songsDid' style={{ marginBottom: '10%' }} className='backround'>
            <p style={{ fontSize: '130%', fontWeight: '500', paddingTop: '12%', marginBottom: '30px' }}>שירים לדוגמה</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px', marginBottom: '5%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            {bussinessData.songs.map((x, i) => (
                <div className='songs' key={i}>
                    <div style={{ display: 'inline-block' }}>{x.name}</div>
                    <audio style={{ marginTop: 'auto', marginBottom: 'auto' }} controls src={encodeURI(`${baseImageUrl}${x.song1.replace(/\\/g, '\/')}?format=avif`)}></audio>
                </div>
            ))}
            {/* <div style={{ width: '80%', height: '2px', backgroundColor: 'lightgrey', marginRight: '10%' }}></div> */}

        </div>}

        {bussinessData.lotOfItems.length > 0 && <div id='lotDivOf'><LotOfItems lotOfItems={bussinessData.lotOfItems} mishlochim={s.priceShipping} mishlochimNote={s.notesShipping} bussiness={{ name: s.name, mail: s.mail }} categoryCode={p.codeCategory}></LotOfItems>
            {/* <div style={{ width: '80%', height: '2px', backgroundColor: 'lightgrey', marginRight: '10%' }}></div> */}
        </div>}



        {bussinessData.songs?.length > 0 && !bussinessData.songs[0].name && <div id='kasher' style={{ height: '80vh', marginBottom: '1%' }} className='backround'>
            <p style={{ fontSize: '130%', fontWeight: '500', paddingTop: '2%', marginBottom: '30px' }}>כשרות</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px', marginBottom: '5%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            {bussinessData.songs.map((x, i) => (
                <img loading='lazy' key={i} style={{ width: '20%', height: '50vh', display: 'inline-block', marginLeft: '2%' }} src={encodeURI(`${baseImageUrl}${x.song1.replace(/\\/g, '\/')}?format=avif`)}></img>
            ))}
        </div>}


        {s.priceList?.length > 0 && <div id="priceListDiv" style={{ height: '80vh', marginBottom: '-5%' }}>
            {p.codeCategory === '3' && <p style={{ fontSize: '130%', fontWeight: '500', paddingTop: '1%', marginBottom: '30px' }}>מחירון</p>}
            {p.codeCategory === '4' && <p style={{ fontSize: '130%', fontWeight: '500', paddingTop: '1%', marginBottom: '30px' }}>תפריט ומחירון</p>}
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-30px', marginBottom: '5%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
            <img loading='lazy' style={{ width: '25%', height: '50vh', display: 'inline-block' }} src={encodeURI(`${baseImageUrl}${s.priceList.replace(/\\/g, '\/')}?format=avif`)} onClick={() => { setImageZoom(true) }}></img>
        </div>}


        {op && <Options options={bussinessData.options} setAddOpinion={setAddOpinion}></Options>}
        {/* <div style={{width:'80%', height:'2px', backgroundColor:'lightgrey', marginRight:'10%'}}></div> */}

        <div id='SendMailFOrBussiness'>
            <SendMailFOrBussiness mail={s.mail} name={s.name} numberToCall={s.numberToCall}></SendMailFOrBussiness>
        </div>


        {bussinessData.answers?.length > 0 && <div className='questions' id='Questions'>
            <p style={{ fontSize: '130%', fontWeight: '500', marginTop: '2%', marginBottom: '30px' }}>שאלות נפוצות</p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-35px', marginBottom: '5%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
            {bussinessData.answers.map((x, i) => (
                <div key={i} onClick={() => openAns.includes(i) ? setOpenAns(openAns.filter(a => a != i)) : setOpenAns([...openAns, i])}><div className='questionCon'>{x.questionCon}
                    <div style={{ float: 'left', marginLeft: '2%' }}><IconButton align="left" className='IconButton' size="small"> {openAns.includes(i) ? <CloseOutlined /> : <PlusOutlined />}</IconButton></div>
                </div>{openAns.includes(i) && <div className='answerCon animate__animated animate__fadeInDown'>{x.answerCon}</div>}</div>
            ))}
        </div>}

        {/* <p style={{ marginTop: '8%', fontWeight: '300' }}>אם יש הערות או דברים שהאולם רוצה להוסיף או להדגיש זה נכתב כאן<br></br>לדוגמא: כל האוכל בכשרות הבד"צ העדה החרדית ירושלים עם משגיח כשרות צמוד במטבח.</p> */}
        <BottomMenu></BottomMenu>

        {addOpinion && <Option categoryCode={s.categoryCode} bussinessId={s.id} set={setAddOpinion} setOP={setOP}></Option>}

        {openManag && <EnterManagger errorMessage={errorMessage} name={s.name} submit={passManageSubmit} set={setOpenManag}></EnterManagger>}

        {imageZoom && <div id='modal2' style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
            <div>
                <CloseOutlined onClick={() => setImageZoom(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%', color: 'white' }} />
                <img loading='lazy' className='animate__animated animate__zoomIn' style={{ height: '90vh', width: 'auto', display: 'inline-block', marginTop: '5vh' }} src={encodeURI(`${baseImageUrl}${s.priceList.replace(/\\/g, '\/')}?format=avif`)} onClick={() => { setImageZoom(true) }}></img>
            </div>
        </div>}

    </div>}

        {!bussinessData && <Wait></Wait>}
    </>
}

export default Bussiness



{/* <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%' }}>{s.name}</p>
        <div ref={ref10} style={{ transition: 'width 0.5s ease-in-out' }} className='underline3'></div>

        <p style={{ fontSize: '110%', fontWeight: '400', marginTop: '1%' }}>{s.description}</p>
        <div className='container3'>
            <div style={{ margin: '20%' }}>

                <p style={{ fontSize: '110%', fontWeight: '400' }}>כתובת</p>
                <p style={{ fontSize: '95%' }}>נהר הירדן 1, לב הרמה, בית שמש. אוטובוסים פנימיים בתוך בית שמש: קווים 13, 16, 11.</p>
            </div>
            <div style={{ margin: '20%' }}>

                <p style={{ fontSize: '110%', fontWeight: '400' }}>גודל</p>
                <p style={{ fontSize: '95%' }}>700 מקומות ישיבה כולל שולחנות, בד"כ מחולק 400 מקומות בגברים, 300 מקומות בנשים.</p>
            </div>
            <div style={{ margin: '20%' }}>

                <p style={{ fontSize: '110%', fontWeight: '400' }}>מחיר</p>
                <p style={{ fontSize: '95%' }}>מחיר לערב: 12,000. המחיר כולל כיסא כלה דגם בסיסי, ניתן לבחור כסאות כלה נוספים בתוספת תשלום.</p>
            </div>
        </div>

        <Carousel></Carousel>

        <div className='backround'>
        <p style={{ fontSize: '130%', fontWeight: '500', marginTop: '10%', marginBottom: '30px' }}>תאריכים פנויים בתקופה הקרובה</p>
        <div ref={ref30} style={{ transition: 'width 0.5s ease-in-out' }} className='underline3'></div>
        
        <Calender></Calender>
</div>




        <div style={{ position: 'relative' }}>
            <p style={{ position: 'absolute', left: '33%', top: '-15px', direction: 'ltr' }}>&#9733; &#9733; &#9733; &#9734; &#9734; (2)</p>
            <p style={{ fontSize: '130%', fontWeight: '500', marginTop: '10%', marginBottom: '30px' }}>4 המלצות וחוות דעת</p>
            <div ref={ref20} style={{ transition: 'width 0.5s ease-in-out' }} className='underline3'></div>

            {opinion.map((x, i) => (
                <div key={i} style={{ width: '70%', marginRight: '15%', textAlign: 'right', position: 'relative' }}>
                    <div style={{ width: '4.5rem', height: '4.5rem', fontSize: '120%', lineHeight: '2.5', color: 'white', position: 'absolute', margin: '1%', marginTop: '2%', marginRight: '2%', borderRadius: '50%', display: 'inline-block', backgroundColor: `${i % 2 == 0 ? '#c00000' : 'black'}`, border: 'black 1.5px solid', userSelect: 'none', textAlign: 'center' }}>{x.userName.charAt(0)}</div>
                    <div style={{ display: 'inline-block', marginRight: '8rem' }}>
                        <p style={{ fontWeight: '400', fontSize: '93%', display: 'inline-block' }}>{x.time}</p>
                        <div style={{ display: 'inline-block', position: 'absolute', left: '20%', top: '20%', direction: 'ltr', color: '#c00000' }}>&#9733; &#9733; &#9733; &#9734; &#9734;</div>
                        <p style={{ fontSize: '75%', marginBottom: '-6px', marginTop: '-8px' }}>{x.userName}</p>
                        <p style={{ fontSize: '95%', }}>{x.opinion}</p>
                    </div>
                    <div style={{ width: '100%', height: '1px', backgroundColor: '#c00000' }}></div>
                </div>
            ))}
            <div className='buton' onClick={() => setAddOpinion(true)}><ButtonRed text='הוספת חוות דעת'></ButtonRed></div>
        </div>
        <SendMailFOrBussiness></SendMailFOrBussiness>

        <p style={{ marginTop: '8%', fontWeight: '300' }}>אם יש הערות או דברים שהאולם רוצה להוסיף או להדגיש זה נכתב כאן<br></br>לדוגמא: כל האוכל בכשרות הבד"צ העדה החרדית ירושלים עם משגיח כשרות צמוד במטבח.</p>
         */}