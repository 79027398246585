import './Forms.css';
import { useState } from 'react';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { EditHowManySendEmail, SendEmailToBussiness } from '../../axios/api';
import { useSelector } from 'react-redux';
import logo from '../../../pic/logo/11.jpg';
import { PhoneOutlined } from '@ant-design/icons';

const SendMailFOrBussiness = (props) => {

    //--------------------------------start form---------------------------------
    const [newBusiness, setNewBusiness] = useState({ name: '', message: '', phoneNumber: '', email: '', bussinessMail: props.mail });
    const [errorMessage, setErrorMessage] = useState('');
    const [send, setSend] = useState(false)
    const [sendGood, setSendGood] = useState(false)
    const [think, setThink] = useState(false)

    const sBussiness = useSelector((state) => state.thisBussinessReducer)


    const handleNameChange = (e) => {
        const inputValue = e.target.value;
        // Validate: Only letters allowed
        if (/^[A-Za-zא-ת ]+$/.test(inputValue) || inputValue === '') {
            setNewBusiness({ ...newBusiness, name: inputValue });
        }
    };

    const handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        // Validate: Numeric input and valid phone number format
        if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
            setNewBusiness({ name: newBusiness.name, message: newBusiness.message, phoneNumber: inputValue, email: newBusiness.email });
        }
    };

    const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        // Validate: English letters, @ and - symbols, and valid email format
        if (/^[א-תA-Za-z@.0-9]+$/.test(inputValue) || inputValue === '') {
            setNewBusiness({ name: newBusiness.name, message: newBusiness.message, phoneNumber: newBusiness.phoneNumber, email: inputValue });
        }
    };

    const handleMassageChange = (e) => {
        setNewBusiness({ name: newBusiness.name, message: e.target.value, phoneNumber: newBusiness.phoneNumber, email: newBusiness.email });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage('');

        // Check if all fields are filled
        if (newBusiness.name === '' || newBusiness.message === '' || newBusiness.phoneNumber === '' || newBusiness.email === '') {
            setErrorMessage('כל השדות הן חובה');
        } else {

            if (!/^\d{10}$/.test(newBusiness.phoneNumber)) {
                setErrorMessage('מספר נייד לא תקין');
                return;
            }

            else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newBusiness.email)) {
                setErrorMessage('כתובת מייל לא תקינה');
                return;
            }

            else {
                setThink(true)
                var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;">שלום ל${props.name === "קול שמחה" ? "קול שמחה" : `עסק ${sBussiness.name}`}</p></div><br/> <br/> לקוח שלח אליכם פניה  <br/> <br/> <b>שם הלקוח: </b> ${newBusiness.name}. <br/> <b>מספר טלפון: </b> ${newBusiness.phoneNumber}<br/> <b>כתובת מייל: </b> ${newBusiness.email}<br/><br/> <b>תוכן ההודעה: </b><br/> ${newBusiness.message}<br/> <br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
                SendEmailToBussiness({ name: "", emailTo: props.name === "קול שמחה" ? 'K3137663@gmail.com' : sBussiness.mail, emailFrom: newBusiness.email, subject: "מיזם קול שמחה - לקוח שלח פניה", message: mes }).then(() => {
                    setSendGood(true)
                    if (props.name === "קול שמחה") {
                        props.sendGood()
                        props.set(false)
                    } else {
                        EditHowManySendEmail(String(sBussiness.categoryCode).split(',')[0], sBussiness.id).then((data) => { }).catch((error) => { console.error(error); });
                    }
                    setThink(false)
                }).catch((error) => {
                    setSend("תקלה בשליחת ההודעה, יש לנסות שנית")
                    setTimeout(() => {
                        setSend(false)
                    }, 40 * 100);
                    console.error(error);
                    setThink(false)
                });
            }
        }
    };

    return <div style={{ paddingTop: '3%' }}>
        <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: `${props.numberToCall ? '500px' : '450px'}`, borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '3%', position: 'relative', minHeight: 'max-content' }}>

            {props.name === "קול שמחה" && <div style={{ marginTop: '2vh', fontWeight: '400' }}>ליצירת קשר עם מזכירות קול שמחה ניתן לשלוח מייל לכתובת: k3137663@gmail.com</div>}

            {!sendGood && <><div className='titl' style={{ marginTop: '3%', fontSize: '120%' }}>צור קשר עם {props.name}</div>
                {props.name === "קול שמחה" && !props.text2 && <div style={{ marginBottom: '-1%' }}>יש לכם רעיונות לייעול, עיצות וטיפים לחתונה או עידכונים? נשמח לדעת!</div>}
                {props.name === "קול שמחה" && props.text2 && <div style={{ marginBottom: '-1%' }}>{props.text2}</div>}
                <form id='newBusinessForm' onSubmit={handleSubmit} style={{ marginTop: '4%', marginBottom: '4%' }}>
                    <textarea onChange={handleMassageChange} value={newBusiness.message} style={{ width: '700px', height: '100px', marginTop: '2%', padding: '0.5%', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '4%', maxHeight: '100px', borderRadius: '10px 10px 10px 10px', outlineColor: '#c00000', borderColor: 'black', maxWidth: '80%' }} placeholder={`תוכן ההודעה ${props.name === "קול שמחה" ? '' : '(מומלץ לציין את תאריך החתונה)'}`}></textarea>
                    <input className='input' placeholder="שם" autoComplete="on" type="text" value={newBusiness.name} onChange={handleNameChange} />
                    <input className='input' placeholder="נייד" autoComplete="on" type="text" value={newBusiness.phoneNumber} onChange={handlePhoneNumberChange} />
                    <input className='input' placeholder="דואר אלקטרוני" autoComplete="on" type="email" value={newBusiness.email} onChange={handleEmailChange} />
                    <span style={{ cursor: `${think ? 'wait' : 'pointer'}` }}><button className='input' id='submit' type="submit" style={{ pointerEvents: `${think ? 'none' : 'auto'}` }}>{props.name === "קול שמחה" ? 'שלח' : 'חזרו אלי'}</button></span>
                    {errorMessage && props.name !== "קול שמחה" && <p><p style={{ marginLeft: '0.5%', color: '#FFC000', display: 'inline-block', marginTop: '-10px' }}><AnnouncementIcon /></p>{errorMessage}</p>}
                    {errorMessage && props.name === "קול שמחה" && <p style={{ position: 'absolute', left: '20%', bottom: '10%', width: '20%' }}><p style={{ marginLeft: '0.5%', color: '#FFC000', display: 'inline-block', marginTop: '-10px' }}><AnnouncementIcon /></p>{errorMessage}</p>}
                    {/* "tel:+97223137663,,2,,3" */}
                    {/* {props.numberToCall && <div id='callToBussiness'><span style={{ backgroundColor: '#c00000', borderRadius: '50%', padding: '6px 9px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => { window.location.href = "tel:+97223137663"; }}><PhoneOutlined style={{ color: 'white', fontSize: '100%', fontWeight: '200' }} /></span><b>חייג:</b><div style={{ fontWeight: '400', direction: 'ltr', display: 'inline-block', marginRight: '7px' }}> 02-3137663 &gt; {props.numberToCall}</div></div>} */}

                </form></>}
            {sendGood && <div style={{ marginTop: '0%', position: 'absolute', height: '100%', textAlign: 'center', width: '100%' }}>
                <img loading='lazy' src={logo} style={{ height: '80%' }}></img>
                <div style={{ fontSize: '140%' }}>ההודעה נשלחה בהצלחה</div>
            </div>}
        </div>
        {send && <div className="send animate__animated animate__bounceInUp">
            <div style={{ lineHeight: '8vh', textAlign: 'center', fontWeight: '400' }}>{send}</div>
        </div>}
    </div>
}

export default SendMailFOrBussiness