import './AddToDataBase.css'
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import ButtonRed from "../../../global/button/button"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { CompleteBussinessData, CompleteThisBussiness } from "../../../2. redux/actions/thisBussinessActions"
import picError from '../../../../pic/animations/113.gif'
import { EditSongs } from '../../../axios/api'

const EditBussinessSongs = (props) => {

    const [songs, setSongs] = useState([])
    const [openDelSongs, setOpenDelSongs] = useState(false)
    const [delSongs, setDelSongs] = useState([]);
    const [think, setThink] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const isAddBus = props.isAddBus;

    const s = useSelector((state) => state.thisBussinessReducer);
    const n = useNavigate()
    const d = useDispatch()
    const baseImageUrl = "https://kolsimcha.co.il/images";

    useEffect(() => {
        window.scrollTo(0, 0);
        setSongs([...s.bussinessData.songs])
    }, [s])

    const changePics = (e) => {
        if (e.target.files.length) {
            var son = [...songs]; setErrorMessage('');
            const promises = []; // נאסוף את כל ההבטחות (promises)

            Array.from(e.target.files).forEach(x => {
                if (x.size >= 5200000) { setErrorMessage('לא ניתן להעלות שיר ארוך מ-5 דקות') }
                else if (son.length < 3) {
                    const promise = new Promise((resolve) => {
                        const fileReader = new FileReader();
                        fileReader.onload = () => {
                            const blob = new Blob([fileReader.result], { type: 'audio/mp3' }); // יצירת Blob מה-ArrayBuffer
                            son.push({ song1: blob, categoryCode: s.categoryCode, bussinessId: s.id, name: x.name.substring(0, 50) });
                            resolve(); // Resolve the promise after adding the song
                        };
                        fileReader.readAsArrayBuffer(x);
                    });
                    promises.push(promise); // הוסף את ההבטחה למערך
                }
            });

            Promise.all(promises).then(() => { setSongs(son); });
        }
    }

    const chooseDelSongs = () => {
        var son = [...songs];
        delSongs.forEach(sonToDel => { son = son.filter(x => sonToDel != x) });
        setSongs(son); setOpenDelSongs(false); setDelSongs([]);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setThink(true)
        if (!isAddBus) {
            var songsToAdd = [], songsDTO = songs;
            for (let i = 0; i < songsDTO.length; i++) {
                if (songsDTO[i].song1 instanceof Blob) { songsToAdd.push(new File([songsDTO[i].song1], "Songs\\" + songsDTO[i].name, { type: songsDTO[i].song1.type })); songsDTO[i].song1 = "\\Songs\\" + songsDTO[i].name; }
            }
            EditSongs(songsToAdd, songsDTO, s.id, s.categoryCode).then((data) => {
                d(CompleteBussinessData({ ...s.bussinessData, songs: data }))
                n(-1);
            }).catch((error) => { console.error(error); setThink(false); });
        }
        else {
            d(CompleteBussinessData({ ...s.bussinessData, songs: songs }))
            props.enter();
        }
    }

    return <div>
        <form onSubmit={handleSubmit}>
            {isAddBus && <div className='buton' onClick={() => { d(CompleteBussinessData({ ...s.bussinessData, songs: songs })); props.prev() }} style={{ position: 'absolute', top: '-5vh', right: '-40%', width: '8vh', zIndex: '5' }}><ButtonRed text='<--'></ButtonRed></div>}

            <p>הכנס שירים שלכם לדוגמא,</p>
            <p>שיר ארוך יותר מ-4 דקות לא יעלה</p>
            <p>שם הקובץ יראה גם כן באתר, ולכן יש לשנות את שם הקובץ לשם הרצוי לפני ההעלאתו</p>
            <input type="file" id="fileElem1" accept="audio/*" multiple onChange={changePics} style={{ display: 'none' }} />
            <input type="button" style={{ marginTop: '10%', marginRight: '10%', maxWidth: '60%' }} className="submit1 input" id="fileSelect1" onClick={() => { document.getElementById("fileSelect1").addEventListener("click", document.getElementById("fileElem1").click()) }} value="העלאת שירים" />
            <div title="מחק שירים" style={{ userSelect: 'none', marginRight: '3%' }} className="submit1 input in2" id="fileSelect2" onClick={() => { setOpenDelSongs(true) }}><DeleteOutlined /></div>

            <div id="fileList1" style={{ width: '90%', height: '20%', margin: '5%', marginTop: '2%' }}> {songs.length === 0 && <p>עדיין לא נבחרו שירים!</p>} {songs.map((x, i) => (<div key={i} className='songs'><span>{x.name}</span><audio controls='true' src={x.song1 instanceof Blob || x.song1 instanceof File ? URL.createObjectURL(x.song1) : baseImageUrl + x.song1} style={{ marginTop: 'auto', marginBottom: 'auto' }}></audio></div>))} </div>
            <p style={{ marginTop: '0px', float: 'right', marginRight: '17%', fontSize: '90%' }}>*עד 3 שירים, תומך בשירים בסיומת .mp3</p>
            {errorMessage && <p><img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}

            <div style={{ marginBottom: '25%' }}></div>
            <span style={{ cursor: `${think ? 'wait' : 'pointer'}` }}><button type="submit" className='buton' style={{ pointerEvents: `${think ? 'none' : 'auto'}`, width: '20%', marginTop: '-10%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto', minWidth: '20vh' }}><ButtonRed text={isAddBus ? 'המשך    -->' : 'סיים עריכה'}></ButtonRed></button></span>
        </form>

        {openDelSongs && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => { setOpenDelSongs(false); }} style={{ cursor: 'pointer', position: 'absolute', left: '5%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '95%', height: '95vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '2.5%', marginTop: '2.5vh' }}>
                    <div id='fileList12' style={{ marginTop: '5%', width: '60%', marginRight: 'auto', marginLeft: 'auto', maxHeight: '45vh', overflow: 'auto' }}>{songs.length === 0 && <p>עדיין לא נבחרו שירים!</p>} {songs.map((x, i) => (<div key={i} className='songs remove2' onClick={() => { if (!delSongs.includes(x)) { setDelSongs([...delSongs, x]) } }}><span>{x.name}</span><audio controls='true' src={x.song1 instanceof Blob || x.song1 instanceof File ? URL.createObjectURL(x.song1) : baseImageUrl + x.song1} style={{ marginTop: 'auto', marginBottom: 'auto' }}></audio></div>))}</div>

                    <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => { chooseDelSongs() }}><ButtonRed text='אישור'></ButtonRed></div>
                    <div id='fileList13' style={{ marginTop: '2%', maxWidth: '100%', maxHeight: '20vh', overflowX: 'auto', textOrientation: 'landscape' }}>{delSongs.map((x, i) => (<div key={i} className='songs'><span>{x.name}</span><audio controls='true' src={x.song1 instanceof Blob || x.song1 instanceof File ? URL.createObjectURL(x.song1) : baseImageUrl + x.song1} style={{ marginTop: 'auto', marginBottom: 'auto' }}></audio></div>))}</div>
                </div>
            </div>
        </div>}

    </div>
}

export default EditBussinessSongs