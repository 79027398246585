import './TaskList.css';
import { Fragment } from 'react';
import { DeleteOutlined, FileAddOutlined, PrinterOutlined } from '@ant-design/icons'
import { CarryOutOutlined } from '@ant-design/icons'
import { FileExclamationOutlined } from '@ant-design/icons'
import { CalendarOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import IconButton from '@mui/material/IconButton';
import { PlusOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';
import 'animate.css';
import ButtonRed from '../../global/button/button';
import BottomMenu from '../../global/bottomMenu/BottomMenu';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddPersonalPaid, ChangeReminders, DelGlobalTask, DelPersonalTask, DoTask, GetFinallTasks, GetpersonalPriceIdBy, NotDoTask, UpdatePersonalPaid } from '../../axios/api';
import AlertAddPersonalTask from './AlertAddPersonalTask';
import AlertDelete from '../../global/alert/AlertDelete';
import Login from '../10. login/Login';
import { HDate } from 'hebcal';
import { CompleteUser } from '../../2. redux/actions/userActions';
import RefReady from '../../global/ref';
import Alert from '../../global/alert/Alert';
import Search from '../../3. managger/Search';
import Wait from '../../global/Wait';
import picError from '../../../pic/animations/113.gif'
import { COMPLETEallTasks, COMPLETEtasksYes } from '../../2. redux/actions/tasksActions';
import Connection from '../11. connection/Connection';
import SideNavPersonalOrganizer from './SideNavPersonalOrganizer';

// import { useReactToPrint } from 'react-to-print';


const TaskList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'רשימת משימות לחתן ולכלה לתקופת האירוסין';
    // יצירת או עדכון תגית ה-description
    let metaDescription = document.querySelector("meta[name='description']");
    if (!metaDescription) { metaDescription = document.createElement('meta'); metaDescription.name = 'description'; document.head.appendChild(metaDescription); }
    metaDescription.content = "קבלו בחינם רשימת משימות מתאימה לצרכים המדויקים שלכם. המכילה את כל המשימות הדרושות לארגון חתונה בצורה הכי טובה שיש. רשימה נפרדת מותאמת לצד החתן / לצד הכלה, עם תאריכים מתאימים לביצוע המשימות - כך תגיעו מוכנים ורגועים לאירוע שלכם";
    // במעבר לעמוד הבא - מחזירים הכל לאיך שהיה קודם
    return () => { document.title = 'קול שמחה - הכל לארגון חתונה'; metaDescription.content = ''; };
  }, []);

  const navigate = useNavigate()
  const s = useSelector(a => a.userReducer)
  const allTasksSelector = useSelector(a => a.tasksReducer.allTasks)
  const taskYesSelector = useSelector(a => a.tasksReducer.tasksYes)
  const [weddingDate, setWeddingDate] = useState(false);
  const [allTasks, setAllTasks] = useState([]);
  const [tempAllTasks, setTempAllTasks] = useState([]);
  const d = useDispatch()
  const [reload, setReload] = useState(true);


  const [login, setLogin] = useState(false);
  const [connection, setConnection] = useState(false);
  const [openIs2, setOpenIs2] = useState(false);
  const [openChangeReminders, setOpenChangeReminders] = useState(false);
  const [openChangeRemindersIs, setOpenChangeRemindersIs] = useState(false);
  const [pays, setPays] = useState(false);
  const [pay, setPay] = useState();
  const [payChange, setPayChange] = useState();
  const [moreSum, setMoreSum] = useState(false);
  const [daysBetweenErusinToWedding, setDaysBetweenErusinToWedding] = useState(0);

  const [opUser, setOpUser] = useState(false)

  const [wait, setWait] = useState(false);

  // const [login, setLogin] = useState(false);
  // const [connection, setConnection] = useState(false);

  useEffect(() => {
    if (s.id) {
      setWait(true)
      setWeddingDate(Math.ceil((new Date(s.weddingDate) - new Date()) / (24 * 60 * 60 * 1000)));
    }
  }, [s]);

  useEffect(() => {
    if (s.id && s.reminders === 0 && !openChangeRemindersIs) {
      setTimeout(() => {
        setOpenChangeReminders(true)
        setOpenChangeRemindersIs(true)
      }, 1000 * 60 * 3);
    }
  }, []);

  const HebrewDaysNames = ['0', "א'", "ב'", "ג'", "ד'", "ה'", "ו'", "ז'", "ח'", "ט'", "י'", 'י"א', 'י"ב', 'י"ג', 'י"ד', 'ט"ו', 'ט"ז', 'י"ז', 'י"ח', 'י"ט', "כ'", 'כ"א', 'כ"ב', 'כ"ג', 'כ"ד', 'כ"ה', 'כ"ו', 'כ"ז', 'כ"ח', 'כ"ט', "ל'"];


  const formatDate = (date) => {
    const secularDate1 = date.split('.')
    return `${secularDate1[2]}-${secularDate1[1]}-${secularDate1[0]}`;
  };

  function convertToHebrewDate(date) {
    const secularDate1 = new Date(date);
    const hebrewDate = new HDate(secularDate1);
    const hebrewDateString = `${HebrewDaysNames[hebrewDate.getDate()]} ${hebrewDate.getMonthName('h')}`;
    return hebrewDateString;
  }

  const setAllTasksWithHDate = (data) => {
    // setReload(false)
    // if (allTasks.length <= 0) {
    const oneDay = 24 * 60 * 60 * 1000;
    var between;
    if (s.weddingDate !== null && s.erusinDate !== null) {
      between = Math.ceil((new Date(s.weddingDate) - new Date(s.erusinDate)) / oneDay)
      setDaysBetweenErusinToWedding(between)
    }
    for (let index = 0; index < data.length; index++) {
      if (data[index].erusinOrWedding === 'חתונה' && s.weddingDate !== null && s.erusinDate !== null) {
        if (data[index].date2 !== null)
          data[index].date = between - data[index].date2;
        if (data[index].isPersonal)
          data[index].date = between - data[index].date;
        if (data[index].date !== null) {
          var ddd = new Date(new Date(s.erusinDate).getTime() + data[index].date * (24 * 60 * 60 * 1000))
          if (new Date(ddd).getDay() === 6)
            ddd = new Date(new Date(ddd).getTime() + oneDay)
          data[index].hDate = convertToHebrewDate(formatDate(new Date(ddd).toLocaleDateString()));
        }
      }
      // else {
      //   data[index].hDate = 777
      // }
    }
    var arr = data.sort((a, b) => a.date > b.date ? 1 : -1)
    setWait(false)
    window.scrollTo(0, 0);
    setAllTasks(arr)
    setTempAllTasks(arr)
    d(COMPLETEallTasks(arr))
    // setReload(true)
    var did = 0
    data.forEach(element => {
      if (element.isDid)
        did = did + 1
    });
    setTaskYes(did)
    setTaskNo(data.length - did)
    d(COMPLETEtasksYes(did))
    // }
  }

  useEffect(() => {
    if (allTasksSelector.length > 0) {
      setWait(false)
      window.scrollTo(0, 0);
      setAllTasks(allTasksSelector)
      setTaskYes(taskYesSelector)
      setTaskNo(allTasksSelector.length - taskYesSelector)
    }
    else if (s.id) {
      GetFinallTasks(s.id).then((data) => {
        setAllTasksWithHDate(data)
      }).catch((error) => { console.error(error); });
    }
  }, [s]);

  // -----------------------start ref-----------------------------
  const [isVisible, setIsVisible] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true, // Triggers the animation only once
    threshold: 0.1, // Percentage of visibility needed to trigger the animation
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);
  // -----------------------end ref-----------------------------

  // -----------------------start table-----------------------------
  const [open, setOpen] = useState([]);
  const [isFirst, setIsFirst] = useState(true)
  var num;

  const [taskYes, setTaskYes] = useState(0);
  const [taskNo, setTaskNo] = useState(0);
  // -----------------------end table-----------------------------

  const [alert, setAlert] = useState(false)

  const filterDid = () => {
    var t = tempAllTasks
    setAllTasks(false)
    setTimeout(() => { setAllTasks(t.filter(a => a.isDid)) }, 1)
  }

  const filterNotDid = () => {
    var t = tempAllTasks
    setAllTasks(false)
    setTimeout(() => { setAllTasks(t.filter(a => !a.isDid)) }, 1)
  }

  const filterAllDid = () => {
    setAllTasks(false)
    setTimeout(() => { setAllTasks(tempAllTasks) }, 1)
  }


  const [del, setDel] = useState(false)
  const delTask = () => {
    var d1 = del
    setDel(false)
    var tas = allTasks.filter(a => a.id == d1);
    var arr = allTasks.filter(a => a.id != d1)
    setAllTasks(arr);
    if (tas[0].isPersonal)
      DelPersonalTask(d1).then((data) => { d(COMPLETEallTasks(arr)); if (tas.isDid === true) { d(COMPLETEtasksYes(taskYes - 1)); setTaskYes((p) => p - 1) } else { setTaskNo((p) => p - 1) } }).catch((error) => { console.error(error); });
    else
      DelGlobalTask(d1, s.id).then((data) => { d(COMPLETEallTasks(arr)); if (tas.isDid === true) { d(COMPLETEtasksYes(taskYes - 1)); setTaskYes((p) => p - 1) } else { setTaskNo((p) => p - 1) } }).catch((error) => { console.error(error); });
  }


  const notDo = (itemId, isChatanOrKalaOr2, e) => {
    e.target.checked = true
    if (isChatanOrKalaOr2 == 2)
      setOpenIs2([itemId, e])
    else
      NotDoTask({ id: itemId, tableName: 'tasksDetails', taskId: itemId, userId: s.id }).then((data) => { }).catch((error) => { console.error(error); });
  }

  const [send, setSend] = useState(false)
  const sendGood = () => {
    setAlert2(false)
    setSend("ההודעה נשלחה בהצלחה !!!")
    setTimeout(() => {
      setSend(false)
    }, 40 * 100);
  }



  const table = (tassksForTable) => {
    return (<>
      {/* ------------------------start table-------------------------- */}
      {
        <div>{tassksForTable && <table ref={ref} style={{ marginBottom: '2%' }} className="animate__animated animate__bounceInUp">
          <thead>
            <tr className={`${isFirst ? "first" : ""}`}>
              <th style={{ width: '13%' }}></th>
              <th style={{ width: '80%' }}></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tassksForTable.map((task, i) => (
              <Fragment key={i}>
                <tr className={`${i % 2 == 0 ? "class1 class3" : "class2 class3"}`} style={{ color: `${s.weddingDate !== null && s.weddingDate !== undefined && !task.isDid && ((task.erusinOrWedding === "חתונה" && daysBetweenErusinToWedding - task.date >= weddingDate) || (task.erusinOrWedding !== "חתונה" && s.erusinDate - task.date <= 0)) ? "#993838" : "black"}`, height: '9vh', cursor: 'pointer', userSelect: 'text' }} onClick={() => { if (open.includes(i)) { setOpen(open.filter((x) => x != i)); document.getElementsByTagName("tr")[num - 1].classList.remove("open"); document.getElementsByTagName("tr")[num - 1].classList.add("class3") } else setOpen([...open, i]) }}//row={row}
                  // onMouseEnter={(event) => { num = Array.from(document.getElementsByTagName("tr")).indexOf(event.currentTarget) + 1 }}
                  onMouseOver={(event) => {
                    setIsFirst(false); if (open.includes(i)) {
                      event.currentTarget.classList.add("open");
                      event.currentTarget.classList.remove("class3");
                      num = Array.from(document.getElementsByTagName("tr")).indexOf(event.currentTarget) + 1
                      if (document.getElementsByTagName("tr").length > num) {
                        document.getElementsByTagName("tr")[num].classList.add("openTask")
                        document.getElementsByTagName("tr")[num].classList.remove("task")
                      }
                    }
                  }}
                  onMouseLeave={(event) => {
                    setIsFirst(true); if (open.includes(i)) {
                      event.currentTarget.classList.remove("open");
                      event.currentTarget.classList.add("class3");
                      if (document.getElementsByTagName("tr").length > num) {
                        document.getElementsByTagName("tr")[num].classList.remove("openTask")
                        document.getElementsByTagName("tr")[num].classList.add("task")
                      }
                    }
                  }}>
                  {task.isDid && <td><div style={{ fontSize: '140%', paddingRight: '20%' }} >&#10003;</div></td>}
                  {!task.isDid && <td>{task.hDate}</td>}
                  <td>{task.name}</td>
                  <td><IconButton align="left" className='IconButton' size="small">
                    {open.includes(i) ? <CloseOutlined /> : <PlusOutlined />}
                  </IconButton></td>
                </tr>
                {open.includes(i) &&
                  <tr style={{ fontWeight: '300' }} className={`${i % 2 == 0 ? "class1 task w3-animate-top animate__animated" : "class2 task w3-animate-top animate__animated"}`}
                    // onMouseEnter={(event) => { num = Array.from(document.getElementsByTagName("tr")).indexOf(event.currentTarget) - 1 }}
                    onMouseOver={(event) => {
                      setIsFirst(false);
                      num = Array.from(document.getElementsByTagName("tr")).indexOf(event.currentTarget) - 1;
                      event.currentTarget.classList.add("openTask");
                      event.currentTarget.classList.remove("task");
                      document.getElementsByTagName("tr")[num].classList.add("open")
                      document.getElementsByTagName("tr")[num].classList.remove("class3")
                    }}
                    onMouseLeave={(event) => {
                      setIsFirst(true);
                      event.currentTarget.classList.remove("openTask");
                      event.currentTarget.classList.add("task");
                      document.getElementsByTagName("tr")[num]?.classList.remove("open")
                      document.getElementsByTagName("tr")[num]?.classList.add("class3")
                    }}>
                    <td></td>
                    <td style={{ textAlign: 'center', paddingTop: '4%', userSelect: 'auto', color: 'rgb(128, 102, 102)' }}>
                      <IconButton style={{ float: 'left', marginTop: '-3%', marginLeft: '-8%' }} align="left" className='IconButton' size="large" onClick={() => { handlePrint(i, task) }}><PrinterOutlined /></IconButton>
                      {!task.explanation && <div>{task.explanation}</div>}{task.explanation && <div>{task.explanation.split('.,!')[0]}</div>}<br></br><br></br>
                      {task.tasks && <div style={{ marginBottom: '100px', marginRight: '-20%' }}>{task.tasks.map((item, index) => (<div key={index}>
                        <label className="checkbox-container" style={{ lineHeight: '20px' }}><input type="checkbox" className="checkbox1" onClick={(e) => { if (s.id) { e.target.checked ? DoTask({ tableName: 'tasksDetails', taskId: item.id, userId: s.id }).then((data) => { }).catch((error) => { console.error(error); }) : notDo(item.id, task.isChatanOrKalaOr2, e) } }} name={tassksForTable[i][index]} defaultChecked={item.isDid ? true : false} /><span className="checkbox-custom" style={{ marginTop: '-5px' }}>&#10003;</span> &nbsp; {item.name}</label><br></br><div style={{ width: '100%', height: '5px' }}>&nbsp;</div>
                      </div>))}</div>}
                      <div style={{ width: '100%', marginTop: '20px' }}></div>
                      <div className='del' onClick={() => { if (s.id) { setDel(task.id) } }} style={{ cursor: 'pointer', fontSize: '80%' }}><DeleteOutlined /> {task.isPersonal ? '' : `לא שייך אלי -`}{document.getElementsByClassName("mainDivTasks")[0] && document.getElementsByClassName("mainDivTasks")[0].offsetWidth < 1300 && !task.isPersonal && <><br></br><span>&nbsp; &nbsp;</span></>} מחק מטלה</div>
                      {task.button && <div className='buton' style={{ width: '20%', position: 'absolute', left: '28%', bottom: '25px', minWidth: 'max-content' }} onClick={() => { task.explanation && task.explanation.split('.,!')[1] ? window.open(task.explanation.split('.,!')[1]) : task.categoryCode !== 0 ? navigate(`/allCards/${task.categoryCode}/${task.name}/${'ארגונית אישית'}`) : setAlert2(true) }}><ButtonRed text={task.button} ></ButtonRed></div>}
                      <div className='buton' style={{ width: '20%', position: 'absolute', left: '6%', bottom: '25px', minWidth: 'max-content' }} onClick={() => {
                        if (s.id) {
                          if (task.isChatanOrKalaOr2 == 2 && task.isDid)
                            setOpenIs2({ task: task, num: num, i: i })
                          else {
                            if (task.paysId) {
                              GetpersonalPriceIdBy(task.paysId, s.id).then((data) => {
                                setPays({ task: task, num: num, i: i, data: data })
                              }).catch((error) => console.error(error))
                            }
                            else {
                              document.getElementsByTagName("tr")[num].classList.remove("open")
                              document.getElementsByTagName("tr")[num].classList.add("class3")
                              task.isDid ? setTaskNo(taskNo + 1) : setTaskNo(taskNo - 1);
                              task.isDid ? setTaskYes(taskYes - 1) : setTaskYes(taskYes + 1);
                              task.isDid = !task.isDid
                              task.isDid ? DoTask({ tableName: task.isPersonal ? "personalTasks" : 'tasks', taskId: task.id, userId: s.id }).then((data) => { d(COMPLETEtasksYes(taskYes + 1)) }).catch((error) => { console.error(error); }) : NotDoTask({ tableName: task.isPersonal ? "personalTasks" : 'tasks', taskId: task.id, userId: s.id }).then((data) => { d(COMPLETEtasksYes(taskYes - 1)) }).catch((error) => { console.error(error); });;
                              setOpen(open.filter((x) => x != i));
                            }
                          }
                        } else { setOpUser(true) }
                      }}><ButtonRed text={`${task.isDid ? "עדיין לא ביצעתי" : "ביצעתי"}`}></ButtonRed></div>
                    </td>
                    <td></td>
                  </tr>}
              </Fragment>
            ))}
          </tbody>
        </table>}
          {/* ------------------------end table-------------------------- */}
        </div>
      }
    </>)
  }



  const handlePrint = (i, task) => {

    // var sum = 0
    // open.forEach(element => {
    //   if (element <= i)
    //     sum = sum + 1
    // });
    // const allDivs = [document.getElementsByTagName("tr")[i + sum], document.getElementsByTagName("tr")[1 + i + sum]]

    const printWindow = window.open('', 'הדפסת מטלה');
    printWindow.document.open();
    printWindow.document.title = 'הדפסת מטלה';

    var div = document.createElement('div')
    div.style.width = '100%';
    div.style.textAlign = 'center';
    div.style.marginTop = '10%';
    div.innerText = task.name
    // div.innerText = allDivs[0].children[1].innerText
    div.style.fontWeight = '400';
    div.style.fontSize = '130%';
    div.style.fontFamily = 'Segoe UI';

    var div2 = document.createElement('div')
    div2.style.marginTop = '5%';
    div2.style.fontFamily = 'Segoe UI';
    div2.style.fontWeight = '300';
    div2.style.fontSize = '80%';
    div2.style.direction = 'rtl';
    debugger
    // div2.innerText = allDivs[1].children[1].children[1].innerText
    div2.innerText = task.explanation;
    if (task.tasks !== null && task.tasks.length > 0) {
      div2.innerHTML += '<hr></hr>'
      task.tasks.forEach(t => {
        div2.innerHTML += `<li>${t.name}</li>`
        // div2.innerText += t.name;
      });
      div2.innerHTML += '<hr></hr>'
      // div2.innerHTML += allDivs[1].children[1].children[4].innerHTML
    }

    div.appendChild(div2)

    printWindow.document.appendChild(div)

    // printWindow.document.appendChild(div2)
    // printWindow.document.writeln(`
    // ----------------------------------------------------------------------------------------------------------------------- ${allDivs[0].children[1].innerText} 
    // `)
    // printWindow.document.writeln(`
    //                        ${allDivs[1].children[1].children[1].innerText}
    // `)
    // // printWindow.document.write(`
    // ${task.name}
    //  *** ${task.explain}
    // ${task.tasks}
    // `);
    printWindow.document.close();
    printWindow.print();
    setTimeout(() => {
      printWindow.close();
    }, 200);
  };


  const [alert2, setAlert2] = useState(false)


  const funcSearch = (inp) => {
    setAllTasks(tempAllTasks.filter(a => a.name.includes(inp)))
    window.scrollTo(0, 0);
  }


  return <div className='mainDivTasks'>
    <SideNavPersonalOrganizer page={1} taskYes={taskYes} taskNo={taskNo}></SideNavPersonalOrganizer>

    {wait && <Wait></Wait>}
    {tempAllTasks?.length > 0 && <Search funcSearch={funcSearch} txt={'ברשימת המשימות'}></Search>}

    {allTasks.length === 0 && tempAllTasks.length > 0 && <>
      <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%', marginBottom: '30px' }}>רשימת משימות</p>
      <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-25px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
      <div style={{ margin: '7%' }}>לא נמצאו תוצאות</div>
    </>}

    {/* -----------------------start side menu----------------------------- */}
    {s.id && <div id="mySidenav1" className="sidenav1">
      <div id="about" onClick={() => setAlert(true)}><FileAddOutlined className='icons' />
        הוספת מטלה אישית</div>
      <div id="blog" onClick={() => filterDid()}><CarryOutOutlined className='icons' />
        הצג את המשימות שבוצעו</div>
      <div id="projects" onClick={() => filterNotDid()}><FileExclamationOutlined className='icons' />
        הצג את המשימות שצריך לבצע</div>
      <div id="contact" onClick={() => filterAllDid()}><CalendarOutlined className='icons' />
        הצג את כל המשימות</div>
    </div>}
    {!s.id && <div id="mySidenav1" className="sidenav1">
      <div id="about"><FileAddOutlined className='icons' />
        הוספת מטלה אישית</div>
      <div id="blog"><CarryOutOutlined className='icons' />
        הצג את המשימות שבוצעו</div>
      <div id="projects"><FileExclamationOutlined className='icons' />
        הצג את המשימות שצריך לבצע</div>
      <div id="contact"><CalendarOutlined className='icons' />
        הצג את כל המשימות</div>
    </div>}
    {/* // -----------------------end side menu----------------------------- */}

    {s.id && weddingDate > 0 && <div style={{ position: 'fixed', left: '5%', marginTop: '-1%', backgroundColor: 'white', zIndex: '20' }}>{s.chatanOrKala}, בעוד {weddingDate} ימים החתונה!</div>}

    {/* ---------------------------------------------------------------------------------------------------------
                                        begin table
--------------------------------------------------------------------------------------------------------- */}
    {allTasks && reload && allTasks.filter(a => a.erusinOrWedding === 'אירוסין').length > 0 && <><p style={{ fontSize: '150%', fontWeight: '500', marginTop: '10%', marginBottom: '30px' }}>רשימת משימות לקראת האירוסין</p>
      <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-25px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
      <div style={{ marginBottom: '3%' }}></div>
      <div style={{ marginBottom: '5%' }}> {table(allTasks.filter(a => a.erusinOrWedding === 'אירוסין'))} </div></>}

    {allTasks && reload && allTasks.filter(a => a.erusinOrWedding === 'חתונה').length > 0 && <>
      <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '9%', marginBottom: '30px' }}>רשימת משימות לקראת החתונה</p>
      <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-25px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
      <div style={{ marginBottom: '3%', width: '50%', marginRight: '25%', marginTop: '2%' }}>התקופה בין האירוסין לחתונה עמוסה במשימות מרובות וחשובות, מומלץ להיות מאורגנים מראש ולבצע בהקדם את כל מה שאפשר, כדי שישאר זמן להכנה רגשית ורוחנית על מנת להגיע לאירוע ביישוב הדעת. <span>לפיכך התזכורות יגיעו אליכם מוקדם ככל האפשר.</span></div>
      {new Date(s.weddingDate) < new Date() && <div style={{ marginBottom: '3%', width: '50%', marginRight: '25%', marginTop: '2%' }}>תאריך החתונה שלכם עבר - לא ניתן להציג חלק רב מהמשימות, <br />במקרה ותאריך החתונה שלכם עדיין לא עבר, תוכלו לעדכן את תאריך החתונה החדש באיזור האישי שלכם.</div>}
      <div> {table(allTasks.filter(a => a.erusinOrWedding === 'חתונה'))} </div></>}

    {new Date(s.weddingDate) < new Date() && allTasks.length <= 0 && <>
      <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '9%', marginBottom: '30px' }}>רשימת משימות לקראת החתונה</p>
      <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-25px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>
      <div style={{ marginBottom: '15%', width: '50%', marginRight: '25%', marginTop: '8%' }}>תאריך החתונה שלכם עבר - לא ניתן להציג את המשימות, <br />במקרה ותאריך החתונה שלכם עדיין לא עבר, תוכלו לעדכן את תאריך החתונה החדש באיזור האישי שלכם.</div>
    </>}
    {/* ---------------------------------------------------------------------------------------------------------
                                        end table
--------------------------------------------------------------------------------------------------------- */}


    {alert && <AlertAddPersonalTask reminder={s.reminders} set={setAlert} setAllTasks={setAllTasksWithHDate}></AlertAddPersonalTask>}
    {del && <AlertDelete set={setDel} func={delTask} text={'מטלה'}></AlertDelete>}

    {!s.id && tempAllTasks.length <= 0 && <>{allTasks.length > 0 && <div style={{ marginTop: '-15%' }}></div>}
      {allTasks.length <= 0 && <><p style={{ fontSize: '150%', fontWeight: '500', marginTop: '9%', marginBottom: '30px' }}>רשימת משימות לקראת החתונה</p>
        <RefReady tag={<div className='animate__animated' style={{ width: '75px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginTop: '-25px' }}></div>} event={'animate__lightSpeedInRight'}></RefReady></>}
      <div style={{ width: '100%', Height: '20vh', paddingBottom: '5vh', display: 'block', marginTop: '5%', fontSize: '110%' }}><span style={{ fontWeight: '400' }}>הרשמו למערכת בכדי לקבל רשימה מותאמת אישית לצרכים שלכם,<br />מסודרת עם תאריכים לפי תאריך החתונה שלכם, <br />עם אפשרות לסמן את המשימות שכבר ביצעתם<br />ואפשרות להוסיף משימות אישיות בהתאם לצרכים שלכם.<br /><br /><br /></span>
        <div style={{ width: '10%', display: 'inline-block' }} onClick={() => setLogin(true)}><ButtonRed text='התחבר'></ButtonRed></div>
        <div style={{ width: '10%', display: 'inline-block' }} onClick={() => setConnection(true)}><ButtonRed text='הירשם'></ButtonRed></div>

        {allTasks.length <= 0 && <div style={{ width: '40%', marginTop: '10vh', marginRight: '30%', position: 'center' }}><p style={{ display: 'block', fontWeight: '400' }}>רשימת משימות כללית - לקריאה בלבד:</p>
          <div className='buton1' style={{ width: '49%', marginTop: '2%', display: 'inline-block' }} onClick={() => GetFinallTasks(-1).then((data) => { var arr = [...data.filter(a => a.date !== null).sort((a, b) => a.date > b.date ? 1 : -1), ...data.filter(a => a.date2 !== null).sort((a, b) => a.date2 < b.date2 ? 1 : -1)]; setAllTasks(arr); setTempAllTasks(arr); window.scrollTo(0, 0); }).catch((error) => { console.error(error); })}><ButtonRed text='למשפחת החתן'></ButtonRed></div>
          <div className='buton1' style={{ width: '49%', marginTop: '2%', marginRight: '2%', display: 'inline-block' }} onClick={() => GetFinallTasks(-2).then((data) => { var arr = [...data.filter(a => a.date !== null).sort((a, b) => a.date > b.date ? 1 : -1), ...data.filter(a => a.date2 !== null).sort((a, b) => a.date2 < b.date2 ? 1 : -1)]; setAllTasks(arr); setTempAllTasks(arr); window.scrollTo(0, 0); }).catch((error) => { console.error(error); })}><ButtonRed text='למשפחת הכלה'></ButtonRed></div>
        </div>}

      </div></>}

    {login && <Login setLogin={setLogin}></Login>}
    {connection && <Connection set={setConnection}></Connection>}

    {openIs2 && <div id='modal2'>
      <div className='animate__animated animate__zoomIn'>
        <CloseOutlined className='close2' onClick={() => setOpenIs2(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
        <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '40vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '30vh' }}>
          <div className='titl' style={{ marginTop: '5%', fontSize: '120%' }}>מטלה זו משותפת לשני הצדדים, יתכן וצד ה{`${s.chatanOrKala == "כלה" ? 'חתן' : 'כלה'}`} ביצע את המטלה.</div>
          <div style={{ marginTop: '-4%', fontSize: '110%', marginBottom: '5%' }}>האם בכל זאת תרצו לבטל את ביצוע המטלה?</div>
          <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => {
            if (openIs2[0]?.i != undefined) {
              if (openIs2[0].task.paysId) {
                GetpersonalPriceIdBy(openIs2[0].task.paysId, s.id).then((data) => {
                  setPays({ task: openIs2[0].task, num: openIs2[0].num, i: openIs2[0].i, data: data })
                }).catch((error) => console.error(error))
              }
              else {
                document.getElementsByTagName("tr")[openIs2[0].num].classList.remove("open")
                document.getElementsByTagName("tr")[openIs2[0].num].classList.add("class3")
                openIs2[0].task.isDid ? setTaskNo(taskNo + 1) : setTaskNo(taskNo - 1);
                openIs2[0].task.isDid ? setTaskYes(taskYes - 1) : setTaskYes(taskYes + 1);
                openIs2[0].task.isDid = !openIs2[0].task.isDid
                openIs2[0].task.isDid ? DoTask({ tableName: openIs2[0].task.isPersonal ? "personalTasks" : 'tasks', taskId: openIs2[0].task.id, userId: s.id }).then((data) => { }).catch((error) => { console.error(error); }) : NotDoTask({ tableName: openIs2[0].task.isPersonal ? "personalTasks" : 'tasks', taskId: openIs2[0].task.id, userId: s.id }).then((data) => { }).catch((error) => { console.error(error); });;
                setOpen(open.filter((x) => x != openIs2[0].i));
              }
            }
            else {
              NotDoTask({ id: openIs2[0], tableName: 'tasksDetails', taskId: openIs2[0], userId: s.id }).then((data) => { }).catch((error) => { console.error(error); });
            }
            setOpenIs2(false)
            openIs2[1].target.checked = false
          }}><ButtonRed text='אישור'></ButtonRed></div>
        </div>
      </div>
    </div>}


    {pays && !pays.task.isDid && <div id='modal2'>
      <div className='animate__animated animate__zoomIn'>
        <CloseOutlined className='close2' onClick={() => setPays(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
        <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '60vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '20vh' }}>

          {pays.data <= 0 && <><div className='titl' style={{ marginTop: '5%', fontSize: '120%', marginBottom: '3%' }}>המערכת מנהלת עבורכם אירגון הוצאות</div>
            <div style={{ fontSize: '110%', marginBottom: '5%' }}>כמה הוצאתם עבור {pays.task.name}?</div></>}
          {pays.data > 0 && <><div className='titl' style={{ marginTop: '4%', fontSize: '120%', marginBottom: '2%' }}>שמור במערכת כי עבור {pays.task.name} סכום הוצאתכם הוא: {pays.data}</div>
            <div style={{ fontSize: '110%', marginBottom: '2%' }}>ברצונכם לשנות את סכום ההוצאה? או להוסיף עליו?</div>
            <div style={{ backgroundColor: 'black', marginBottom: '4%', width: '370px', padding: '1%', marginRight: 'auto', marginLeft: 'auto' }}>
              <div className={payChange === true ? 'or orClick' : 'or'} style={{ display: 'inline-block', cursor: 'pointer' }} onClick={(e) => { setPayChange(true); document.getElementsByClassName("orClick")[0]?.classList.remove("orClick"); e.target.classList.add("orClick") }}>לשנות</div>
              <div className={payChange === false ? 'or orClick' : 'or'} style={{ display: 'inline-block', marginRight: '10px', cursor: 'pointer' }} onClick={(e) => { setPayChange(false); document.getElementsByClassName("orClick")[0]?.classList.remove("orClick"); e.target.classList.add("orClick") }}>להוסיף</div>
            </div></>}

          <input className='input20' style={{ width: '400px' }} onClick={(e) => e.target.style.width = '80%'} onBlur={(e) => e.target.style.width = '20%'} placeholder={'הכנס סכום'} autoComplete="on" type="text" value={pay} onChange={(e) => { if ((/^[0-9]*$/.test(e.target.value) && e.target.value < 922337203685477) || e.target.value === '') { setPay(e.target.value) } }} />
          <div className='buton1' style={{ width: '15%', marginRight: 'auto', marginLeft: 'auto', marginTop: '5%' }} onClick={() => {
            if (pays.data === -1) {
              AddPersonalPaid({ pricePaid: pay, paysId: pays.task.paysId, userId: s.id }).then((data) => { }).catch((error) => console.error(error))
              document.getElementsByTagName("tr")[pays.num].classList.remove("open")
              document.getElementsByTagName("tr")[pays.num].classList.add("class3")
              pays.task.isDid ? setTaskNo(taskNo + 1) : setTaskNo(taskNo - 1);
              pays.task.isDid ? setTaskYes(taskYes - 1) : setTaskYes(taskYes + 1);
              pays.task.isDid = !pays.task.isDid
              pays.task.isDid ? DoTask({ tableName: pays.task.isPersonal ? "personalTasks" : 'tasks', taskId: pays.task.id, userId: s.id }).then((data) => { }).catch((error) => { console.error(error); }) : NotDoTask({ tableName: pays.task.isPersonal ? "personalTasks" : 'tasks', taskId: pays.task.id, userId: s.id }).then((data) => { }).catch((error) => { console.error(error); });;
              setOpen(open.filter((x) => x != pays.i));
              setPays(false)
            }
            else if (pay > 0) {
              var sum;
              payChange ? sum = pay : sum = Number(pay) + Number(pays.data)
              if (sum > 922337203685477)
                setMoreSum(true)
              else {
                UpdatePersonalPaid(pays.task.paysId, sum, s.id).then((data) => { }).catch((error) => console.error(error))
                document.getElementsByTagName("tr")[pays.num].classList.remove("open")
                document.getElementsByTagName("tr")[pays.num].classList.add("class3")
                pays.task.isDid ? setTaskNo(taskNo + 1) : setTaskNo(taskNo - 1);
                pays.task.isDid ? setTaskYes(taskYes - 1) : setTaskYes(taskYes + 1);
                pays.task.isDid = !pays.task.isDid
                pays.task.isDid ? DoTask({ tableName: pays.task.isPersonal ? "personalTasks" : 'tasks', taskId: pays.task.id, userId: s.id }).then((data) => { }).catch((error) => { console.error(error); }) : NotDoTask({ tableName: pays.task.isPersonal ? "personalTasks" : 'tasks', taskId: pays.task.id, userId: s.id }).then((data) => { }).catch((error) => { console.error(error); });;
                setOpen(open.filter((x) => x != pays.i));
                setPays(false)
              }
            }
          }}><ButtonRed text='אישור'></ButtonRed></div>
        </div>
      </div>
    </div>}


    {pays && pays.task.isDid && <div id='modal2'>
      <div className='animate__animated animate__zoomIn'>
        <CloseOutlined className='close2' onClick={() => setPays(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
        <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '60vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '20vh' }}>

          <div className='titl' style={{ marginTop: '4%', fontSize: '120%', marginBottom: '2%' }}>שמור במערכת כי עבור {pays.task.name} סכום הוצאתכם הוא: {pays.data}</div>
          <div style={{ fontSize: '110%', marginBottom: '2%' }}>האם למחוק את סכום ההוצאה?</div>
          <div style={{ backgroundColor: 'black', marginBottom: '4%', width: '370px', padding: '1%', marginRight: 'auto', marginLeft: 'auto' }}>
            <div className={payChange === true ? 'or orClick' : 'or'} style={{ display: 'inline-block', cursor: 'pointer' }} onClick={(e) => { setPayChange(true); document.getElementsByClassName("orClick")[0]?.classList.remove("orClick"); e.target.classList.add("orClick") }}>למחוק</div>
            <div className={payChange === false ? 'or orClick' : 'or'} style={{ display: 'inline-block', marginRight: '10px', cursor: 'pointer' }} onClick={(e) => { setPayChange(false); document.getElementsByClassName("orClick")[0]?.classList.remove("orClick"); e.target.classList.add("orClick") }}>לא למחוק</div>
          </div>

          <div className='buton1' style={{ width: '15%', marginRight: 'auto', marginLeft: 'auto', marginTop: '5%' }} onClick={() => {
            if (payChange) {
              UpdatePersonalPaid(pays.task.paysId, 0, s.id).then((data) => { }).catch((error) => console.error(error))
            }
            document.getElementsByTagName("tr")[pays.num].classList.remove("open")
            document.getElementsByTagName("tr")[pays.num].classList.add("class3")
            pays.task.isDid ? setTaskNo(taskNo + 1) : setTaskNo(taskNo - 1);
            pays.task.isDid ? setTaskYes(taskYes - 1) : setTaskYes(taskYes + 1);
            pays.task.isDid = !pays.task.isDid
            pays.task.isDid ? DoTask({ tableName: pays.task.isPersonal ? "personalTasks" : 'tasks', taskId: pays.task.id, userId: s.id }).then((data) => { }).catch((error) => { console.error(error); }) : NotDoTask({ tableName: pays.task.isPersonal ? "personalTasks" : 'tasks', taskId: pays.task.id, userId: s.id }).then((data) => { }).catch((error) => { console.error(error); });;
            setOpen(open.filter((x) => x != pays.i));
            setPays(false)
          }}><ButtonRed text='אישור'></ButtonRed></div>
        </div>
      </div>
    </div>}

    {openChangeReminders && <div id='modal2'>
      <div className='animate__animated animate__zoomIn'>
        <CloseOutlined className='close2' onClick={() => setOpenChangeReminders(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
        <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '50vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '25vh' }}>
          <div className='titl' style={{ marginTop: '5%', fontSize: '110%' }}>במקרה שלא ביצעתם מטלה שחשוב לבצע אותה תקבלו תזכורת למייל שלכם, <br></br>כדי שנהיה בטוחים שלא תשכחו לארגן שום דבר.</div>
          <div style={{ marginTop: '-2%', fontSize: '100%', marginBottom: '5%' }}>האם תירצו לאשר קבלת תזכורות למייל?</div>
          <div style={{ marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }}>
            <div className='buton1' style={{ width: '20%', marginLeft: '5%', minWidth: 'max-content', display: 'inline-block' }} onClick={() => { setOpenChangeReminders(false) }}><ButtonRed text='לא מעוניין'></ButtonRed></div>
            <div className='buton1' style={{ width: '20%', minWidth: 'max-content', display: 'inline-block' }} onClick={() => {
              ChangeReminders(s.id, 1).then((data) => { d(CompleteUser({ ...s, reminders: 1 })) }).catch((error) => { console.error(error); });
              setOpenChangeReminders(false)
            }}><ButtonRed text='אישור'></ButtonRed></div>
          </div>
        </div>
      </div>
    </div>}

    {moreSum && <div id='modal2'>
      <div className='animate__animated animate__zoomIn'>
        <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '50vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '25vh' }}>
          <img loading='lazy' src={picError} style={{ width: '100px', height: '100px', marginTop: '2%' }}></img>
          <div className='titl' style={{ marginTop: '5%', fontSize: '110%', marginBottom: '5%' }}>לא ניתן להכניס סכום כה גבוה!</div>
          <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => { setMoreSum(false) }}><ButtonRed text='אישור'></ButtonRed></div>
        </div>
      </div>
    </div>}

    {alert2 && <Alert set={setAlert2} sendGood={sendGood} text={"קול שמחה"} mail={"info@kolsimcha.co.il"}></Alert>}

    {send && <div className="send animate__animated animate__bounceInUp">
      <div style={{ lineHeight: '8vh', textAlign: 'center', fontWeight: '400' }}>{send}</div>
    </div>}

    {opUser && <div id='modal2'>
      <div className='animate__animated animate__zoomIn'>
        <CloseOutlined className='close2' onClick={() => setOpUser(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
        <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '40vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '30vh' }}>
          <div className='titl' style={{ marginTop: '8%', fontSize: '120%', marginBottom: '5%' }}>לא ניתן לבצע פעולה זו, עליכם להתחבר או להירשם למערכת</div>
          <div style={{ width: '10%', display: 'inline-block' }} onClick={() => setLogin(true)}><ButtonRed text='התחבר'></ButtonRed></div>
          <div style={{ width: '10%', display: 'inline-block' }} onClick={() => setConnection(true)}><ButtonRed text='הירשם'></ButtonRed></div>
        </div>
      </div>
    </div>}

    {login && <Login setLogin={(bool) => { setLogin(false); setOpUser(false) }}></Login>}
    {connection && <Connection set={(bool) => { setConnection(false); setOpUser(false) }}></Connection>}

    {weddingDate > 0 && <p>מעבר לרשימה שציינו, השאר יחסית משני, גם אם שכחתם משהו - זה לא נורא, הכל יעבור עד החתונה. <br /> הדבר העיקרי זה לבוא לחתונה בשמחה ויישוב הדעת.</p>}

    <BottomMenu></BottomMenu>

  </div >

}

export default TaskList