import { useEffect, useRef } from "react";

const RefReady = (prop) => {
    const elementRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate__animated');
                    entry.target.classList.add(prop.event);
                    entry.target.style.animationDelay = prop.event2;
                }
            });
        }, { threshold: 0.3 });
        if (elementRef.current) { observer.observe(elementRef.current); }
        return () => { if (elementRef.current) { observer.unobserve(elementRef.current); } };
    }, []);

    return <div ref={elementRef}>
        {prop.tag}
    </div>
}

export default RefReady