import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RefReady from "../../global/ref";
import { CloseOutlined } from "@ant-design/icons";
import { DelOneFromShopCart, FinishBuyDate, GetOneBussiness, GetShopCartBussiness } from "../../axios/api";
import { IconButton } from "@mui/material";
import Alert from "../../global/alert/Alert";
import { CompleteThisBussiness } from "../../2. redux/actions/thisBussinessActions";
import { COMPLETEallShoppingCart, COMPLETEDelCart } from "../../2. redux/actions/shoppingCartAction";
import BottomMenu from "../../global/bottomMenu/BottomMenu";
import ButtonRed from "../../global/button/button";
import { handleDateChange2 } from "../11. connection/Connection";
import AlertDelete from "../../global/alert/AlertDelete";
import { COMPLETEAddorders } from "../../2. redux/actions/ordersActions";

const Shopping = () => {
    useEffect(() => { window.scrollTo(0, 0); }, []);

    const s = useSelector(a => a.userReducer)
    const sShopping = useSelector(a => a.shoppingCartReducer.allShoppingCart)
    const navigate = useNavigate()
    const [weddingDate, setWeddingDate] = useState(false);
    const [shopping, setShopping] = useState([]);
    const [plus, setPlus] = useState([]);
    const [alert, setAlert] = useState(false)
    const d = useDispatch()
    const [openDell, setOpenDell] = useState(false)
    const baseImageUrl = "https://kolsimcha.co.il/images";

    useEffect(() => {
        if (s.id && sShopping.length <= 0) {
            GetShopCartBussiness(s.id).then((data) => {
                setShopping(data)
                d(COMPLETEallShoppingCart(data))
            }).catch((error) => { console.error(error); });
        }
        else {
            setShopping(sShopping)
        }
    }, [s])

    useEffect(() => {
        setWeddingDate(Math.ceil((new Date(s.weddingDate) - new Date()) / (24 * 60 * 60 * 1000)));
    }, [s]);

    const delOneShopCart = () => {
        DelOneFromShopCart(openDell).then((data) => {
            setShopping(shopping.filter(a => a.id != openDell))
            d(COMPLETEDelCart(openDell))
        }).catch((error) => { console.error(error); });
        setOpenDell(false)
    }

    return <div>
        {s.id && weddingDate > 0 && <div style={{ position: 'fixed', left: '5%', marginTop: '-5%', backgroundColor: 'white' }}>{s.chatanOrKala}, בעוד {weddingDate} ימים החתונה!</div>}

        <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '8%', width: '30%', marginRight: '35%', marginBottom: '3px' }}>כל הפריטים שבחרתם</p>
        <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

        {/* <div onClick={() => { navigate(-1) }} style={{ backgroundColor: 'rgb(218, 166, 166)', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', borderRadius: '4px', cursor: 'pointer', width: '5%', height: '6vh', paddingTop: '10px', position: 'absolute', top: '10vh', right: '5vh' }}><ArrowRightOutlined /></div> */}

        <div className="buton1" style={{ minWidth: 'max-content', width: '20%', position: 'absolute', top: '15%', left: '10%' }} onClick={() => { navigate(`/choozedBussness`) }}><ButtonRed text='עבור להזמנות שבוצעו'></ButtonRed></div>


        <table style={{ marginTop: '2%', width: '80%', marginRight: '10%' }}>
            <thead>
                <tr style={{ textAlign: 'center' }}>
                    <th style={{ width: '15%', textAlign: 'center' }}>תאריך שינוי אחרון</th>
                    <th style={{ width: '17%', textAlign: 'center' }}>נותן השירות</th>
                    <th style={{ width: '38%', textAlign: 'center' }}>המוצרים שנבחרו</th>
                    <th style={{ width: '15%' }}>יצירת קשר</th>
                    <th style={{ width: '15%', textAlign: 'center' }}>סכום לתשלום</th>
                </tr>
            </thead>
            {shopping && <tbody>
                {shopping.map((x, i) => (
                    <Fragment key={i}><tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                        <td style={{ textAlign: 'center' }}>{x.shoppingCartDetails.length === 0 ? "-" : new Date(x.date).toLocaleDateString('en-GB').replace('/', '-').replace('/', '-')}</td>
                        <td style={{ textAlign: 'center' }}>{x.nameBussiness}</td>
                        {x.shoppingCartDetails.length > 0 && <td style={{ textAlign: 'center' }}>
                            <IconButton align="center" style={{ color: 'black', padding: '2%', paddingRight: '5%', paddingLeft: '5%' }} className='IconButton' size="big" onClick={() => { if (plus.includes(i)) { setPlus(plus.filter((x) => x != i)); } else setPlus([...plus, i]) }}>
                                {plus.includes(i) ? 'הסתר פרטים' : 'הצג פרטים'}
                            </IconButton>
                            {plus.includes(i) && <div style={{ marginTop: '5%', marginBottom: '5%' }}>{x.shoppingCartDetails.map((y, j) => (
                                <div key={j} className='proAll' style={{ border: 'rgb(128, 14, 14) 2px solid', marginTop: '1%', position: 'relative', width: '100%', padding: '0', minHeight: 'fit-content' }}>
                                    {/* <div style={{ height: '11.6vh', display: 'inline-block', width: '25%', textAlign: 'center', paddingTop: '4%', float: 'right', cursor:'auto' }}>{y.name}</div> */}
                                    <div style={{ height: '12vh', display: 'inline-block', marginRight: '5%', textAlign: 'right', width: '75%', lineHeight: '5px', fontWeight: '300' }} className='prudu2'><p>{y.name}</p><b>מחיר: </b>{y.price} ש"ח <p><b>כמות: </b>{y.amount}</p></div>
                                    <img loading='lazy' src={`${baseImageUrl}${y.picture.replace(/\\/g, '\/')}?format=avif`} className='proPic2'></img>
                                </div>
                            ))}</div>}
                            <div className='buton1' style={{ width: '200px', marginRight: 'auto', marginLeft: 'auto' }} onClick={() => { GetOneBussiness(String(x.categoryCode).split(',')[0], x.bussinessId).then((data) => { d(CompleteThisBussiness(data)); navigate(`/bussiness/${'ההזמנות שלי'}/${'סל קניות'}/${String(x.categoryCode).split(',')[0]}`) }).catch((error) => { console.error(error); }); }}><ButtonRed text='המשך'></ButtonRed></div>
                        </td>}
                        {x.shoppingCartDetails.length === 0 && <td style={{ textAlign: 'center' }}>תאריך בבדיקה: {handleDateChange2(x.date)} - {new Date(x.date).toLocaleDateString('en-GB').replace('/', '-').replace('/', '-')}<div className='buton1' style={{ width: 'max-content', marginRight: 'auto', marginLeft: 'auto' }} onClick={() => {
                            FinishBuyDate(x.id, { userId: s.id, nameBussiness: x.nameBussiness, mailBussiness: x.mailBussiness, date: x.date /*, price: 0*/}).then((data) => {
                                setShopping(shopping.filter(a => a.id != x.id))
                                d(COMPLETEAddorders({ categoryCode: x.categoryCode, bussinessId: x.bussinessId, date: x.date, nameBussiness: x.nameBussiness, mailBussiness: x.mailBussiness, detailsShopping: [], sum: "" }))
                                d(COMPLETEDelCart(x.id))
                            }).catch((error) => { console.error(error); });
                        }}><ButtonRed text='סגירה סופית אושרה ע"י בעל העסק '></ButtonRed></div></td>}
                        <td className='kishur' style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => setAlert(x)}>שלח הודעה</td>
                        <td style={{ textAlign: 'center' }}>{x.sum ? `${x.sum} ש"ח` : "-"}</td>
                        {x.shoppingCartDetails.length === 0 && <span style={{margin:'8px', cursor:'pointer', color:"#ababab", fontSize:'80%'}} onClick={()=>{ setOpenDell(x.id) }}><CloseOutlined/></span>}
                    </tr>
                    </Fragment>
                ))}
            </tbody>}
        </table>

        {shopping.length <= 0 && <p style={{ fontSize: '120%', marginTop: '10%' }}>לא נבחרו פריטים</p>}

        {alert && <Alert set={setAlert} text={alert.nameBussiness} mail={alert.mailBussiness}></Alert>}
        
        {openDell && <AlertDelete set={setOpenDell} func={delOneShopCart} text={'הזמנה'}></AlertDelete>}

        <BottomMenu></BottomMenu>
    </div>
};

export default Shopping