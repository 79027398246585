import './Search.css'
import { useNavigate, useNavigation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { CloseOutlined } from '@ant-design/icons';
import zIndex from '@mui/material/styles/zIndex';
import { GetSearchByNameBussiness, GetSearchByProduct } from '../../axios/api';

const Search = (prop) => {

    const navigate = useNavigate()

    const [allProducts, setAllProducts] = useState([])
    //כל תא במערך אמור להכיל {מוצר, קוד עסק, קוד קטגוריה}

    const [allBussiness, setAllBussiness] = useState([])
    //כל תא במערך אמור להכיל {שם עסק, קוד עסק, קוד קטגוריה}

    const [resultSearch, setResultSearch] = useState([])
    const [resultSearchNow, setResultSearchNow] = useState()
    const [inputSearch, setInputSearch] = useState("")

    const searchData1 = (search) => {
        setInputSearch(search)
        var result = []
        prop.getMenu?.forEach(menu => {
            menu.Titles?.forEach(title => {
                title.Categories?.forEach(category => {
                    // if (!result.some(c => c.categoryCode === category.categoryCode))
                    result.push({ categoryName: category.CategoryName, categoryCode: category.CategoryCode, menu: menu.name })
                });
            });
        });
        result.sort((a, b) => a.categoryName > b.categoryName ? 1 : -1)
        const uniqueProducts = result.filter((x, index) => {
            return result.findIndex((y) => y.categoryName === x.categoryName) === index;
        });
        setTimeout(() => {
            setResultSearch(uniqueProducts.filter(c => c.categoryName && c.categoryName.includes(search)) || []);
        }, 1);
    }

    const searchData2 = (search) => {
        setInputSearch(search)
        var result = allBussiness
        result.sort((a, b) => a.nameProductOrBussiness > b.nameProductOrBussiness ? 1 : -1);
        const uniqueBussiness = result.filter((x, index) => {
            return result.findIndex((y) => y.nameProductOrBussiness === x.nameProductOrBussiness) === index;
        });
        setTimeout(() => {
            setResultSearch(uniqueBussiness.filter(c => c.nameProductOrBussiness && c.nameProductOrBussiness.includes(search)) || []);
        }, 1);
    }

    const searchData3 = (search) => {
        setInputSearch(search)
        var result = allProducts
        result.sort((a, b) => a.nameProductOrBussiness > b.nameProductOrBussiness ? 1 : -1);
        const uniqueProducts = result.filter((x, index) => {
            return result.findIndex((y) => y.nameProductOrBussiness === x.nameProductOrBussiness) === index;
        });
        setTimeout(() => {
            setResultSearch(uniqueProducts.filter(c => c.nameProductOrBussiness && c.nameProductOrBussiness.includes(search)) || []);
        }, 1);
    }

    useEffect(() => {
        searchData1('')
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
        // document.getElementsByTagName('html')[0].style.pointerEvents = 'none';
    }, []);

    useEffect(() => {
        GetSearchByProduct().then((data) => {
            setAllProducts(data)
        }).catch((error) => { console.error(error); });
        GetSearchByNameBussiness().then((data) => {
            setAllBussiness(data)
        }).catch((error) => { console.error(error); });
    }, [])

    const [numSearch, setNumSearch] = useState(1)

    return <div style={{ width: '100%', height: '120vh', backgroundColor: '#0a0a0aad', position: 'fixed', zIndex: '700' }}>

        <div>

            <div className='SearchButton SearchRight' onClick={() => {
                if (resultSearchNow !== undefined) {
                    document.getElementsByTagName('html')[0].style.overflow = 'auto';
                    prop.setIsSearch(false);
                    if (numSearch === 1) { navigate(`/allCards/${resultSearchNow.categoryCode}/${resultSearchNow.categoryName}/${resultSearchNow.menu}`) }
                    else if (numSearch === 2) {
                        var name = resultSearchNow.nameProductOrBussiness
                        var allFind = allBussiness.filter(c => c.nameProductOrBussiness && c.nameProductOrBussiness === resultSearchNow.nameProductOrBussiness)
                        for (let i = 0; i < allFind.length; i++) {
                            delete allFind[i].nameProductOrBussiness
                        }
                        navigate(`/SearchBussiness/חיפוש ${name}`, { state: { allFind: allFind } });

                    }
                    else {
                        var name = resultSearchNow.nameProductOrBussiness
                        var allFind = allProducts.filter(c => c.nameProductOrBussiness && c.nameProductOrBussiness === resultSearchNow.nameProductOrBussiness)
                        for (let i = 0; i < allFind.length; i++) {
                            delete allFind[i].nameProductOrBussiness
                        }
                        navigate(`/SearchBussiness/חיפוש ${name}`, { state: { allFind: allFind } });
                    }
                }
            }}>חפש</div>

            <div>
                <input className='search' readOnly={inputSearch !== document.getElementsByClassName('search')[0]?.value} type='text' onClick={() => { if (inputSearch === document.getElementsByClassName('search')[0]?.value || inputSearch === '') { setNumSearch(1); setResultSearch([]); setTimeout(() => { searchData1(inputSearch) }, 1); } }} placeholder='חיפוש לפי קטגוריה' autocomplete='off' onChange={(e) => searchData1(e.target.value)} style={{ borderTop: '#c00000 2px solid', backgroundColor: `${numSearch === 1 ? "#fccbcb" : "white"}` }}></input>
                <input className='search' readOnly={inputSearch !== document.getElementsByClassName('search')[1]?.value} type='text' onClick={() => { if (inputSearch === document.getElementsByClassName('search')[1]?.value || inputSearch === '') { setNumSearch(2); setResultSearch([]); setTimeout(() => { searchData2(inputSearch) }, 1); } }} placeholder='חיפוש לפי שם עסק' autocomplete='off' onChange={(e) => searchData2(e.target.value)} style={{ backgroundColor: `${numSearch === 2 ? "#fccbcb" : "white"}` }}></input>
                <input className='search' readOnly={inputSearch !== document.getElementsByClassName('search')[2]?.value} type='text' onClick={() => { if (inputSearch === document.getElementsByClassName('search')[2]?.value || inputSearch === '') { setNumSearch(3); setResultSearch([]); setTimeout(() => { searchData3(inputSearch) }, 1); } }} placeholder='חיפוש לפי מוצר / שירות' autocomplete='off' onChange={(e) => searchData3(e.target.value)} style={{ backgroundColor: `${numSearch === 3 ? "#fccbcb" : "white"}` }}></input>

                {resultSearch[0] && <div className='resultSearchRound animate__animated animate__fadeInDown'>{resultSearch.map((x, i) => (
                    <div className='resultSearch' key={i}>
                        {numSearch === 1 && <div onClick={() => { setInputSearch(x.categoryName); document.getElementsByClassName('search')[0].value = x.categoryName; setResultSearchNow(x); setResultSearch([]) }}>{x.categoryName}</div>}
                        {numSearch === 2 && <div onClick={() => { setInputSearch(x.nameProductOrBussiness); document.getElementsByClassName('search')[1].value = x.nameProductOrBussiness; setResultSearchNow(x); setResultSearch([]) }}>{x.nameProductOrBussiness}</div>}
                        {numSearch === 3 && <div onClick={() => { setInputSearch(x.nameProductOrBussiness); document.getElementsByClassName('search')[2].value = x.nameProductOrBussiness; setResultSearchNow(x); setResultSearch([]) }}>{x.nameProductOrBussiness}</div>}
                    </div>
                ))}</div>}

            </div>

            <div className='SearchButton SearchLeft' onClick={() => { document.getElementsByTagName('html')[0].style.overflow = 'auto'; prop.setIsSearch(false) }}>
                <IconButton className='IconButton' size="large">
                    <CloseOutlined />
                </IconButton>
            </div>
        </div>

    </div>

}

export default Search