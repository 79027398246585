import { CloseOutlined } from '@ant-design/icons';
import '../global/alert/Alert.css';
import ButtonRed from '../global/button/button';
import picError from '../../pic/animations/113.gif'
import { useState } from 'react';
import ErrorPass from '../1. menu/10. login/ErrorPass';
import { useSelector } from 'react-redux';


const EnterManagger = (prop) => {

    const [alert, setAlert] = useState(false);
    const s = useSelector((state) => state.thisBussinessReducer)

    return <>
        <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => prop.set(false)} />
                <div className='glow'>
                    <p className='titl'>כניסה זו מיועדת לבעל העסק {prop.name}</p>
                    <p className='titl' style={{marginTop:'-2%'}}>מהו קוד העסק?</p>
                    <input className='input20' id='passManag' style={{ width: '300px' }} onClick={(e) => e.target.style.width = '80%'} onBlur={(e) => e.target.style.width = '20%'} placeholder="סיסמה" type="password" />
                    <div id='submit30' onClick={() => prop.submit(false)}><ButtonRed text={'אישור'}></ButtonRed></div>
                    {prop.errorMessage && <p style={{marginBottom:'-1%'}}><img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img>{prop.errorMessage}</p>}
                    <br></br>
                    <div className='errorPass' onClick={() => { setAlert(true); }}>שיחזור סיסמה</div>
                    {alert && <ErrorPass set={setAlert} bussiness={s} good={prop.submit}></ErrorPass>}

                </div>
            </div>
        </div>
    </>
};

export default EnterManagger