import './AddToDataBase.css'
import { CloseOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import ButtonRed from "../../../global/button/button"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { EditProducts, SendEmailToBussiness } from "../../../axios/api"
import { CompleteBussinessData, CompleteThisBussiness } from "../../../2. redux/actions/thisBussinessActions"
import { IconButton } from "@mui/material"
import picError from '../../../../pic/animations/113.gif'

const EditBussinessLotOfItems = (props) => {

    const [lotItems, setLotItems] = useState([])
    const [priceShipping, setPriceShipping] = useState(-1)
    const [phoneToCall, setPhoneToCall] = useState(null)
    const [notesShipping, setNotesShipping] = useState()
    const [mishlochim, setMishlochim] = useState(false);
    const [mishloachKavuha, setMishloachKavuha] = useState(false);
    const [checkSlika, setCheckSlika] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [think, setThink] = useState(false)
    const [ccToAllTables, setCcToAllTables] = useState()
    const isAddBus = props.isAddBus;

    const cc = JSON.parse(sessionStorage.getItem('mainCC'))
    const s = useSelector((state) => state.thisBussinessReducer);
    const n = useNavigate()
    const d = useDispatch()
    const baseImageUrl = "https://kolsimcha.co.il/images";

    useEffect(() => {
        window.scrollTo(0, 0);
        var ccTo = !['1', '2', '179', '182', '3', '82', '4'].includes(cc) ? 0 : s.categoryCode;
        setLotItems(s.bussinessData.lotOfItems ?? [])
        if (ccTo === 0) { setNotesShipping(s.notesShipping); setPriceShipping(s.priceShipping) }
        setPhoneToCall(s.phoneToCall);
        setMishlochim(s.priceShipping === -1 || s.priceShipping === undefined ? false : true)
        setCcToAllTables(ccTo);
        setMishloachKavuha(s.priceShipping === null ? "option2" : s.priceShipping >= 0 ? "option1" : '')
    }, [s])

    const clickPicMutsar = (e, num) => {
        document.getElementsByClassName("fileSelectMutsar")[num].addEventListener("click", document.getElementsByClassName("fileElemMutsar")[num].click());
    }

    const resizeImageTo300Pixels = (numSize, inputImage, funcKod) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.src = URL.createObjectURL(inputImage);
        image.onload = function () {
            canvas.width = numSize;
            canvas.height = (numSize * image.height) / image.width;
            ctx.drawImage(image, 0, 0, numSize, (numSize * image.height) / image.width);
            canvas.toBlob((blob) => {
                const resizedFile = new File([blob], inputImage.name, { type: 'image/png' });
                funcKod(resizedFile)
            }, 'image/png', 0.9);
        };
    }

    const changePicMutsar = async (e, i) => {
        if (!e.target.files.length) {
            const updatedLotItems = lotItems.map((item, index) => { if (index === i) { return { ...item, picture: '' }; } return item; }); setLotItems(updatedLotItems)
        }
        else {
            const file = e.target.files[0];
            const funcK = (fileBlob) => {
                const updatedLotItems = lotItems.map((item, index) => { if (index === i) { return { ...item, picture: fileBlob }; } return item; });
                setLotItems(updatedLotItems)
            }
            resizeImageTo300Pixels(800, file, funcK)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage('');
        var lot = lotItems.filter(x => x.code !== '' || x.name !== '' || x.description !== '' || x.price !== '' || x.picture !== '');
        if (!isAddBus && !s.phoneToCall && phoneToCall?.length < 9)
            setErrorMessage('מספר טלפון של העסק אינו תקין');
        else if (lot.filter(x => x.code === '').length > 0 || lot.filter(x => x.name === '').length > 0 || lot.filter(x => x.description === '').length > 0 || lot.filter(x => x.price === '').length > 0 || lot.filter(x => x.picture === '').length > 0)
            setErrorMessage('יש למלא את כל הפרטים עבור כל מוצר');
        else {
            setThink(true);
            if (!isAddBus) {
                var imagesToAdd = [], lotOf = lot;
                for (let i = 0; i < lotOf.length; i++) {
                    if (lotOf[i].picture instanceof File) { imagesToAdd.push(new File([lotOf[i].picture], "LotOfItemsPics\\" + lotOf[i].picture.name, { type: lotOf[i].picture.type })); lotOf[i].picture = "\\LotOfItemsPics\\" + lotOf[i].picture.name; }
                }
                EditProducts(imagesToAdd, lotOf, s.id, s.categoryCode, phoneToCall, priceShipping, notesShipping).then((data) => {
                    var bus = s;
                    if (!s.phoneToCall) {
                        bus = { ...bus, phoneToCall: phoneToCall }
                        //שליחת מייל לקול שמחה שעסק חדש התחבר למערכת הטלפונית
                        var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום לקול שמחה </p></div><br/> <br/> עסק קיים התחבר למערכת הטלפונית,  <br/> נא לחבר אותו בהקדם - הוא כבר התחיל לשלם. <br/> <br/>  <b>שם העסק: </b> ${s.name}.<br/>  <b>תחומים: </b> ${s.categoryCode}. <br/> <b>מספר טלפון: </b> ${s.phone}<br/> <b>כתובת מייל: </b> ${s.mail}<br/><br/> <b>מספר הטלפון אותו לחבר למערכת הטלפונית: </b><br/> ${s.phoneToCall}<br/><br/>  לאחר בנית השלוחה - נא להודיע ללקוח שהתחבר בהצלחה, ולשלוח לו את מספר המערכת הטלפונית שלנו + מספר השלוחה שלו  <br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
                        SendEmailToBussiness({ name: "", emailTo: 'K3137663@gmail.com', emailFrom: 'info@kolsimcha.co.il', subject: 'קול שמחה - לקוח קיים התחבר למערכת הטלפונית - נא להוסיף', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                    }
                    if (checkSlika) { //שליחת מייל לקול שמחה שעסק קיים רוצה שרות סליקת אשראי
                        var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום לקול שמחה </p></div><br/> <br/> עסק קיים הצטרף לחבילת קול מצהלות,  <br/> העסק מעוניין להצטרף לשירות סליקת האשראי מלקוחות. <br/> <br/>  <b>שם העסק: </b> ${s.name}.<br/>  <b>תחומים: </b> ${s.categoryCode}. <br/> <b>מספר טלפון: </b> ${s.phone}<br/> <b>כתובת מייל: </b> ${s.mail}<br/><br/> <br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
                        SendEmailToBussiness({ name: "", emailTo: 'K3137663@gmail.com', emailFrom: 'info@kolsimcha.co.il', subject: 'קול שמחה - לקוח קיים רוצה שירות סליקת אשראי מלקוחות', message: mes }).then(() => { }).catch((error) => { console.error(error); });
                    }
                    if (ccToAllTables === 0) { bus = { ...bus, priceShipping: priceShipping, notesShipping: notesShipping } }
                    d(CompleteThisBussiness(bus))
                    d(CompleteBussinessData({ ...s.bussinessData, lotOfItems: data }))
                    n(-1);
                }).catch((error) => { console.error(error); setThink(false); });
            }
            else {
                //כדי לשלוח מייל לקול שמחה בסוף הרישום שעסק חדש רוצה שירות סליקת אשראי
                sessionStorage.setItem('checkSlika', JSON.stringify(checkSlika))
                if (ccToAllTables === 0) { d(CompleteThisBussiness({ ...s, priceShipping: priceShipping, notesShipping: notesShipping })) }
                d(CompleteBussinessData({ ...s.bussinessData, lotOfItems: lot }))
                props.enter();
            }
        }
    }

    return <div>
        <form onSubmit={handleSubmit}>
            {isAddBus && <div className='buton' onClick={() => { if (ccToAllTables === 0) { d(CompleteThisBussiness({ ...s, priceShipping: priceShipping, notesShipping: notesShipping })) }; d(CompleteBussinessData({ ...s.bussinessData, lotOfItems: lotItems })); props.prev() }} style={{ position: 'absolute', top: '-5vh', right: '-40%', width: '8vh', zIndex: '5' }}><ButtonRed text='<--'></ButtonRed></div>}

            <div style={{ fontWeight: '400' }}>הכנס חבילות / שירותים / מוצרים מדוייקים, כולל תמונה, תיאור ומחיר,</div>
            <p style={{ fontWeight: '400' }}>כדי לקבל הזמנות ישירות מקול שמחה.</p>
            <div style={{ fontWeight: '400' }}>שימו לב, מוצר / חבילה שתכניסו ראשון, יוצג ראשון ללקוח. וכן הלאה.</div>

            <table style={{ marginTop: '7%', marginRight: 'auto', marginLeft: 'auto', width: '100%' }}>
                <thead>
                    <tr>
                        <th style={{ width: '20%', textAlign: 'center' }}>קוד מוצר / מק"ט</th>
                        <th style={{ width: '20%', textAlign: 'center' }}>שם המוצר</th>
                        <th style={{ width: '20%', textAlign: 'center' }}>תיאור</th>
                        <th style={{ width: '20%', textAlign: 'center' }}>מחיר בשקלים</th>
                        <th style={{ width: '21%', textAlign: 'center' }}>תמונה</th>
                    </tr>
                </thead>
                {lotItems && <tbody>{lotItems.map((x, i) => (
                    <tr style={{ fontWeight: '300', borderTop: '#c00000 1px solid' }}>
                        <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={x.code} onChange={(e) => { if (e.target.value.length <= 20) { const updatedLotItems = lotItems.map((item, index) => { if (index === i) { return { ...item, code: e.target.value }; } return item; }); setLotItems(updatedLotItems); } }} defaultValue={x.code}></input></td>
                        <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={x.name} onChange={(e) => { if (e.target.value.length <= 22) { const updatedLotItems = lotItems.map((item, index) => { if (index === i) { return { ...item, name: e.target.value }; } return item; }); setLotItems(updatedLotItems); } }} defaultValue={x.name}></input></td>
                        <td><input className='inpu' style={{ width: '100%', height: 'auto', border: 'none' }} autoComplete="on" type="text" value={x.description} onChange={(e) => { if (e.target.value.length <= 140) { const updatedLotItems = lotItems.map((item, index) => { if (index === i) { return { ...item, description: e.target.value }; } return item; }); setLotItems(updatedLotItems); } }} defaultValue={x.description}></input></td>
                        <td><input className='inpu' style={{ width: '100%', height: '200%', border: 'none' }} autoComplete="on" type="text" value={x.price} onChange={(e) => { if ((/^[0-9]*$/.test(e.target.value) && e.target.value < 214748) || e.target.value === '') { const updatedLotItems = lotItems.map((item, index) => { if (index === i) { return { ...item, price: e.target.value }; } return item; }); setLotItems(updatedLotItems); } }} defaultValue={x.price}></input></td>
                        {x.picture && <img loading='lazy' className='picUp' src={x.picture instanceof Blob || x.picture instanceof File ? URL.createObjectURL(x.picture) : baseImageUrl + x.picture}></img>}{!x.picture && <div style={{ display: 'inline-block' }}>אין תמונה</div>}
                        <input type="file" className="fileElemMutsar" accept="image/*" onChange={(e) => changePicMutsar(e, i)} style={{ display: 'none' }} />
                        <input type="button" className="choozePic fileSelectMutsar" onClick={(e) => clickPicMutsar(e, i)} value="בחר תמונה" />
                        <td><IconButton onClick={() => setLotItems(lotItems.filter(a => a !== lotItems[i]))} className='IconButton' size="small">
                            <CloseOutlined />
                        </IconButton></td>
                    </tr>))}
                </tbody>}
            </table>
            <div onClick={() => { setLotItems([...lotItems, { code: '', name: '', description: '', price: '', picture: '' }]) }} className='buton' style={{ marginTop: '0', float: 'left', width: '20%' }}><ButtonRed text='הוסף מוצר'></ButtonRed></div>
            {ccToAllTables === 0 && <div style={{ position: 'relative', marginTop: '18%', border: '#c00000 2px solid', width: '80%', marginRight: '10%', padding: '5%' }}>
                <div style={{ position: 'absolute', right: '-10%', width: '120%' }}><label className="checkbox-container"><input type="checkbox" className="checkbox1" defaultChecked={mishlochim} value={-1} onClick={(e) => { if (mishlochim) { setPriceShipping(-1) }; setMishlochim(!mishlochim) }} /><span className="checkbox-custom">&#10003;</span> &nbsp; האם קיים בעסק שירות משלוחים?</label></div><br></br>
                {mishlochim && <div>
                    <label style={{ display: 'block', textAlign: 'right', marginRight: '100px' }}><input type="radio" value="option1" checked={mishloachKavuha === 'option1'} style={{ accentColor: '#c00000' }} onChange={(e) => { setMishloachKavuha(e.target.value); }} /> <span style={{ fontSize: '80%' }}>אם מחיר המשלוח הוא סכום קבוע שמשולם לבית העסק, נא למלא את הסכום כאן – והסכום יצורף להזמנה:</span></label>
                    {mishloachKavuha === "option1" && <div>
                        <input className='input20 ii' style={{ width: '70%', marginTop: '3%' }} placeholder="מחיר משלוח - יצורף להזמנה" autoComplete="on" type="text" value={priceShipping === -1 ? "" : priceShipping} onChange={(e) => { if (e.target.value <= 32767) { setPriceShipping(e.target.value) } }} />
                        למשלוח חינם מלאו מחיר משלוח: 0
                        <textarea style={{ width: '80%', height: '300px', marginTop: '2%', padding: '0.5%', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '4%', borderRadius: '10px 10px 10px 10px', outlineColor: '#c00000', borderColor: 'black' }} placeholder="הערות למשלוח: זמן אספקה וכדו'" autoComplete="on" type="text" value={notesShipping} onChange={(e) => { if (e.target.value.length < 900) { setNotesShipping(e.target.value) } }} />
                    </div>}
                    <label style={{ display: 'block', textAlign: 'right', marginRight: '100px' }}><input type="radio" value="option2" checked={mishloachKavuha === 'option2'} style={{ accentColor: '#c00000' }} onChange={(e) => { setMishloachKavuha(e.target.value); if (e.target.value === "option2") { setPriceShipping(-2) } }} /> <span style={{ fontSize: '80%' }}>במקרה שהסכום אינו קבוע או שמשולם לשליח – למלא כאן את הפירוט:</span></label>
                    {mishloachKavuha === "option2" && <textarea style={{ width: '80%', height: '300px', marginTop: '2%', padding: '0.5%', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '4%', borderRadius: '10px 10px 10px 10px', outlineColor: '#c00000', borderColor: 'black' }} placeholder="מחירים והערות על המשלוח" autoComplete="on" type="text" value={notesShipping} onChange={(e) => { if (e.target.value.length < 900) { setNotesShipping(e.target.value) } }} />}
                </div>}
                {/* שירות סליקה */}
                {(isAddBus || s.bussinessData.lotOfItems?.length === 0) && <div style={{ position: 'absolute', right: '-10%', width: '120%', marginTop: '14px' }}><label className="checkbox-container"><input type="checkbox" className="checkbox1" onClick={(e) => { setCheckSlika(!checkSlika) }} /><span className="checkbox-custom">&#10003;</span> &nbsp; העסק מעונין להצטרף לשירותי סליקת אשראי מלקוחות.</label></div>}<br></br>
            </div>}
            <div style={{ marginBottom: '20%' }}></div>
            {!s.phoneToCall && !isAddBus && <input className='input20 ii' style={{ width: '70%', marginTop: '-10%', marginBottom: '10%' }} placeholder="*מס' טלפון של העסק" autoComplete="on" type="text" value={phoneToCall} onChange={(e) => { if (e.target.value.length <= 10) { setPhoneToCall(e.target.value) } }} />}

            <div style={{ marginBottom: '15%' }}></div>
            <span style={{ cursor: `${think ? 'wait' : 'pointer'}` }}><button type="submit" className='buton' style={{ pointerEvents: `${think ? 'none' : 'auto'}`, width: '20%', marginTop: '-10%', marginBottom: '5%', marginRight: 'auto', marginLeft: 'auto', minWidth: '20vh', zIndex: '5' }}><ButtonRed text={isAddBus ? 'המשך    -->' : 'סיים עריכה'}></ButtonRed></button></span>
            {errorMessage && <p><img loading='lazy' src={picError} style={{ width: '35px', height: '35px' }}></img>{errorMessage}</p>}
        </form>

    </div>
}

export default EditBussinessLotOfItems