// import logo from './logo.svg';
// import './App.css';
import React, { useState } from 'react'
import { Provider } from 'react-redux'
import store from './components/2. redux/store/store';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Menu from './components/1. menu/0. menu/Menu';
import HomePage2 from './components/1. menu/1. home/HomePage2';
import HowItWork from './components/1. menu/2. howItWork/HowItWork';
import TaskList from './components/1. menu/9. personalOrganizer/TaskList';
import AddBusiness from './components/1. menu/8. addBusiness/AddBusiness';
import Erusin from './components/1. menu/3. erusin/Erusin';
import FromWedding from './components/1. menu/4. fromWedding/FromWedding';
import ShabatChatan from './components/1. menu/5. shabatChatan/ShabatChatan';
import Wedding from './components/1. menu/6. wedding/Wedding';
import AllCards from './components/global/card/AllCards';
import ShevaBrachot from './components/1. menu/7. shevaBrachot/ShevaBrachot';
import Bussiness from './components/global/bussiness/Bussiness';
import HowManyToPay from './components/1. menu/9. personalOrganizer/HowManyToPay';
import ChoozedBussness from './components/1. menu/9. personalOrganizer/ChoozedBussness';
import ListAddress from './components/1. menu/9. personalOrganizer/ListAddress';
import PicForMenu from './components/global/image/PicForMenu';
import MenuManagger from './components/3. managger/2. MenuManagger';
import OnScroll from './components/global/onScroll/OnScroll';
import PersonalArea from './components/1. menu/11. connection/PersonalArea';
import Down from './components/global/Down';
import Kochavit from './components/global/bussiness/Kochavit';
import Shopping from './components/1. menu/9. personalOrganizer/Shopping';
import Mehorasim from './components/1. menu/12. mehorasim/Mehorasim';
import SearchBussiness from './components/global/searchBussiness/SearchBussiness';
import AddBussinessToDB from './components/1. menu/8. addBusiness/addToDataBase/AddBussinessToDB';
import EditBussinessMain from './components/1. menu/8. addBusiness/addToDataBase/EditBussinessMain';
import BusinessArea from './components/global/bussiness/BusinessArea';
import { useEffect } from 'react';

function App() {

    const [showDown, setShowDown] = useState(false);

    useEffect(() => {
        window.onload = () => setShowDown(true);
    }, []);

  return (
      <div className="App" style={{ direction: 'rtl', fontFamily: "'IBM Plex Sans Hebrew', sans-serif", fontSize: '160%', fontWeight: '350' }}>

      <Provider store={store}>
        <BrowserRouter>
          <Menu></Menu>
          <OnScroll></OnScroll>
          {showDown && <Down></Down>}
          <Routes>
            <Route exact path='/menuManagger' element={< MenuManagger />}></Route>

            <Route exact path='/' element={< HomePage2 />}></Route>
            <Route exact path='/איך זה עובד?/:now' element={< HowItWork />}></Route>
            <Route exact path='/ארגונית אישית/:userId' element={< TaskList />}></Route>
            <Route exact path='/מאורסים/:userId' element={< Mehorasim />}></Route>
            <Route exact path='/taskList' element={< TaskList />}></Route>
            <Route exact path='/הצטרפות לעסקים/:now' element={< AddBusiness />}></Route>
            <Route exact path='/אירוסין/:now' element={< Erusin />}></Route>
            <Route exact path='/קודם החתונה/:now' element={< FromWedding />}></Route>
            <Route exact path='/שבת חתן/:now' element={< ShabatChatan />}></Route>
            <Route exact path='/חתונה/:now' element={< Wedding />}></Route>
            <Route exact path='/שבע ברכות/:now' element={< ShevaBrachot />}></Route>
            <Route exact path='/picForMenu' element={< PicForMenu />}></Route>
            <Route exact path='/allCards/:categoryCode/:name/:categoryName' element={< AllCards />}></Route>
            <Route exact path='/bussiness/:menu/:categoryName/:codeCategory' element={< Bussiness />}></Route>
            <Route exact path='/howManyToPay' element={< HowManyToPay />}></Route>
            <Route exact path='/choozedBussness' element={< ChoozedBussness />}></Route>
            <Route exact path='/listAddress' element={< ListAddress />}></Route>
            
            <Route exact path='/AddBussinessToDB/:which' element={< AddBussinessToDB />}></Route>
            <Route exact path='/EditBussinessMain/:which' element={< EditBussinessMain />}></Route>
            <Route exact path='/EditBussinessMain/:which/:codeCategory/:bussinessId' element={< EditBussinessMain />}></Route>

                      <Route exact path='/PersonalArea' element={< PersonalArea />}></Route>
                      <Route exact path='/BusinessArea' element={< BusinessArea />}></Route>
            <Route exact path='/Kochavit/:categoryName/:cc' element={< Kochavit />}></Route>
            <Route exact path='/Shopping' element={< Shopping />}></Route>
            <Route exact path='/SearchBussiness/:name' element={< SearchBussiness />}></Route>


            {/* <Route path="*" element={<NoPage />} /> */}
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
